import React, { useState } from "react";
import _ from "lodash";
import { Col, NSidebarContainer, Spinner } from "components";
import { NBTextArea, NButton } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";
import { RowItem } from "components/Nimbiv2/styled";
import { createNote } from "services/student.service";
import { useDispatch } from "react-redux";
import { fetchStudentNotes } from "store/actions/async/student-async.actions";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulbOn,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { OverlayTrigger } from "components";
import { Form, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { fetchPersonHistory } from "store/actions/async/crm-async.action";

const FormSlideObservation = ({ open, onClose, studentID }) => {
  const { t } = useTranslation();
  const [isSaving, setSaving] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [noteStarred, setStarredNote] = useState(false);
  const dispatch = useDispatch();
  const addNewNote = async () => {
    setSaving(true);
    const payload = {
      note: noteText,
      student: studentID,
      is_starring: noteStarred,
    };
    const { status } = await createNote(payload);

    if (status === 201) {
      dispatch(fetchStudentNotes(studentID));
      setSaving(false);
      setNoteText("");
      setStarredNote(false);
      toast.success(t("create_observation_success"));
      dispatch(fetchPersonHistory(studentID));
      onClose();
    } else {
      toast.error(t("failed_add_observation"));
    }
  };
  return (
    <NSidebarContainer
      width="634px"
      openSide={open}
      title={t("new_observation")}
      onClose={onClose}
    >
      <div
        className="d-flex"
        style={{ flexDirection: "column", height: "100%" }}
      >
        <div style={{ height: "100%" }}>
          <NBTextArea
            value={noteText}
            maxLength={1000}
            rows={20}
            placeholder={t("write_new_observation")}
            onChange={(event) => setNoteText(event.target.value)}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <span
              className="mr-1"
              onClick={() => {
                setStarredNote(!noteStarred);
              }}
              style={{
                cursor: "pointer",
                paddingRight: "0.75rem",
                paddingBottom: "0px",
                paddingTop: "7px",
              }}
            >
              <FontAwesomeIcon
                className="mr-2"
                icon={faLightbulbOn}
                style={{ fontSize: "15px", paddingTop: "13px" }}
              />
              {t("student_note_starring")}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-student-notes`}>
                    {t("starring_notes_popover")}
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} className="ml-1" />
              </OverlayTrigger>
            </span>
            <ContainerSwitch>
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={noteStarred}
                onChange={(evt) => {
                  setStarredNote(evt.target.checked);
                }}
                custom
              />
            </ContainerSwitch>
          </div>
        </div>
        <RowItem>
          <Col xs="4">
            <NButton
              outlined
              onClick={() => {
                setNoteText("");
                onClose();
              }}
              style={{ width: "100%" }}
            >
              {_.upperFirst(t("cancel"))}
            </NButton>
          </Col>
          <Col xs="8">
            <NButton
              style={{ marginLeft: "16px", width: "100%" }}
              disabled={isSaving || String(noteText).trim().length === 0}
              onClick={addNewNote}
            >
              {isSaving ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}{" "}
              {_.upperFirst(t("save"))}
            </NButton>
          </Col>
        </RowItem>
      </div>
    </NSidebarContainer>
  );
};
export default FormSlideObservation;

const ContainerSwitch = styled.div`
  .custom-switch .custom-control-label {
    padding-left: 2rem;
    padding-bottom: 0px;
    padding-top: 10px;
  }

  .custom-switch .custom-control-label::before {
    height: 2rem;
    width: calc(3rem + 0.75rem);
    border-radius: 4rem;
  }

  .custom-switch .custom-control-label::after {
    width: calc(2rem - 4px);
    height: calc(2rem - 4px);
    border-radius: calc(3rem - (2rem / 2));
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(2rem - 0.25rem));
  }
`;
