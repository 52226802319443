import React from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getGlobalConfig } from "utils";

const SelectStudentTracking = ({
  selectTracking,
  changeTrackingObject = () => {},
}) => {
  const { personsProfile, personCourses } = useSelector((state) => state.crm);
  return (
    <>
      {personsProfile?.list_courses &&
        (selectTracking &&
        selectTracking?.id &&
        personsProfile?.tasks?.length > 0 ? (
          <div style={{ maxWidth: "300px" }}>
            <Form.Control
              as="select"
              value={selectTracking?.id}
              onChange={(event) => {
                changeTrackingObject(event.target.value);
              }}
              style={{ width: "100%" }}
            >
              {personCourses
                .filter((item) => item.id !== 0)
                .map((item, key) => {
                  return (
                    <option key={`initiatives-${key}`} value={item.id}>
                      {item.name}{" "}
                      {personsProfile.tasks &&
                        getGlobalConfig(
                          "show_tracking_object_external_id",
                          false
                        ) &&
                        ` -  ${
                          personsProfile.tasks.find((task) => {
                            return task?.tracking_object?.id === item.id;
                          })?.tracking_object.external_id ?? ""
                        }`}
                    </option>
                  );
                })}
            </Form.Control>
          </div>
        ) : (
          "..."
        ))}
    </>
  );
};
export default SelectStudentTracking;
