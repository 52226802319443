import React, { useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPencil } from "@fortawesome/pro-regular-svg-icons";

import { uploadImageProfile, uploadProfile } from "services/user.service";
import { changeAnonymous, updateUserSession } from "store/actions/auth.actions";
import { Modal, Row, Col, Form, Spinner } from "components";
import { IconContainer } from "../styled";
import { getDataFromLocalStorage, setDataToLocalStorage } from "utils";
import { NSpinnerPoints } from "components/Nimbiv2";
import styles from "./ModalUser.module.css";

const ModalUser = ({ user, show, handleClose }) => {
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [teams, setTeams] = React.useState([]);
  const [loadingAnonymous, setLoadingAnonymous] = React.useState(false);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    let teamsLocal = [];
    let user_teams = user?.user_teams ? user.user_teams : [];
    for (let i = 0; i < user_teams.length; i++) {
      teamsLocal.push(user_teams[i].name);
    }
    setTeams(teamsLocal);
  }, [user]);

  const handleChange = async (event) => {
    setLoading(true);
    const fileUploaded = event.target.files[0];
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(fileUploaded.name)) {
      toast.error("Lo siento, este tipo de archivo no está permitido");
      setLoading(false);
      return false;
    }
    const { status, data } = await uploadImageProfile(
      user.profile_id,
      fileUploaded
    );

    if (status === 200) {
      user.profile_picture = data["profile_image"];
      toast.success("La imagen fue subida exitosamente!.");
      dispatch(updateUserSession({ user: user }));
      setLoading(false);
      return true;
    } else {
      toast.error("Error al subir la imagen!.");
      setLoading(false);
      return false;
    }
  };

  const updateProrfileAnonymous = async (evt) => {
    const checked = evt;
    setLoadingAnonymous(true);
    const { status } = await uploadProfile(user.profile_id, {
      anonymous: evt,
    });
    if (status === 200) {
      dispatch(changeAnonymous(checked));
      let user = getDataFromLocalStorage("user");
      user.anonymous = checked;
      setDataToLocalStorage("user", user);
      if (checked) {
        toast.success("Se activo el modo incógnito");
      } else {
        toast.success("Se desactivo el modo incógnito");
      }
      setLoadingAnonymous(false);
    } else {
      setLoadingAnonymous(false);
      toast.error("Error al actualizar!.");
      return false;
    }
  };

  const getUserThumb = (photo, isBig = false) => {
    return (
      <div>
        <div
          className="d-flex"
          style={{
            border: "2.4px solid var(--brand-color)",
            borderRadius: "50%",
            position: "relative",
          }}
        >
          <IconContainer
            size="100px"
            colorBackground={photo !== null ? `url(${photo})` : null}
            style={{
              border: "2.4px solid white",
            }}
          >
            {photo === null && (
              <FontAwesomeIcon icon={faUser} size={isBig ? "5x" : "1x"} />
            )}

            {loading && (
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100px",
                  height: "100px",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: "50%",
                }}
              >
                <NSpinnerPoints size="30px" color="white" />
              </div>
            )}
          </IconContainer>

          <IconContainer
            size="25px"
            style={{
              position: "absolute",
              bottom: "2px",
              right: "-5px",
              backgroundColor: "var(--brand-color)",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={handleClick}
            radius="0px"
          >
            <FontAwesomeIcon
              icon={faPencil}
              style={{ fontSize: "10px", color: "white" }}
            />
          </IconContainer>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            accept=".png,.jpg,.jpeg,image/png,.gif"
            style={{ display: "none" }}
          />
        </div>
      </div>
    );
  };

  return (
    <ModalDesign
      className={styles.modalProfile}
      show={show}
      onHide={handleClose}
      style={{}}
    >
      <Modal.Header closeButton>
        <Modal.Title>
        {user && (
          <div>
            <div className="title">{t("my_data")}</div>
            <div className="title-name">{`${user?.first_name} ${user?.last_name}`}</div>
          </div>
        )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`px-4 ${styles.bodyContainer}`}>
        <Row>
          <Col className="d-flex justify-content-center" md={4}>
            {getUserThumb(user?.profile_picture, true)}
          </Col>
          <Col md={8}>
            {user ? (
              <Form>
                <div>
                  <div
                    className="item-data"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <strong>{_.upperFirst(t("mail"))}:</strong> {user?.username}
                    <br />
                  </div>
                  <div className="item-data">
                    <strong>{_.upperFirst(t("position"))}:</strong>{" "}
                    {user?.groups_alias}
                  </div>
                  <div className="item-data">
                    <strong>{_.upperFirst(t("teams"))}:</strong>{" "}
                    {teams.length > 0 ? teams.join(", ") : "-"}
                  </div>
                  <div className="item-data d-flex align-items-center">
                    <strong
                      className="mr-1"
                      onClick={() => {
                        updateProrfileAnonymous(!user?.anonymous);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("private_mode")}:
                    </strong>{" "}
                    <Form.Check
                      checked={user?.anonymous}
                      type="switch"
                      id="custom-switch"
                      label=""
                      onChange={(evt) => {
                        updateProrfileAnonymous(evt.target.checked);
                      }}
                    />
                    {loadingAnonymous && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ml-2"
                      />
                    )}
                  </div>
                </div>
              </Form>
            ) : null}
          </Col>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer
        className={`d-flex justify-content-start px-4 ${styles.footerContainer}`}
      >
        <span>Ver en Panel</span>
        <FontAwesomeIcon icon={faExternalLinkAlt} />
      </Modal.Footer> */}
    </ModalDesign>
  );
};

export default ModalUser;

const ModalDesign = styled(Modal)`
  .modal-content {
    border-radius: 16px;
  }
  .title {
    font-size: var(--font-size-small);
    color: #989da5;
    text-transform: uppercase;
    font-weight: 600;
  }
  .title-name {
    font-size: 24px;
    font-weight: 600;
  }
  .modal-body {
    background: #f8fbff;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .item-data {
    font-size: var(--font-size-lg);
    margin-top: 8px;
  }
`;
