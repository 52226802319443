import React from "react";
import _ from "lodash";
import { CardNimbi } from "components/Nimbiv2/styled";
import { ChangeLogPage } from "../detail";
import SelectStudentTracking from "./SelectStudentTracking";

const PageChangeLogStudent = ({
  student_tracking_id,
  selectTracking,
  changeTrackingObject = () => {},
}) => {
  return (
    <CardNimbi padding="16px 26px">
      <div className="d-flex justify-content-between">
        <div style={{ fontWeight: "600", fontSize: "20px" }}>
          {_.upperFirst("Historial de cambios")}
        </div>
        <div>
          <SelectStudentTracking
            selectTracking={selectTracking}
            changeTrackingObject={changeTrackingObject}
          />
        </div>
      </div>
      <ChangeLogPage student_tracking_id={student_tracking_id} />
    </CardNimbi>
  );
};
export default PageChangeLogStudent;
