import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useDefault } from "hooks";
import { getExtraColumns, getGlobalConfig } from "utils";
import { setActiveModule } from "store/actions/site.actions";
// import { fetchStudentTags } from "store/actions/async/student-async.actions";
import { fetchPersonHistory } from "store/actions/async/crm-async.action";
import { NTable } from "components/Nimbiv2";
import styles from "../Student.module.css";
import { ModalProfile, Image } from "components";
import { CardNimbi, NButtonCircle } from "components/Nimbiv2/styled";
import { Container, OverlayTrigger } from "react-bootstrap";
import moment from "moment";
import {
  faInfoCircle,
  faGraduationCap,
  faPhone,
  faEnvelope,
  faMessageLines,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LastManagement from "../../Task/LastManagement";
import {
  cleanCourseSelected,
  cleanCustomPerson,
  toggleCheckedArray,
} from "store/actions/student.actions";
import { useTranslation } from "react-i18next";
import useTranslationLocal from "hooks/useTranslationLocal";
import { cleanListCourses } from "store/actions/crm.actions";
import { fetchStudents } from "store/actions/async/student-async.actions";
import {
  ColumnInfoStudent,
  PopoverLastContact,
} from "components/Nimbiv2/PageStudent";
import { ButtonSize } from "utils/constants";
import RiskStudentBadge from "./RiskStudentBadge";
import { fetchEnterableListActionPlans } from "store/actions/async/moment-async.actions";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const StudentList = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const { listCheckable } = useSelector((state) => state.student);

  const {
    list: students,
    pending: { list: isPending },
    markAllItems,
    hasSelect,
  } = useSelector((state) => state.student);

  const { user } = useSelector((state) => state.auth);

  const [showLastContact, setShowLastContact] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [studentInfo, setStudentInfo] = useState(null);
  const studentModuleTitle = useDefault(
    "studentModuleTitle",
    translation("students", "startCase", t("students"))
  );
  const new_columns_table = getExtraColumns("tableStudent");

  useEffect(() => {
    dispatch(fetchStudents());
    if (getGlobalConfig("enable_massive_entry_into_action_plans")) {
      dispatch(fetchEnterableListActionPlans());
    }
  }, [dispatch]);

  useEffect(() => {
    if (studentModuleTitle)
      dispatch(
        setActiveModule({
          title: studentModuleTitle,
          subtitle: t("page_student_subtitle", {
            students: translation("students", "", t("students")),
          }),
        })
      );
  }, [dispatch, studentModuleTitle, t, translation]);

  const handleClose = () => {
    setStudentInfo(null);
    setShowLastContact(false);
  };

  const handleCloseProfile = () => {
    setShowProfile(false);
  };

  /*const toggleProfileModal = (student) => {
    dispatch(fetchPersonsProfile(student.id));
    dispatch(fetchPersonTasks(student.id));
    // dispatch(fetchPersonTasksHistory(student.id));
    dispatch(fetchPersonHistory(student.id));
    dispatch(fetchStudentNotes(student.id));
    dispatch(fetchStudentCourses(student.id));
    setShowProfile(true);
  };*/

  const goStudentPage = (item) => {
    dispatch(cleanCustomPerson());
    dispatch(cleanListCourses());
    dispatch(cleanCourseSelected());
    history.push(`/alumnos/${item.id}/`);
  };

  const toggleContactModal = (student) => {
    setStudentInfo({ full_name: `${student.firstName} ${student.lastName}` });
    // dispatch(fetchPersonTasksHistory(student.id));
    dispatch(fetchPersonHistory(student.id));
    setShowLastContact(true);
  };
  const iconsChannel = {
    Call: faPhone,
    Email: faEnvelope,
    WhatsApp: faWhatsapp,
    Blackboard: faMessageLines,
  };
  const displayLastContact = (row) => {
    if (row.lastContact) {
      return (
        <div className="d-flex align-items-end">
          <div className="d-flex flex-column align-items-center">
            {(row.lastContact?.user_photo ?? null) !== null && (
              <Image
                className={`${styles.contactThumb} mb-2`}
                src={row.lastContact.user_photo}
                alt="photo"
                aria-hidden="true"
                roundedCircle
                style={{ cursor: "pointer" }}
                onClick={() => toggleContactModal(row)}
              />
            )}
            {(row.lastContact?.user_photo ?? null) === null &&
              iconsChannel[row.lastContact.channel] && (
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  className="secondary_color mb-2"
                  size="2x"
                  icon={iconsChannel[row.lastContact.channel]}
                  onClick={() => toggleContactModal(row)}
                  aria-hidden="true"
                />
              )}
            <div className="d-flex align-items-center">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => toggleContactModal(row)}
                aria-hidden="true"
                className="secondary_color"
              >
                <u>{moment(row.lastContact?.closed_at).format("DD/MM/YYYY")}</u>
              </span>
              {/* <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="right"
                overlay={popover(row)}
              >
                <FontAwesomeIcon
                  className="text-muted "
                  icon={faInfoCircle}
                  style={{ marginLeft: "5px" }}
                />
              </OverlayTrigger> */}
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="right"
                overlay={
                  <PopoverLastContact
                    id="contact-popover"
                    data={row.lastContact}
                  />
                }
              >
                <FontAwesomeIcon
                  className="text-muted ml-2"
                  icon={faInfoCircle}
                  style={{ marginLeft: "5px" }}
                />
              </OverlayTrigger>
            </div>
          </div>
        </div>
      );
    }

    return <span>{t("no_information_line")}</span>;
  };

  const columns = [
    {
      columnName: "student",
      title: translation("student"),
      key: "student",
      fixed: true,
      style: { left: "0px" },
      width: "290",
      render: (item, key) => {
        return (
          <ColumnInfoStudent
            student={item}
            onClick={() => {
              goStudentPage(item);
            }}
            isAnonymous={user?.anonymous}
          />
        );
      },
    },
    {
      columnName: "past_tracking_object",
      title: translation("past_tracking_object"),
      key: "past_courses",
      width: "148",
      render: (item, key) => (
        <span className={`${styles.rowText}`}>{item.pastCourses}</span>
      ),
    },
    {
      columnName: "active_tracking_object",
      title: translation("active_tracking_object"),
      key: "active_courses",
      width: "141",
      render: (item, key) => (
        <span className={` ${styles.rowText}`}>
          {item.currentCourses}
          {item.hasSenceCourse && "*"}
        </span>
      ),
    },
    {
      columnName: "last_management",
      title: translation("last_management"),
      key: "last_management",
      width: "177",
      render: (item, key) => <div>{displayLastContact(item)}</div>,
    },
    ...(!getGlobalConfig("hide_nimbi_risk", false)
      ? [
          {
            columnName: "risk",
            title: translation("risk"),
            width: "115",
            key: "icon",
            center: true,
            render: (item, key) => (
              <div style={{ textAlign: "center" }}>
                <RiskStudentBadge risk={item.risk} />
              </div>
            ),
          },
        ]
      : []),
    ...getExtraColumns("tableStudent", true, translation),
    {
      columnName: "actions",
      title: "",
      key: "actions",
      fixed: true,
      style: { right: "0px" },
      width: "60",
      render: (item, key) => (
        <NButtonCircle
          color="second"
          sizeStyle={ButtonSize.lg}
          onClick={() => goStudentPage(item)}
          icon={
            <FontAwesomeIcon
              icon={faGraduationCap}
              style={{ marginTop: "4px" }}
            />
          }
        ></NButtonCircle>
      ),
    },
  ];
  const handleChecked = (data) => {
    dispatch(toggleCheckedArray({ listID: data }));
  };

  return (
    <Container fluid style={{ paddingBottom: "20px" }}>
      <CardNimbi
        padding="0px 16px 16px 16px"
        style={{ height: "auto", borderRadius: "8px" }}
      >
        <NTable
          tableName="tableStudent"
          widthSkeletons="100%"
          columns={columns}
          dataSource={students}
          isPending={isPending}
          upperCaseHeader
          check={listCheckable}
          onChange={(data) => {
            handleChecked(data);
          }}
          selectAll={markAllItems}
          hasSelect={hasSelect}
          fixed={new_columns_table.length > 0}
          hoverActive
          descriptionEmpty={
            <div>
              Aquí se mostrará toda la información de <br />
              tus alumnos cuando esté disponible
            </div>
          }
        ></NTable>

        {showLastContact ? (
          <LastManagement
            student={studentInfo}
            isVisible={showLastContact}
            onClose={handleClose}
          />
        ) : null}

        {/* Student profile Modal */}
        <ModalProfile
          notifyClose={handleCloseProfile}
          isVisible={showProfile}
        />
      </CardNimbi>
    </Container>
  );
};

export default StudentList;
