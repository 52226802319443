import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { CardNimbi, CardNimbiFixed } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { NButton, NTable } from "components/Nimbiv2";
import { useTranslationLocal } from "hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchEnterableActionPlans } from "store/actions/async/student-async.actions";
import { useParams } from "react-router-dom";

import SelectStudentTracking from "./SelectStudentTracking";
import ModalEnterActionPlan from "../detail/ModalEnterActionPlan";

const PageActionPlan = ({ selectTracking, changeTrackingObject }) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { enterable_action_plans } = useSelector((state) => state.student);
  const [show, setShow] = useState(false);
  const [momentsActive, setMomentsActive] = useState({});
  // const [check, setCheck] = useState(false);

  useEffect(() => {
    if (selectTracking) {
      dispatch(
        fetchEnterableActionPlans(id, {
          student_tracking_id: selectTracking.student_tracking_id,
        })
      );
    }
  }, [selectTracking, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const StudentTrackingIsActive = useMemo(() => {
    return selectTracking?.student_tracking_active ?? false;
  }, [selectTracking]);

  const columns = [
    {
      title: translation("action_plan_actives", "", "Planes de acción activos"),
      key: "name",
      render: (item, key) => <div>{item["name"]}</div>,
    },
    {
      title: translation("action_plan_start", "", "Fecha de inicio"),
      key: "action_plan_start",
      center: true,
      render: (item, key) => (
        <div className="text-center">
          {moment(item["created_at"]).format("DD-MM-YYYY")}
        </div>
      ),
    },
  ];

  return (
    <CardNimbi radius="8px" padding="0px" style={{ height: "100%" }}>
      <CardNimbi padding="16px 26px">
        <div
          className="d-flex justify-content-between"
          style={{ marginBottom: "24px" }}
        >
          <div style={{ fontWeight: "600", fontSize: "20px" }}>
            {_.upperFirst(t("action_plans"))}
          </div>
          <div>
            <SelectStudentTracking
              selectTracking={selectTracking}
              changeTrackingObject={changeTrackingObject}
            />
          </div>
        </div>
        <div style={{ height: "100%" }}>
          <NTable
            columns={columns}
            dataSource={selectTracking?.action_plans ?? []}
          ></NTable>
        </div>
      </CardNimbi>
      {StudentTrackingIsActive &&
        enterable_action_plans &&
        enterable_action_plans.length > 0 && (
          <CardNimbiFixed
            style={{
              height: "auto",
              boxShadow: "0px -10px 30px 0px #7F7F7F29",
              position: "sticky",
              top: 0,
            }}
          >
            <NButton
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => {
                setShow(true);
              }}
            >
              {"Ingresar a plan de acción"}
            </NButton>
          </CardNimbiFixed>
        )}
      <ModalEnterActionPlan
        show={show}
        setShow={setShow}
        selectTracking={selectTracking}
        momentsActive={momentsActive}
        setMomentsActive={setMomentsActive}
      />
    </CardNimbi>
  );
};
export default PageActionPlan;
