import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  CardNimbi,
  CommentBlock,
  IconContainer,
  RowItem,
} from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchExternalManagement } from "store/actions/async/external-management-async.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faExternalLinkAlt,
  faFile,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
import moment from "moment";
import { Col, OverlayTrigger, Tooltip } from "components";
import { ContainerEmpty, NButton, NSpinnerPoints } from "components/Nimbiv2";
import { getFormConfig, getGlobalConfig } from "utils";
import EmptyListSVG from "assets/images/EmptyListSVG";
import { Channels } from "utils/constants";
import FormSlideExternalManagement from "./FormSlideExternalManagement";
import SelectStudentTracking from "../v3/SelectStudentTracking";

const ExternalManagementPage = ({
  student_tracking_id,
  studentID,
  selectTracking,
  changeTrackingObject = () => {},
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    externalManagement,
    pending: { externalManagement: isPending },
  } = useSelector((state) => state.externalManagement);
  const [openRow, setOpenRow] = useState(null);
  const [showSlide, setShowSlide] = useState(false);
  const [lastData, setLastData] = useState({});
  const version3 = getGlobalConfig("enable_student_version_3", false);

  useEffect(() => {
    if (student_tracking_id) {
      dispatch(
        fetchExternalManagement({ student_tracking: student_tracking_id })
      );
    }
  }, [dispatch, student_tracking_id]);

  return (
    <CardNimbi radius="8px" padding="0px" style={{ height: "100%" }}>
      <CardNimbi padding="16px 26px">
        <div
          className={`d-flex ${
            version3 ? "justify-content-between" : "align-items-center"
          }`}
          style={{ marginBottom: "18px" }}
        >
          {!version3 && (
            <IconContainer className="mr-2">
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </IconContainer>
          )}
          <TitleText>{_.upperFirst(t("records"))}</TitleText>
          <div>
            <SelectStudentTracking
              selectTracking={selectTracking}
              changeTrackingObject={changeTrackingObject}
            />
          </div>
        </div>{" "}
        <div style={{ marginBottom: "32px" }}>
          {isPending && <NSpinnerPoints />}
          {!isPending &&
            externalManagement &&
            externalManagement.map((item, key) => {
              return (
                <CommentBlock
                  style={{ marginTop: "12px", display: "block" }}
                  key={`external-management-${key}`}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      className="mr-auto"
                      style={
                        openRow !== key
                          ? {
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }
                          : {}
                      }
                    >
                      <strong>
                        {moment(item.closed_at).format("DD/MM/YYYY")}
                      </strong>
                      {" - "}
                      {item.comment}
                    </div>
                    {getGlobalConfig(
                      "enable_massive_external_management",
                      false
                    ) &&
                      item?.load_massive && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              Gestión externa cargada desde archivo
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon={faFile}
                            style={{ cursor: "pointer", marginRight: "5px" }}
                          />
                        </OverlayTrigger>
                      )}
                    <div>
                      <FontAwesomeIcon
                        icon={openRow === key ? faAngleUp : faAngleDown}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (openRow === key) {
                            setOpenRow(null);
                          } else {
                            setOpenRow(key);
                          }
                        }}
                      />
                    </div>
                  </div>
                  {openRow === key && (
                    <CardNimbi
                      padding="20px 12px"
                      style={{ marginTop: "20px" }}
                    >
                      <RowItem>
                        <Col xs="3">
                          <strong style={{ marginRight: "8px" }}>Autor</strong>
                          {item?.created_by?.full_name}
                        </Col>
                        <Col xs="3">
                          <strong style={{ marginRight: "8px" }}>
                            {_.upperFirst(t("type"))}
                          </strong>
                          {item?.management_type}
                        </Col>
                        <Col xs="3">
                          <strong style={{ marginRight: "8px" }}>
                            {_.upperFirst(t("topic"))}
                          </strong>
                          {item?.management_topic}
                        </Col>
                        <Col xs="3">
                          <strong style={{ marginRight: "8px" }}>
                            {_.upperFirst(t("channel"))}
                          </strong>
                          {_.get(Channels, item?.channel, item.channel)}
                        </Col>
                        <Col xs="3">
                          <strong style={{ marginRight: "8px" }}>
                            {_.upperFirst(t("state"))}
                          </strong>
                          {item?.management_state}
                        </Col>
                        <Col xs="3">
                          <strong style={{ marginRight: "8px" }}>
                            {t("management_date")}
                          </strong>
                          {moment(item?.closed_at).format("DD-MM-YYYY")}
                        </Col>
                        {getFormConfig(
                          "form_external_management",
                          "show_subtopic"
                        ) && (
                          <Col xs="3">
                            <strong style={{ marginRight: "8px" }}>
                              {_.upperFirst(t("sub_topic"))}
                            </strong>
                            {item?.management_subtopic}
                          </Col>
                        )}
                        {getGlobalConfig(
                          "enable_massive_external_management",
                          false
                        ) && (
                          <Col xs="3">
                            <strong style={{ marginRight: "8px" }}>
                              {t("bulk_upload")}
                            </strong>
                            {item?.load_massive ? "SI" : "NO"}
                          </Col>
                        )}
                        {getFormConfig(
                          "form_external_management",
                          "show_notification_email"
                        ) &&
                          item?.with_notification && (
                            <Col xs="4">
                              <strong style={{ marginRight: "8px" }}>
                                {t("notify_person_in_charge")}
                              </strong>
                              {item?.notification_email
                                ? item.notification_email
                                : _.upperFirst(t("pending_one"))}
                            </Col>
                          )}
                      </RowItem>
                    </CardNimbi>
                  )}
                </CommentBlock>
              );
            })}
          {!isPending &&
            externalManagement &&
            externalManagement.length === 0 && (
              <ContainerEmpty
                version="2"
                image={<EmptyListSVG />}
                text={"Aún no hay gestiones externas"}
                description={
                  <div>Empieza creando una utilizando el formulario</div>
                }
              />
            )}
        </div>
      </CardNimbi>
      {/* <DividerH /> */}
      {/* <div>
        <div
          className="d-flex align-items-center"
          style={{ marginTop: "22px", marginBottom: "30px" }}
        >
          <IconContainer className="mr-2">
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </IconContainer>
          <TitleText>{t("new_registration")}</TitleText>
        </div>{" "}
        {!clean && (
          <FormExternalManagement
            formValues={initialValues}
            onFormSubmit={saveChanges}
          />
        )}
      </div> */}
      <CardNimbiDesign
        style={{
          height: "auto",
          boxShadow: "0px -10px 30px 0px #7F7F7F29",
          position: "sticky",
          top: 0,
        }}
      >
        <NButton
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => {
            setShowSlide(!showSlide);
          }}
        >
          Nuevo registro
        </NButton>
      </CardNimbiDesign>
      <FormSlideExternalManagement
        student_tracking_id={student_tracking_id}
        studentID={studentID}
        open={showSlide}
        onClose={() => {
          setShowSlide(false);
        }}
        saveLastValue={(values) => {
          setLastData(values);
        }}
        lastData={lastData}
      />
    </CardNimbi>
  );
};

export default ExternalManagementPage;

const TitleText = styled.div`
  color: var(--text-primary, #2a2b2e);
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
`;

const CardNimbiDesign = styled(CardNimbi)`
  position: fixed;
  bottom: 0;
  box-shadow: 0px -10px 30px 0px #7f7f7f29;
`;
