import React from "react";
import _ from "lodash";
import { CardNimbi } from "components/Nimbiv2/styled";
import { HistoryTask } from "../detail";
import { useTranslation } from "react-i18next";

const PageHistoryStudent = () => {
  const { t } = useTranslation();
  return (
    <CardNimbi padding="16px 26px">
      <HistoryTask title={_.upperFirst(t("record"))} />
    </CardNimbi>
  );
};
export default PageHistoryStudent;
