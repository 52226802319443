import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Col, Container, Tab } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setActiveModule } from "store/actions/site.actions";
import { Tasks } from "components";
import { NNavbarDashboard, ScrollToTopOnMount } from "components/Nimbiv2";
import { CardNimbi, NTabs, RowItem } from "components/Nimbiv2/styled";
import { CardIndicatorStudent } from "components/Nimbiv2/PageStudent";
import {
  StudentProfile,
  InfoStudent,
  HistoryTask,
  ObservationPage,
  PageRisk,
  AttendancePage,
  GradePage,
  ExternalManagementPage,
  ChangeLogPage,
  SurveyPage,
  MilestonePage,
} from "containers/Student/detail";
import {
  fetchPersonHistory,
  fetchPersonsProfile,
  fetchStudentTrackingsPerson,
  fetchStudentCourses,
  fetchStudentMoments,
  fetchStudentInitiatives,
} from "store/actions/async/crm-async.action";
import {
  fetchStudentNotes,
  fetchStudentInsights,
  fetchStudentRisk,
  //  fetchProgressAttendance,
  //  fetchProgressGrades,
} from "store/actions/async/student-async.actions";
import { getGlobalConfig, getText, verifyPermissionTab } from "utils";
import { cleanCustomPerson } from "store/actions/student.actions";
import { cleanListCourses } from "store/actions/crm.actions";
import { IconRisk } from "assets/images";
import TabZendesk from "components/ModalProfile/TabZendesk";
import CardIndicator from "components/Skeletons/CardIndicator";
import { AcademicAdvancement } from "containers/Task/detail";
import { useTranslationLocal } from "hooks";
import RiskHistoryPage from "containers/Student/detail/RiskHistoryPage";

const StudentDetailV2 = () => {
  // initial data
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const [tabSelect, setTabSelect] = useState("information");
  const [person, setPerson] = useState(null);
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();

  // initial get store
  const { personsProfile, personCourses } = useSelector((state) => state.crm);
  const { user } = useSelector((state) => state.auth);
  const {
    insights,
    courseSelected,
    pending: { insightsPending: isPending },
  } = useSelector((state) => state.student);

  //contents
  useEffect(() => {
    if (personsProfile) {
      setPerson(personsProfile);
    }
  }, [personsProfile]);

  useEffect(() => {
    return () => {
      dispatch(cleanListCourses());
      dispatch(cleanCustomPerson());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStudentCourses(id));
    dispatch(fetchPersonsProfile(id));
    dispatch(fetchStudentTrackingsPerson(id, { page: 1, page_size: 100 }));
    dispatch(fetchPersonHistory(id));
    dispatch(fetchStudentNotes(id));
    dispatch(fetchStudentMoments(id));
    dispatch(fetchStudentInitiatives(id));
    // dispatch(fetchEnterableActionPlans(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (courseSelected) {
      dispatch(fetchStudentInsights(id, { course: courseSelected }));
      dispatch(fetchStudentRisk(id, { course: courseSelected }));
    }
  }, [dispatch, id, courseSelected]);

  useEffect(() => {
    dispatch(
      setActiveModule({
        title: translation(
          "studentDetailPageTitle",
          null,
          _.upperFirst(t("info_student"))
        ),
        subtitle: null,
        breadcrumbles: [
          {
            title: location.state
              ? _.upperFirst(t(location.state.back))
              : _.upperFirst(translation("students", "", t("students"))),
            url: location.state ? location.state.link : "/alumnos",
          },
          {
            title: `${
              personsProfile
                ? `${personsProfile.first_name ?? ""} ${
                    personsProfile.last_name ?? ""
                  }`
                : "..."
            }`,
            url: null,
          },
        ],
      })
    );
  }, [dispatch, personsProfile, location.state, translation, t]);

  let cardsTemplate = [
    {
      icon: "flag",
      title: _.upperFirst(t("risk")),
      data: insights?.risk?.data,
      days_difference: "-",
      empty_image: IconRisk,
    },
  ];
  if (getGlobalConfig("hide_nimbi_risk", false)) {
    cardsTemplate = [
      {
        icon: "flag",
        title: _.upperFirst(t("no_information")),
        data: [],
        days_difference: "-",
        empty_image: IconRisk,
      },
    ];
  }

  const student_tracking_id = useMemo(() => {
    if (courseSelected) {
      const new_array = personCourses.filter(
        (item) => item?.id?.toString() === courseSelected.toString()
      );
      if (new_array.length > 0) {
        return new_array[0].student_tracking_id;
      }
    }
    return null;
  }, [courseSelected, personCourses]);

  // useEffect(() => {
  //   if (student_tracking_id) {
  //     if (verifyPermissionTab("tabProfile", "progress_attendance")) {
  //       dispatch(fetchProgressAttendance(student_tracking_id));
  //     }
  //     if (verifyPermissionTab("tabProfile", "progress_grades")) {
  //       dispatch(fetchProgressGrades(student_tracking_id));
  //     }
  //   }
  // }, [student_tracking_id, dispatch]);

  return (
    <div style={{ paddingBottom: "100px" }}>
      <ScrollToTopOnMount />
      <NNavbarDashboard breadcrumbles={true} activeCircle={false} />
      <Container fluid style={{ margin: "0px", marginTop: "40px" }}>
        <RowItem>
          <Col lg="4" md="12" style={{ paddingLeft: "0px" }}>
            <StudentProfile />
          </Col>
          <Col lg="8" md="12" style={{ paddingLeft: "0px" }}>
            {!isPending && insights.length > 0 && (
              <RowItem>
                {insights
                  .filter((item) => {
                    if (
                      insights.length > 1 &&
                      getGlobalConfig("hide_nimbi_risk", false) &&
                      item?.title === "Riesgo"
                    ) {
                      return false;
                    }
                    return true;
                  })
                  .map((item, key) => {
                    return (
                      <Col lg="4" md="6" key={`${key}-card-indicator`}>
                        <CardIndicatorStudent
                          item={item}
                          labelModal={
                            item?.title === "Riesgo"
                              ? _.upperFirst(t("risk"))
                              : null
                          }
                        />
                      </Col>
                    );
                  })}
              </RowItem>
            )}
            {!isPending && courseSelected && insights.length === 0 && (
              <RowItem>
                {cardsTemplate.map((item, key) => {
                  return (
                    <Col lg="4" md="6" key={`${key}-card-indicator`}>
                      <CardIndicatorStudent item={item} />
                    </Col>
                  );
                })}
              </RowItem>
            )}
            {(isPending || !courseSelected) && (
              <RowItem>
                {["1", "2", "3"].map((item, key) => {
                  return (
                    <Col lg="4" md="6" key={`${key}-card-indicator-skeletons`}>
                      <CardIndicator />
                    </Col>
                  );
                })}
              </RowItem>
            )}
            <Container
              fluid
              style={insights.length > 0 ? { marginTop: "24px" } : {}}
            >
              <RowItem>
                <Col xs="12" style={{ paddingLeft: "0px" }}>
                  <NTabs
                    onSelect={(data) => {
                      setTabSelect(data);
                    }}
                    marginbottom="16px"
                    scroll={1}
                  >
                    <Tab
                      eventKey="information"
                      title={getText(
                        "information",
                        "startCase",
                        t("information")
                      )}
                    ></Tab>
                    <Tab
                      eventKey="history"
                      title={getText("history", "startCase", t("record"))}
                    ></Tab>
                    {!getGlobalConfig("hide_nimbi_risk", false) && (
                      <Tab
                        eventKey="risk"
                        title={getText("risk", "startCase", t("risk"))}
                      ></Tab>
                    )}
                    <Tab
                      eventKey="courses"
                      title={getText(
                        "tracking_objects",
                        "startCase",
                        t("courses")
                      )}
                    ></Tab>
                    {getGlobalConfig("observations_available", true) && (
                      <Tab
                        eventKey="observations"
                        title={getText(
                          "observations",
                          "startCase",
                          t("observations")
                        )}
                      ></Tab>
                    )}
                    {verifyPermissionTab(
                      "tabTaskDetail",
                      "academic_advancement"
                    ) &&
                      student_tracking_id && (
                        <Tab
                          eventKey="academic_advancement"
                          title={t("academic_progress")}
                        ></Tab>
                      )}

                    {verifyPermissionTab("tabProfile", "progress_grades") && (
                      <Tab
                        eventKey="grades"
                        title={_.upperFirst(t("grades"))}
                      ></Tab>
                    )}
                    {verifyPermissionTab(
                      "tabProfile",
                      "progress_attendance"
                    ) && (
                      <Tab
                        eventKey="attendance"
                        title={_.upperFirst(t("attendance"))}
                      ></Tab>
                    )}
                    {verifyPermissionTab("tabProfile", "zendesk") && (
                      <Tab
                        eventKey="zendesk"
                        title={getText(
                          "zendesk",
                          "startCase",
                          t("portal_help")
                        )}
                      ></Tab>
                    )}
                    {verifyPermissionTab(
                      "tabProfile",
                      "externalManagement"
                    ) && (
                      <Tab
                        eventKey="external_management_list"
                        title={_.upperFirst(t("external_management"))}
                      ></Tab>
                    )}
                    {verifyPermissionTab("tabProfile", "change_logs") && (
                      <Tab
                        eventKey="change_logs"
                        title={_.upperFirst(t("changelog"))}
                      ></Tab>
                    )}
                    {verifyPermissionTab("tabProfile", "risk_history") && (
                      <Tab
                        eventKey="risk_history"
                        title={_.upperFirst(t("risk_history"))}
                      ></Tab>
                    )}
                    {verifyPermissionTab("tabProfile", "surveys") && (
                      <Tab
                        eventKey="surveys"
                        title={_.upperFirst(t("surveys"))}
                      ></Tab>
                    )}
                    {verifyPermissionTab("tabProfile", "milestone") &&
                      personsProfile?.periodo_ingreso_carrera && (
                        <Tab
                          eventKey="milestone"
                          title={getText(
                            "milestone",
                            "startCase",
                            t("milestone")
                          )}
                        ></Tab>
                      )}
                  </NTabs>
                </Col>
                <Col xs="1"></Col>
                <Col xs="3" style={{ paddingRight: "0px" }}></Col>
              </RowItem>
            </Container>
            <Container fluid>
              <RowItem>
                <Col
                  lg="12"
                  md="12"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  {tabSelect === "information" && (
                    <div>
                      <InfoStudent />
                    </div>
                  )}
                  {tabSelect === "history" && (
                    <div>
                      <HistoryTask />
                    </div>
                  )}
                  {tabSelect === "risk" && (
                    <div>
                      <PageRisk />
                    </div>
                  )}
                  {tabSelect === "courses" && (
                    <CardNimbi style={{ height: "auto" }}>
                      <Tasks
                        data={person ? person.tasks : []}
                        marginTop={false}
                        isAnonymous={user?.anonymous}
                      />
                    </CardNimbi>
                  )}
                  {tabSelect === "observations" &&
                    getGlobalConfig("observations_available", true) && (
                      <ObservationPage studentID={id} />
                    )}
                  {tabSelect === "academic_advancement" &&
                    student_tracking_id &&
                    verifyPermissionTab(
                      "tabTaskDetail",
                      "academic_advancement"
                    ) && (
                      <AcademicAdvancement
                        student_tracking_id={student_tracking_id}
                        callFetch={true}
                      />
                    )}
                  {tabSelect === "zendesk" && (
                    <CardNimbi style={{ height: "auto" }}>
                      <TabZendesk person={person} />
                    </CardNimbi>
                  )}
                  {tabSelect === "grades" &&
                    student_tracking_id &&
                    verifyPermissionTab("tabProfile", "progress_grades") && (
                      <GradePage student_tracking_id={student_tracking_id} />
                    )}
                  {tabSelect === "attendance" &&
                    student_tracking_id &&
                    verifyPermissionTab(
                      "tabProfile",
                      "progress_attendance"
                    ) && (
                      <AttendancePage
                        student_tracking_id={student_tracking_id}
                      />
                    )}
                  {tabSelect === "external_management_list" &&
                    verifyPermissionTab("tabProfile", "externalManagement") && (
                      <ExternalManagementPage
                        student_tracking_id={student_tracking_id}
                        studentID={id}
                      />
                    )}
                  {tabSelect === "change_logs" &&
                    verifyPermissionTab("tabProfile", "externalManagement") && (
                      <ChangeLogPage
                        student_tracking_id={student_tracking_id}
                      />
                    )}
                  {tabSelect === "risk_history" &&
                    verifyPermissionTab("tabProfile", "risk_history") && (
                      <RiskHistoryPage
                        student_tracking_id={student_tracking_id}
                      />
                    )}
                  {tabSelect === "surveys" &&
                    student_tracking_id &&
                    verifyPermissionTab("tabProfile", "surveys") && (
                      <SurveyPage student_tracking_id={student_tracking_id} />
                    )}
                  {tabSelect === "milestone" &&
                    student_tracking_id &&
                    verifyPermissionTab("tabProfile", "milestone") && (
                      <MilestonePage
                        student_tracking_id={student_tracking_id}
                      />
                    )}
                </Col>
              </RowItem>
            </Container>
          </Col>
        </RowItem>
      </Container>
    </div>
  );
};
export default StudentDetailV2;
