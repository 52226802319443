import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import NSpinnerPoints from "../Spinners/NSpinnerPoints";
import { faBarsFilter } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Collapse, Container } from "react-bootstrap";
const ContainerFilters = ({
  isPendingCount,
  countItems = 0,
  textCount,
  hasFilters,
  renderFilterBadges = () => <></>,
  renderFilterGroup = () => <></>,
}) => {
  const { t } = useTranslation();
  const [expandFilters, setExpandFilters] = useState(false);
  return (
    <div className="w-100">
      <ContainerFiltersDesign>
        <div className="d-flex justify-content-between">
          <div>
            <strong className="title">
              {isPendingCount ? <NSpinnerPoints size="18px" /> : countItems}
            </strong>{" "}
            {textCount ?? t("pending_tasks")}
          </div>
          <div className="d-flex">
            <div
              className="d-flex justify-content-end align-items-center"
              style={{ marginRight: "12px" }}
            >
              {renderFilterBadges()}
            </div>
            {hasFilters ? (
              <div
                className="title-filter"
                onClick={() => {
                  setExpandFilters((sw) => !sw);
                }}
              >
                {expandFilters
                  ? _.upperFirst(t("hide"))
                  : _.upperFirst(t("filters"))}
                <FontAwesomeIcon
                  icon={faBarsFilter}
                  style={{ height: "14px", marginLeft: "5px" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </ContainerFiltersDesign>

      {/* <Row style={{ margin: 0 }} className="w-100"> */}
      <Collapse in={expandFilters} style={{ width: "100%", marginTop: "4px" }}>
        <ContainerFiltersDesign>
          <Container fluid className="mt-2">
            {renderFilterGroup()}
          </Container>
        </ContainerFiltersDesign>
      </Collapse>
      {/* </Row> */}
    </div>
  );
};

export default ContainerFilters;

const ContainerFiltersDesign = styled(Card)`
  border: 0px;
  border-radius: 8px;
  background: #fafcfe;
  padding: 15px 20px;
  .title {
    color: #334fff;
    font-size: 16px;
  }
  .title-filter {
    font-size: 14px;
    color: #334fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
