import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  StepTitle,
  TextQuestion,
  TextTitle,
} from "components/Nimbiv2/InitiativesComponent";
import {
  CardNimbi,
  RowItem,
  AnonymousContainer,
  NTabs,
} from "components/Nimbiv2/styled";
import { Col, Tab } from "react-bootstrap";
import { NIcon, NTable, NButton, NTextArea } from "components/Nimbiv2";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import {
  APP_MODULES,
  cleanNullJson,
  getGlobalConfig,
  getSessionFilters,
  setSessionFilters,
  storeClientAllFilters,
  deleteLocalStorage,
} from "utils";
import _ from "lodash";
import FormFilter from "../../../components/NFormFilter";
import { cleanFilter, updateClientFilters } from "store/actions/site.actions";
import { fetchStudentsTracking } from "store/actions/async/initiatives-async.actions";
import PaginationBar from "../PaginationBar";
import { useTranslation } from "react-i18next";
import useTranslationLocal from "hooks/useTranslationLocal";
import { ButtonSize } from "utils/constants";
import { fetchClientFilters } from "store/actions/async/site-async.action";
import RiskScoreTask from "containers/Task/v2/columns/RiskScoreTask";
const panel = APP_MODULES.INITIATIVES.ID;

const type_initiative = {
  Email: "email",
  Call: "call",
};
const PageFourth = ({
  onChange,
  onChangeForm,
  formValues,
  pageNumber,
  edit = false,
  errors,
}) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const dispatch = useDispatch();
  const {
    clientFilters,
    pending: { clientFilters: isPendingFilter },
    errors: { clientFilters: isErrorFilters },
  } = useSelector((state) => state.site);
  const { user } = useSelector((state) => state.auth);
  const filtersData = useMemo(() => clientFilters.data[panel] || [], [
    clientFilters.data,
  ]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [tempFilters, setTempFilters] = useState({});
  const [clientFilterTemp, setClientFilterTemp] = useState([]);
  const [ruts, setRuts] = useState(formValues.ruts ?? null);

  const {
    list: students,
    pending: { list: isPending },
    paginationTracking,
  } = useSelector((state) => state.initiative);

  const selectedTab = useMemo(
    (item) => {
      return formValues.student_tracking_tab ?? "filters";
    },
    [formValues.student_tracking_tab]
  );

  useEffect(() => {
    if (
      edit &&
      students &&
      formValues &&
      formValues.student_trackings &&
      formValues.student_trackings.length > 0
    ) {
      if (paginationTracking.count === formValues.student_trackings.length) {
        onChangeForm("student_trackings", [0], { selectAll: true });
      }
    }
  }, [students, edit, formValues, onChangeForm, paginationTracking.count]);

  useEffect(() => {
    if (paginationTracking)
      onChangeForm("len_student_trackings", paginationTracking.count);
    else onChangeForm("len_student_trackings", 0);
  }, [paginationTracking]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errors.hasOwnProperty("student_trackings")) {
      window.scrollTo(0, 0);
      setBtnDisabled(true);
    }
  }, [errors]);

  const getCoursesActive = (item) => {
    if (item.student_courses.length === 0) {
      return <div>{translation("message_not_have_courses_active")}</div>;
    }
    return (
      <p style={{ whiteSpace: "normal", textOverflow: "ellipsis" }}>
        {item.student_courses[0].name}{" "}
        {item.student_courses.length > 1 && (
          <span
            style={{
              background: "linear-gradient(0deg, #EDF1F7, #EDF1F7), #FFFFFF",
              borderRadius: "64px",
              padding: "2px 6px",
              fontSize: "10px",
              display: "inline-block",
            }}
          >
            <NIcon style={{ fontSize: "10px" }} iconname="plus" />{" "}
            {item.student_courses.length - 1}
          </span>
        )}
      </p>
    );
  };
  const columns = [
    {
      title: translation("student"),
      dataIndex: "student",
      key: "student",
      width: "25%",
      render: (value, item) => {
        return (
          <div>
            <div style={{ fontWeight: 500, letterSpacing: "0.3px" }}>
              {!user.anonymous && item.student.full_name}
              {user.anonymous && (
                <>
                  {item.student.full_name.split(" ", 1)}{" "}
                  <AnonymousContainer
                    isAnonymous={user?.anonymous}
                    radius="5px"
                  >
                    {item.student.full_name.split(" ").splice(1).join(" ")}
                  </AnonymousContainer>
                </>
              )}
            </div>
            <div
              style={{ fontSize: "var(--font-size-small)", color: "#787C83" }}
            >
              <AnonymousContainer isAnonymous={user?.anonymous}>{`${
                (item.student.document_type ?? "Other") === "Other"
                  ? translation("document_type_dynamic", "", t("other"))
                  : item.student.document_type ?? ""
              } ${item.student.document_number}`}</AnonymousContainer>
            </div>
            {getGlobalConfig("id_student_show", "yes") === "yes" && (
              <div
                style={{
                  fontSize: "var(--font-size-small)",
                  color: "#787C83",
                  lineHeight: "120%",
                  marginTop: "2px",
                }}
              >
                <AnonymousContainer isAnonymous={user?.anonymous}>
                  {item.student.external_id}
                </AnonymousContainer>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: translation("tracking_object_campaign"),
      dataIndex: "currentCourses",
      key: "currentCourses",
      width: "25%",
      render: (value, item) => {
        return <div>{getCoursesActive(item)}</div>;
      },
    },
    {
      title: translation("campus"),
      key: "sede",
      width: "24%",
      render: (item) => {
        return (
          <div>
            <AnonymousContainer isAnonymous={user?.anonymous}>
              {item.campus_name}
            </AnonymousContainer>
          </div>
        );
      },
    },
    {
      title: translation("risk"),
      dataIndex: "risk",
      key: "risk",
      width: "15%",
      render: (value, item) => {
        return (
          <div>
            <RiskScoreTask
              item={item}
              property="last_risk_score"
              propertyRiskTag="risk_tag"
            />
          </div>
        );
      },
    },
  ];

  const handleFilterChanged = useCallback(
    (content, dataClientFilter) => {
      if (_.isEqual(clientFilters.filters[panel], content) === false) {
        setClientFilterTemp(dataClientFilter);
        setTempFilters({
          data: clientFilters.data,
          filters: { ...clientFilters.filters, ...{ [panel]: content } },
        });
      }
    },
    [clientFilters.data, clientFilters.filters]
  );

  const updateSessionFilters = useCallback(() => {
    const isEmpty = Object.keys(tempFilters).length === 0;
    if (isEmpty) return false;
    if (tempFilters.filters) {
      onChangeForm(
        "clientfilter_values",
        tempFilters.filters[panel],
        {
          client_filters: clientFilterTemp,
        },
        false,
        false,
        false,
        ["ruts"]
      );
    }
    dispatch(updateClientFilters(tempFilters));
    storeClientAllFilters(
      tempFilters.filters[panel],
      APP_MODULES.INITIATIVES.FILTER_NAME
    );
  }, [dispatch, tempFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderFilterGroup = useCallback(() => {
    if (filtersData.length === 0 || !panel) return null;
    const hasFilters = Object.keys(clientFilters.filters || {}).length > 0;
    const filtersetData = hasFilters ? clientFilters.filters[panel] : {};
    return (
      <div style={{ padding: "32px 29px" }}>
        <FormFilter
          data={filtersData}
          onChange={handleFilterChanged}
          filterset={filtersetData ?? {}}
          isDisabled={false}
        />
      </div>
    );
  }, [filtersData, clientFilters.filters, handleFilterChanged]);

  const getAllResults = useCallback(
    ({ ruts = null, exceptionLastFilters = false } = {}) => {
      let queryParams = {};
      if (exceptionLastFilters || (ruts && ruts !== "")) {
        deleteLocalStorage(APP_MODULES.INITIATIVES.FILTER_NAME);
      }
      queryParams = getSessionFilters(
        APP_MODULES.INITIATIVES.FILTER_NAME,
        true
      );
      let data = {};
      let payloadPost = {};
      data = {
        initiative_type: type_initiative[formValues.optionInitiative],
        campuses: formValues.campuses || null,
        tracking_objects: formValues.tracking_objects || null,
      };
      if (ruts && ruts !== "") {
        payloadPost["ruts"] = ruts.replaceAll(" ", "");
      }
      const payload = {
        ...queryParams,
        ...data,
      };
      if (Object.keys(queryParams).length === 2) {
        setSessionFilters(APP_MODULES.INITIATIVES.FILTER_NAME, payload);
      }

      dispatch(
        fetchStudentsTracking(
          cleanNullJson(payload),
          Object.keys(payloadPost).length > 0 ? payloadPost : null
        )
      );
    },
    [
      dispatch,
      formValues.campuses,
      formValues.optionInitiative,
      formValues.tracking_objects,
    ] // eslint-disable-next-line
  ); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Apply filters
    updateSessionFilters();
    getAllResults();
  }, [tempFilters, updateSessionFilters, getAllResults]);

  const cleanFilters = () => {
    getAllResults({ exceptionLastFilters: true });
    dispatch(cleanFilter(panel));
  };

  const changeTab = (key) => {
    onChangeForm("student_tracking_tab", key, {}, false, false, false, [
      "selectAll",
      "student_trackings",
      "ruts",
    ]);
    if (selectedTab !== key) {
      cleanFilters();
    }
  };

  const searchRuts = () => {
    getAllResults({ ruts });
    onChangeForm("ruts", ruts.replaceAll(" ", ""), {}, false, false, false, [
      "clientfilter_values",
      "client_filters",
      "selectAll",
      "student_trackings",
    ]);
  };

  return (
    <div style={{ maxWidth: "922px", margin: "auto", paddingBottom: "30px" }}>
      <CardNimbi padding="0px">
        <div style={{ padding: "33px 35px 33px 35px" }}>
          <RowItem>
            <Col xs={8} style={{ padding: "0px" }}>
              <StepTitle>
                {pageNumber ? pageNumber : t("step_number", { number: "5" })}
              </StepTitle>
              <div style={{ marginTop: "18px" }}>
                <TextTitle>
                  {t("title_select_criteria_initiative", {
                    students: translation("students", "", t("students")),
                  })}
                </TextTitle>
              </div>
            </Col>
          </RowItem>
        </div>
        {errors.hasOwnProperty("student_trackings") && (
          <div style={{ padding: "16px 35px 33px 35px" }}>
            <div style={{ color: "#E23232" }}>
              * {errors["student_trackings"]}
              <NIcon
                iconname="exclamationCircle"
                style={{ marginLeft: "4px" }}
              ></NIcon>
            </div>
          </div>
        )}
        <div
          style={{
            position: "relative",
            paddingRight: "35px",
            paddingLeft: "35px",
          }}
        >
          <NTabs
            activeKey={formValues.student_tracking_tab ?? "filters"}
            id="tabs-create-initiatives"
            onSelect={changeTab}
            marginbottom="2px"
            style={{ marginLeft: "0px", width: "100%" }}
          >
            <Tab eventKey="filters" title={_.upperFirst(t("filters"))} />
            <Tab eventKey="filters-masive" title={t("mass_filter")} />
          </NTabs>
        </div>

        {selectedTab === "filters" && (
          <ContainerFilters>
            {isPendingFilter && !isErrorFilters && (
              <div style={{ padding: "32px 29px" }}>
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="fas fa-cog fa-spin"
                  style={{ fontSize: "50px" }}
                />
              </div>
            )}
            {!isPendingFilter && !isErrorFilters && renderFilterGroup()}
            {!isPendingFilter && isErrorFilters && (
              <div style={{ padding: "32px 29px" }}>
                <NButton
                  onClick={() => {
                    dispatch(
                      fetchClientFilters({ module: APP_MODULES.INITIATIVES.ID })
                    );
                  }}
                >
                  {t("reload_filters")}
                </NButton>
              </div>
            )}
          </ContainerFilters>
        )}
        {selectedTab === "filters-masive" && (
          <ContainerFilters>
            <div style={{ padding: "32px 29px" }}>
              <NTextArea
                helpText={t("help_ducoment_numbers")}
                onChange={(ruts) => {
                  setRuts(ruts);
                }}
                defaultValue={ruts}
              />
              <div className="d-flex justify-content-end mt-2">
                <NButton
                  disabled={isPending}
                  loading={isPending}
                  onClick={searchRuts}
                >
                  Buscar
                </NButton>
              </div>
            </div>
          </ContainerFilters>
        )}

        <div style={{ padding: "16px 35px 33px 35px" }}>
          <div>
            {!isPending && (
              <TextQuestion>
                {_.upperFirst(translation("students", "", t("students")))} (
                {paginationTracking ? paginationTracking.count : "0"})
              </TextQuestion>
            )}
          </div>
          <div style={{ marginTop: "30px" }}>
            <NTable
              isPending={isPending}
              defaultValue={formValues.student_trackings}
              keyValue={"id"}
              columns={columns}
              dataSource={students}
              check={true}
              hasSelect={
                formValues.student_trackings &&
                formValues.student_trackings.length > 0
              }
              upperCaseHeader
              onChange={(selected, extra) => {
                onChangeForm("student_trackings", selected, extra);
                setBtnDisabled(false);
              }}
              selectAll={formValues.selectAll ?? false}
              textEmpty={t("no_students_to_display", {
                students: translation("students", "", t("students")),
              })}
            />
            {!isPending && (
              <PaginationBar
                campuses={formValues.campuses}
                tracking_objects={formValues.tracking_objects}
                showMore={true}
              />
            )}
          </div>
        </div>
      </CardNimbi>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          <NButton
            sizeStyle={ButtonSize.lg}
            style={{
              marginTop: "24px",
              borderRadius: "24px",
              marginRight: "16px",
            }}
            onClick={() => {
              onChange("last");
            }}
            outlined
            icon={<NIcon iconname="arrowLeft" />}
            color="second"
          >
            {t("go_back")}
          </NButton>
          <NButton
            sizeStyle={ButtonSize.lg}
            style={{
              marginTop: "24px",
              borderRadius: "24px",
              textTransform: "capitalize",
            }}
            onClick={() => {
              onChange("next");
            }}
            icon={<NIcon iconname="arrowRight" />}
            positionIcon="right"
            color="second"
            disabled={btnDisabled}
          >
            {t("continue")}
          </NButton>
        </div>
      </div>
    </div>
  );
};

export default PageFourth;
const ContainerFilters = styled.div`
  background: #f8fbff;
`;
