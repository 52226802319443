import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";

import { NIcon } from "..";
import { IconContainer } from "../styled";
import styles from "./ResultCall.module.css";

const ToggleHeader = ({
  title,
  eventKey,
  selectMenu,
  children,
  icon,
  iconname,
  flexTitle = false,
  childIcon,
  version,
  onClick,
}) => {
  return (
    <Accordion.Toggle
      as={Card.Header}
      eventKey={childIcon ? null : eventKey}
      className={styles.toggle}
      disabled={true}
      style={version === 3 ? { marginTop: "20px", color: "#71819C" } : {}}
      onClick={onClick}
    >
      <div className="d-flex align-items-center">
        {version !== 3 && (icon || iconname) && (
          <IconContainer style={{ marginRight: "13px" }}>
            {icon && <FontAwesomeIcon icon={icon} />}
            {iconname && <NIcon iconname={iconname} />}
          </IconContainer>
        )}
        <div
          className={flexTitle ? "d-flex align-items-center" : ""}
          style={selectMenu === eventKey ? { color: "#334FFF" } : {}}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className="mr-auto"></div>
        <div>{children}</div>
        <div>
          {selectMenu === eventKey ? (
            <>
              {childIcon ? (
                childIcon
              ) : (
                <FontAwesomeIcon
                  icon={faAngleUp}
                  style={{ fontSize: "16px" }}
                />
              )}
            </>
          ) : (
            <>
              {childIcon ? (
                childIcon
              ) : (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  style={{ fontSize: "16px" }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Accordion.Toggle>
  );
};
export default ToggleHeader;
