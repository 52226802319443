import React from "react";
import { getGlobalConfig, uuidv4 } from "utils";
import { useSelector, useDispatch } from "react-redux";
import TaskHistory from "containers/Task/v2/TaskHistory";
import { CardNimbi } from "components/Nimbiv2/styled";
import { fetchPersonHistory } from "store/actions/async/crm-async.action";
import { ContainerEmpty, NSpinnerPoints } from "components/Nimbiv2";
import EmptyListSVG from "assets/images/EmptyListSVG";
import HistoryFilter from "./HistoryFilters";

const HistoryTask = ({ title }) => {
  const {
    personHistory,
    personsProfile: person,
    pending: { personhistory: isPendingPersonHistory },
  } = useSelector((state) => state.crm);

  const dispatch = useDispatch();

  const version3 = getGlobalConfig("enable_student_version_3", false);

  const fetchTasks = async (filters) => {
    const queryFilters = {};
    Object.keys(filters)
      .filter((el) => filters[el] !== "0")
      .forEach((el) => {
        queryFilters[el] = filters[el];
      });
    return dispatch(fetchPersonHistory(person.id, queryFilters));
  };

  return (
    <div>
      <div className={version3 ? "d-flex justify-content-between" : ""}>
        {title && (
          <div style={{ fontWeight: "600", fontSize: "20px" }}>{title}</div>
        )}
      </div>

      <div style={{ marginTop: "20px" }}>
        <HistoryFilter
          applyFilter={(newFilter) => {
            fetchTasks(newFilter);
          }}
        />
      </div>
      <CardNimbi style={version3 ? { height: "auto" } : {}}>
        {isPendingPersonHistory && (
          <div>
            <NSpinnerPoints />
          </div>
        )}
        {personHistory && personHistory.length > 0 && person && (
          <div className="overflow-auto">
            {!isPendingPersonHistory &&
              personHistory &&
              personHistory.map((m) => (
                <TaskHistory
                  key={uuidv4(8)}
                  student={{
                    full_name: `${person.first_name} ${person.last_name}`,
                  }}
                  task={m}
                />
              ))}
          </div>
        )}
      </CardNimbi>
      {personHistory && personHistory.length === 0 && (
        <CardNimbi padding="24px">
          <div className="mb-4">
            <ContainerEmpty
              version="2"
              image={<EmptyListSVG />}
              text={"Aún no existe historial de este alumno"}
              description={
                <div>
                  Al momento de empezar a gestionar el alumno, podrás ver todos{" "}
                  <br />
                  los movimientos en esta sección
                </div>
              }
            />
          </div>
        </CardNimbi>
      )}
    </div>
  );
};
export default HistoryTask;
