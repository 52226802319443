import React, { useState, useEffect, useCallback, useMemo } from "react";
import _ from "lodash";
import styled from "styled-components";
import PropTypes from "prop-types";
import FormFilter from "../../NFormFilter";
import NPriorityFilter from "./NPriorityFilter";
import NRiskOrder from "./NRiskOrder";
import NFilterBadge from "./NFilterBadge";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container, Collapse, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsFilter, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { NSearchStudents } from "components";
import {
  loadClientFilters,
  updateClientFilters,
} from "store/actions/site.actions";
import { APP_MODULES, getGlobalConfig, storeClientAllFilters } from "utils";
import { SEARCH } from "./constants";
import NSpinnerPoints from "../Spinners/NSpinnerPoints";
import { fetchClientFilters } from "store/actions/async/site-async.action";
import { NButton } from "..";

const {
  TASK: { FILTER_NAME: FILTER_TASK },
} = APP_MODULES;

const NDynamicFilters = ({
  panel,
  onGetSingleStudent,
  onGetSuggestStudents,
  onLazySecondList,
  isPendingList = false,
  showPriorityFilter = false,
  filterNameLS = FILTER_TASK,
  children,
  changeTab,
  showRiskOrder = false,
  defaultSearch,
  dynamicFilter = false,
  idMomentFilter,
  countItems = 0,
  textCount,
  isPending = false,
  external = false,
  emptyLabel,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    clientFilters,
    pending: { clientFilters: isPendingFilter },
    errors: { clientFilters: isErrorFilters },
  } = useSelector((state) => state.site);
  const [tempFilters, setTempFilters] = useState({});
  const [expandFilters, setExpandFilters] = useState(false);

  const filtersData = useMemo(() => clientFilters.data[panel] || [], [
    clientFilters.data,
    panel,
  ]);
  const hasAssociatedFilters = useMemo(() => {
    return (
      filtersData.filter((item) =>
        item.moments ? item.moments.length > 0 : false
      ).length > 0
    );
  }, [filtersData]);

  const dataFilter = useMemo(() => {
    return dynamicFilter && hasAssociatedFilters
      ? idMomentFilter
        ? filtersData.filter(
            (item) =>
              item.moments.includes(idMomentFilter) || item.moments.length === 0
          )
        : []
      : filtersData;
  }, [dynamicFilter, idMomentFilter, filtersData, hasAssociatedFilters]);

  // const filtersCount = useMemo(() => {
  //   const currentFilters = Object.keys({ ...clientFilters.filters[panel] });
  //   let count = 0;
  //   count = currentFilters.length;
  //   return count;
  // }, [clientFilters.filters, panel]);

  const getTypeData = useCallback(
    (filter) => {
      const studentData = clientFilters.data[panel];
      const data = studentData?.filter((item) => item.filter_field === filter);
      if (data?.length > 0) {
        return data[0]["type_component"];
      }
      return null;
    },
    [clientFilters, panel]
  );

  const filtersApplied = useMemo(() => {
    const currentFilters = Object.keys({ ...clientFilters.filters[panel] });

    const list_filter = [];
    const allItems = {
      1: "SI",
      0: "NO",
      True: "SI",
      False: "NO",
      undefined: "NO",
      "1##": "SI",
      "0##": "NO",
    };
    currentFilters.forEach((filter) => {
      const item = filtersData.find(
        (element) => element.filter_field === filter
      );

      // eslint-disable-next-line prefer-destructuring
      let value = clientFilters.filters[panel][filter];
      if (getTypeData(filter) === "boolean") {
        value = _.get(
          allItems,
          clientFilters.filters[panel][filter],
          clientFilters.filters[panel][filter]
        );
      }
      if (item) {
        let res = { key: item["label"], value: value, filter: filter };
        list_filter.push(res);
      }
    });

    return list_filter;
  }, [clientFilters.filters, panel, filtersData, getTypeData]);

  const updateSessionFilters = useCallback(() => {
    const isEmpty = Object.keys(tempFilters).length === 0;
    if (isEmpty) return false;

    dispatch(updateClientFilters(tempFilters));
    storeClientAllFilters(
      tempFilters.filters[panel],
      filterNameLS,
      tempFilters.data[panel]
    );
    onLazySecondList();
  }, [dispatch, tempFilters, panel, filterNameLS, onLazySecondList]);

  const handleFilterChanged = useCallback(
    (content) => {
      if (_.isEqual(clientFilters.filters[panel], content) === false) {
        setTempFilters({
          data: clientFilters.data,
          filters: { ...clientFilters.filters, ...{ [panel]: content } },
        });
      }
    },
    [clientFilters.data, clientFilters.filters, panel]
  );

  const renderFilterGroup = useCallback(() => {
    if (filtersData.length === 0 || !panel) return null;
    const hasFilters = Object.keys(clientFilters.filters || {}).length > 0;
    const filtersetData = hasFilters ? clientFilters.filters[panel] : {};
    return (
      <>
        {/* <Row className="mt-3">
          <Col>
            <div className={taskStyles.filterTitle}>Filtrar por:</div>
          </Col>
        </Row> */}
        <Row>
          <Col className="p-0">
            <Container style={{ padding: "0px" }} fluid>
              <FormFilter
                transparent={false}
                data={dataFilter}
                onChange={handleFilterChanged}
                filterset={filtersetData ?? {}}
                isDisabled={isPendingList}
              />
            </Container>
          </Col>
        </Row>
      </>
    );
  }, [
    panel,
    filtersData,
    clientFilters.filters,
    isPendingList,
    handleFilterChanged,
    dataFilter,
  ]);

  useEffect(() => {
    dispatch(loadClientFilters());
  }, [dispatch]);

  useEffect(() => {
    // Apply filters
    updateSessionFilters();
  }, [tempFilters, updateSessionFilters]);

  const deletefilter = (filter) => {
    let filters = clientFilters.filters[panel];
    delete filters[filter["filter"]];
    setTempFilters({
      data: clientFilters.data,
      filters: { ...clientFilters.filters, ...{ [panel]: filters } },
    });
  };

  const renderFilterBadges = () => {
    if (Object.keys(filtersApplied).length === 0) return null;
    return filtersApplied.map((element) => {
      //const [key, value] = element;
      return (
        <NFilterBadge
          key={element["key"]}
          filterInfo={element}
          onClickDelete={deletefilter}
        />
      );
    });
  };

  return (
    <div>
      <div
        style={{ padding: "0px", marginBottom: "16px", rowGap: "0.5rem" }}
        className="mt-2 d-flex flex-wrap justify-concent-center align-items-center"
      >
        <Col lg="6" md="12" className="p-0 mr-auto">
          <InputDesign style={{ padding: "7px 12px", marginRight: "10px" }}>
            <NSearchStudents
              filterKey={SEARCH.TASK.FILTER_KEY}
              fieldName={SEARCH.TASK.FIELD_NAME}
              onChange={onGetSingleStudent}
              asyncSearch={onGetSuggestStudents}
              isDisabled={isPendingList}
              defaultSearch={defaultSearch}
              external={external}
              emptyLabel={emptyLabel}
            />
            <FontAwesomeIcon
              icon={faSearch}
              style={{ height: "27px", marginLeft: "10px", color: "#334FFF" }}
            />
          </InputDesign>
          <div></div>
        </Col>

        {/* <Col md="12" lg="6" className="p-0 mt-2 mt-lg-0 pl-0 pl-lg-2"> */}
        {showPriorityFilter && panel === APP_MODULES.TASK.ID ? (
          <NPriorityFilter
            isDisabled={isPendingList}
            changeTab={changeTab}
            changeMoment={idMomentFilter}
          />
        ) : null}
        {panel === APP_MODULES.STUDENT.ID && (
          <NRiskOrder
            isDisabled={isPendingList}
            changeTab={changeTab}
            riskFilter={
              showRiskOrder &&
              !getGlobalConfig("hide_nimbi_risk", false) &&
              !getGlobalConfig("hide_nimbi_filter_risk", false)
            }
          />
        )}
        {children}
        {/* </Col> */}
      </div>

      <ContainerFilters>
        <div className="d-flex justify-content-between">
          <div>
            <strong className="title">
              {isPending ? <NSpinnerPoints size="18px" /> : countItems ?? 0}
            </strong>{" "}
            {textCount ?? t("pending_tasks")}
          </div>
          <div className="d-flex">
            <div
              className="d-flex justify-content-end align-items-center"
              style={{ marginRight: "12px" }}
            >
              {renderFilterBadges()}
            </div>
            {isPendingFilter && !isErrorFilters && (
              <NSpinnerPoints size="20px" />
            )}
            {!isPendingFilter && !isErrorFilters && (
              <div
                className="title-filter"
                onClick={() => {
                  if (!isPendingList) setExpandFilters((sw) => !sw);
                }}
              >
                {expandFilters
                  ? _.upperFirst(t("hide"))
                  : _.upperFirst(t("filters"))}
                <FontAwesomeIcon
                  icon={faBarsFilter}
                  style={{ height: "14px", marginLeft: "5px" }}
                />
              </div>
            )}
            {!isPendingFilter && isErrorFilters && (
              <div>
                <NButton
                  transparent={true}
                  style={{ padding: "0px 15px" }}
                  onClick={() => {
                    dispatch(fetchClientFilters({ module: panel }));
                  }}
                >
                  {t("reload_filters")}
                </NButton>
              </div>
            )}
          </div>
        </div>
      </ContainerFilters>

      <Collapse in={expandFilters} style={{ width: "100%", marginTop: "4px" }}>
        <ContainerFilters>
          <Container fluid className="mt-2">
            {isPendingFilter && !isErrorFilters && <NSpinnerPoints />}
            {!isPendingFilter && !isErrorFilters && <>{renderFilterGroup()}</>}
            {!isPendingFilter && isErrorFilters && (
              <div>
                <NButton
                  onClick={() => {
                    dispatch(fetchClientFilters({ module: panel }));
                  }}
                >
                  {t("reload_filters")}
                </NButton>
              </div>
            )}
          </Container>
        </ContainerFilters>
      </Collapse>
    </div>
  );
};

export default NDynamicFilters;
NDynamicFilters.propTypes = {
  onGetSingleStudent: PropTypes.func.isRequired,
  onGetSuggestStudents: PropTypes.func.isRequired,
  onLazySecondList: PropTypes.func.isRequired,
  panel: PropTypes.oneOf(["tasks", "students", "users"]),
  isPendingList: PropTypes.bool,
  showPriorityFilter: PropTypes.bool,
};

const InputDesign = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  // box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 10px 12px;
  .form-control {
    border: 0px solid #ced4da !important;
    padding: 0px;
  }
  .rbt {
    width: 100%;
  }
`;

const ContainerFilters = styled(Card)`
  border: 0px;
  background: #fafcfe;
  padding: 15px 20px;
  border-radius: 8px;
  .title {
    color: #334fff;
    font-size: 16px;
  }
  .title-filter {
    font-size: 14px;
    color: #334fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
