import React, { useState } from "react";
import _ from "lodash";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CardNimbi, IconContainer } from "components/Nimbiv2/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icons from "components/Nimbiv2/icons";
import {
  faEllipsisVertical,
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import { CardUserPosition } from "components/Nimbiv2";

import { getTopCallersList } from "store/actions/async/dashboard-async.actions";
import { t } from "i18next";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ paddingLeft: "10px", paddingRight: "10px", cursor: "pointer" }}
  >
    {children}
  </div>
));

const CardRanking = ({ data = [] }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("today");

  const filterDate = (date_filter) => {
    dispatch(getTopCallersList(date_filter, "topCallersLoad"));
  };
  const {
    pending: { topCallersLoad: topCallersLoadPending },
  } = useSelector((state) => state.dashboard);

  const cardEmpty = {
    first_name: "",
    last_name: "",
    total_calls: null,
    image: null,
  };

  return (
    <CardNimbi padding="20px 19px">
      <div className="d-flex h-100">
        <div
          className="d-flex align-items-center"
          style={{ marginBottom: "12px", width: "100%" }}
        >
          <div>
            <IconContainer>
              <FontAwesomeIcon
                icon={_.get(Icons, "trophy", faExclamationTriangle)}
                size="lg"
              />
            </IconContainer>
          </div>
          <div
            className="ml-3 d-flex justify-content-center align-items-center fontNova mr-auto"
            style={{ lineHeight: "120%" }}
          >
            <strong>{t("top_telephone_assistants")}</strong>
          </div>
          <div>
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-custom-components"
                as={CustomToggle}
              >
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  size="2x"
                  style={{ color: "#7C8A99" }}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey="1"
                  active={filter === "today"}
                  onClick={() => {
                    filterDate("today");
                    setFilter("today");
                  }}
                >
                  Hoy
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  active={filter === "last_week"}
                  onClick={() => {
                    filterDate("last_week");
                    setFilter("last_week");
                  }}
                >
                  Últimos 7 dias
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="4"
                  active={filter === "last_month"}
                  onClick={() => {
                    filterDate("last_month");
                    setFilter("last_month");
                  }}
                >
                  Últimos 30 dias
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div>
        <CardUserPosition
          position="1"
          data={data.length > 0 ? data[0] : cardEmpty}
          loading={topCallersLoadPending}
        />
        <CardUserPosition
          position="2"
          data={data.length > 1 ? data[1] : cardEmpty}
          background="#DBE3FF"
          titleColor="#2A3B53"
          descriptionColor="#71819C"
          loading={topCallersLoadPending}
        />
        <CardUserPosition
          position="3"
          data={data.length > 2 ? data[2] : cardEmpty}
          background="#ECF2FF"
          titleColor="#2A3B53"
          descriptionColor="#71819C"
          loading={topCallersLoadPending}
        />
      </div>
    </CardNimbi>
  );
};
export default CardRanking;
