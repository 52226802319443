import React from "react";
import _ from "lodash";
import { Row, Col, Form, Spinner } from "components";
import { Formik } from "formik";
import { useSession, useTranslationLocal } from "hooks";
import { getFileFromInput } from "utils";
import { useTranslation } from "react-i18next";
import { NButton, NInput, NTextArea } from "components/Nimbiv2";
import styled from "styled-components";
import { toast } from "react-toastify";

const ReportForm = ({ onSubmit: onFormSubmit }) => {
  const { user } = useSession();
  const { t } = useTranslation();
  const hiddenFileInput = React.useRef(null);
  const { translation } = useTranslationLocal();
  const formValues = {
    section: "",
    description: "",
    screenshot: null,
    sender: user.id,
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const validateRules = (values) => {
    const errors = {};

    if (!values.section) {
      errors.section = "Sección es requerido";
    } else if (!values.description) {
      errors.description = "Descripción es requerido";
    }

    return errors;
  };

  const showError = (fieldName, errors, touched) => {
    const hasError = errors[fieldName];
    const isTouched = touched[fieldName] || false;

    if (hasError && isTouched) {
      return <Form.Text className="text-danger">{errors[fieldName]}</Form.Text>;
    }

    return null;
  };

  const loadScreenshot = (event, setter) => {
    if (event.currentTarget.files.length > 0) {
      const fileUploaded = event.currentTarget.files[0];
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

      if (!allowedExtensions.exec(fileUploaded.name)) {
        toast.error("Lo siento, este tipo de archivo no está permitido");
        return false;
      }
      const file = getFileFromInput(event, 5000);
      if (file) {
        setter("screenshot", file);
        return false;
      } else {
        event.target.value = null;
        setter("screenshot", null);
      }
      // eslint-disable-next-line no-alert
      alert("El tamaño de la imagen excede el limite permitido 5MB");
    } else {
      event.target.value = null;
      setter("screenshot", null);
    }
  };

  return (
    <Formik
      initialValues={formValues}
      validate={validateRules}
      onSubmit={onFormSubmit}
    >
      {({
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
      }) => (
        <Row>
          <Col style={{ padding: "0px" }}>
            <Form onSubmit={handleSubmit}>
              <div
                style={{
                  marginBottom: "32px",
                  color: "#3B4567",
                  fontSize: "16px",
                }}
              >
                {t("fill_out_the_form")}
              </div>
              <fieldset disabled={isSubmitting}>
                <Form.Group controlId="formSection">
                  <NInput
                    name="section"
                    type="text"
                    maxLength={60}
                    onChange={(value) => setFieldValue("section", value)}
                    disabled={isSubmitting}
                    autoComplete="off"
                    label={_.upperFirst(translation("reason_for_contact"))}
                  />
                  {showError("section", errors, touched)}
                </Form.Group>
                <Form.Group controlId="formDescription">
                  <NTextArea
                    name="description"
                    as="textarea"
                    rows={3}
                    onChange={(value) => setFieldValue("description", value)}
                    autoComplete="off"
                    label={_.upperFirst(t("description"))}
                  />
                  {showError("description", errors, touched)}
                </Form.Group>
                <Form.Group>
                  <div style={{ color: "#787C83", marginBottom: "12px" }}>
                    {t("attach_related_screenshot")}
                  </div>
                  <CustomButtonFile>
                    <NButton
                      outlined
                      style={{ marginRight: "12px" }}
                      onClick={handleClick}
                    >
                      Seleccionar archivo
                    </NButton>
                    <div className="text-select">
                      {values?.screenshot
                        ? values?.screenshot?.name
                        : "Ningún archivo seleccionado"}
                    </div>
                  </CustomButtonFile>
                  <input
                    type="file"
                    name="screenshot"
                    id="formScreenshot"
                    label={translation("attach_screenshot_x")}
                    onChange={(event) => loadScreenshot(event, setFieldValue)}
                    style={{ display: "none" }}
                    accept=".png,.jpg,.jpeg,image/png,.gif"
                    ref={hiddenFileInput}
                  />
                </Form.Group>

                <NButton
                  type="submit"
                  disabled={isSubmitting}
                  style={{ width: "100%", marginTop: "56px" }}
                  colorbutton="#1E22CE"
                  hoverbuttom="#1E22CE"
                >
                  {isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Enviar"
                  )}
                </NButton>
              </fieldset>
            </Form>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default ReportForm;

const CustomButtonFile = styled(Form.File)`
  display: flex;
  align-items: center;
  .text-select {
    color: #787c83;
    max-width: 220px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
