import React, { useMemo } from "react";

import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TitleCard } from "containers/Task/detail/detail.styled";
import ChartArea from "../ChartArea";
import { CardNimbi, IconContainer, RowItem } from "../styled";
import styled from "styled-components";
import NIcon from "../NIcon";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import colors from "utils/risk";
import { useTranslation } from "react-i18next";
import { applyTextType, getGlobalConfig } from "utils";
import { IconRisk } from "assets/images";
import { colorsBadge } from "utils/constants";
import { useTranslationLocal } from "hooks";

const CardIndicatorStudent = ({ item, labelModal, border }) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();

  const isEmpty = useMemo(() => {
    return !(item && item.data && item.data.length > 0);
  }, [item]);

  const colorRisk = useMemo(() => {
    return item?.category;
  }, [item]);

  const colorsText = {
    Alto: colorsBadge.dark.danger.background,
    Medio: colorsBadge.dark.warning.background,
    Bajo: colorsBadge.dark.success.background,
  };

  return (
    <CardNimbi border={border ? "1px solid #DBE3FF" : ""}>
      <RowItem>
        <div className="d-flex w-100">
          <IconContainer size="37px">
            {item?.icon ? (
              <NIcon iconname={item?.icon} />
            ) : (
              <NIcon iconname="flag" />
            )}
          </IconContainer>
          <div
            style={{ marginTop: "10px", marginLeft: "11px" }}
            className="w-100"
          >
            <div className="d-flex align-items-center">
              <TitleCard style={{ color: "#2A2B2E" }}>
                {getGlobalConfig("hide_nimbi_risk", false) &&
                item?.title === "Riesgo"
                  ? _.upperFirst(t("no_information"))
                  : _.upperFirst(translation(item?.title))}
              </TitleCard>
              {item?.type === "graphic" && (
                <div className="ml-auto" style={{ color: "#787C83" }}>
                  ({isEmpty ? "0d" : `${item?.days_difference}d`})
                </div>
              )}
            </div>

            {!isEmpty && item?.type === "graphic" && (
              <CardNumber isRisk={colorRisk}>
                {applyTextType(
                  String(item.last_value),
                  item.type_value,
                  null,
                  null,
                  ""
                )}
              </CardNumber>
            )}
          </div>
        </div>
        {isEmpty && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img src={IconRisk} alt="empty" />
          </div>
        )}

        {!isEmpty && item?.type === "graphic" && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ChartArea color="blue" data={item.data} labelModal={labelModal} />
          </div>
        )}
        {!isEmpty && item?.type === "text" && (
          <div
            style={{
              color: _.get(
                colorsText,
                item?.data,
                colorsBadge.dark.information.background
              ),
              width: "100%",
              display: "flex",
              justifyContent: "center",
              height: "100px",
              alignItems: "center",
              fontSize: "32px",
            }}
          >
            {item?.data}
          </div>
        )}
        {!isEmpty && item?.type === "list_custom" && (
          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {item?.data.map((itemData) => (
              <>
                <div
                  style={{
                    fontSize:
                      item?.data.length > 4
                        ? "14px"
                        : "var(--font-size-caption);",
                  }}
                >
                  {itemData?.name}
                </div>
              </>
            ))}
          </div>
        )}
        {isEmpty && (
          <div style={{ marginTop: "16px" }}>
            <FontAwesomeIcon
              icon={faCircleMinus}
              className="mr-1"
              style={{ color: "#334FFF" }}
            ></FontAwesomeIcon>
            {_.upperFirst(t("no_information"))}
          </div>
        )}
      </RowItem>
    </CardNimbi>
  );
};

export default CardIndicatorStudent;

const CardNumber = styled.div`
  color: ${(props) =>
    props.isRisk ? colors[props.isRisk]["bold"] : "#334FFF"};
  font-size: 20px;
  line-height: 120%;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 2px;
`;
CardNumber.defaultProps = {
  type: "normal",
};
