import React from "react";
import {
  StepTitle,
  TextTitle,
  TextQuestion,
  ImgPosition,
} from "components/Nimbiv2/InitiativesComponent";
import { DividerH, CardNimbi } from "components/Nimbiv2/styled";
import { Col, Row } from "react-bootstrap";
import { ImageInitiatives1 } from "assets/images";
import ListOptionClip from "components/Nimbiv2/ListOptionClip";
import { NIcon, NButton } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";
import { ButtonSize } from "utils/constants";

const PageFirst = ({ onChange, onChangeForm, formValues, errors }) => {
  const { t } = useTranslation();

  const OptionsCreate = [
    {
      icon: "sparkles",
      title: t("new_initiative"),
      value: "new",
    },
    {
      icon: "copy",
      title: t("campaign_designed"),
      value: "copy",
    },
  ];

  return (
    <div style={{ maxWidth: "922px", margin: "auto" }}>
      <CardNimbi padding={"33px 35px 33px 35px"} style={{ minHeight: "380px" }}>
        <Row>
          <ImgPosition src={ImageInitiatives1} />
          <Col xs={9}>
            <StepTitle>{t("step_number", { number: "1" })}</StepTitle>
            <div style={{ marginTop: "18px" }}>
              <TextTitle>{t("select_start_creating_campaign")}</TextTitle>
            </div>
            <div style={{ marginTop: "40px", marginBottom: "40px" }}>
              <DividerH></DividerH>
            </div>
            <div style={{ marginTop: "57px" }}>
              <TextQuestion>
                1. {t("would_designed_campaign_scratch")}
              </TextQuestion>
              <div style={{ marginTop: "16px" }}>
                <ListOptionClip
                  listOptions={OptionsCreate}
                  selected={formValues.optionCreate}
                  onChange={(value) => {
                    onChangeForm(
                      "optionCreate",
                      value,
                      {
                        optionCreate: value,
                      },
                      false,
                      true,
                      false
                    );
                  }}
                  error="*Selecciona una opción de campaña"
                  showError={errors.hasOwnProperty("optionCreate")}
                />
              </div>
            </div>
          </Col>
        </Row>
      </CardNimbi>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <NButton
          sizeStyle={ButtonSize.lg}
          style={{
            marginTop: "24px",
            borderRadius: "24px",
            textTransform: "capitalize",
          }}
          onClick={() => {
            onChange("next");
          }}
          icon={<NIcon iconname="arrowRight" />}
          positionIcon={"right"}
          color="second"
        >
          {t("continue")}
        </NButton>
      </div>
    </div>
  );
};

export default PageFirst;
