import React from "react";
import styled from "styled-components";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faEllipsisV,
  faEnvelope,
  faCalendarAlt,
  faPhone,
  faMessageLines
} from "@fortawesome/pro-regular-svg-icons";

import { backgroundMoment, initiativeExternalBanner } from "assets/images";
import { AnonymousContainer } from "components/Nimbiv2/styled";
import {
  CardContainer,
  CardHeader,
  CardBody,
  CardTitle,
  RowDetail,
  CardDescription,
} from "../CardGeneral";
import { Form, NBadge } from "components";
import { NButton } from "components/Nimbiv2";
import { IconCheck } from "assets/images";
import { boolValues, ButtonSize, colorsBadge } from "utils/constants";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useTranslationLocal } from "hooks";
import { get_values_range_number } from "utils/initiatives";

// const InitiativeStatusColor = {
//   active: "#3AC8AC",
//   paused: "#E23232",
//   completed: "#989DA5",
//   draft: "#F6D42D",
//   canceled: "#E23232",
//   programmed: "#989DA5",
// };
const InitiativeStatusColor = {
  active: colorsBadge.light.success,
  paused: colorsBadge.light.danger,
  completed: colorsBadge.light.inactive,
  draft: colorsBadge.light.warning,
  canceled: colorsBadge.light.danger,
  programmed: colorsBadge.light.inactive,
};

const icons = {
  Call: faPhone,
  Email: faEnvelope,
  WhatsApp: faWhatsapp,
  LMS: faMessageLines
};

const CardInitiative = ({
  item,
  onChange,
  showCriteria = false,
  showActive = true,
  selected = false,
  copy = false,
  onClickDetail,
  onClickTask = () => {},
  isAnonymous,
}) => {
  const { translation } = useTranslationLocal();
  const { t } = useTranslation();
  const changeSelectedItem = () => {
    if (copy) {
      onChange(item);
    }
  };

  const textTitle = {
    Email: { icon: faEnvelope, title: t("emails") },
    WhatsApp: { icon: faWhatsapp, title: t("messages") },
    LMS: { icon: faMessageLines, title: t("lms") },
  };

  return (
    <CardContainerDesign
      style={
        copy
          ? { height: "100%", border: "1px solid rgba(0, 0, 0, 0.125)" }
          : { height: "100%" }
      }
      selected={selected}
    >
      <CardHeader
        img={
          item?.source_type === "external"
            ? initiativeExternalBanner
            : backgroundMoment
        }
        height="62px"
        onClick={changeSelectedItem}
      >
        {item?.source_type === "external" && (
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              background: "rgba(0,0,0,0.5)",
            }}
          ></div>
        )}
        {copy && (
          <CheckSelected selected={selected} onClick={changeSelectedItem}>
            <InputCheck
              type={"checkbox"}
              checked={selected}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-2px",
              }}
              onChange={() => {}}
            />
          </CheckSelected>
        )}
        {showActive && (
          <div className="card-options d-flex justify-content-center align-items-center">
            <div className="mr-auto">
              <NBadge
                text={_.upperFirst(t(item.status))}
                customStyle={{
                  borderRadius: "64px",
                }}
                newColor={InitiativeStatusColor[item.status]}
              />
            </div>
            {1 === 0 && (
              <FontAwesomeIcon icon={faEllipsisV} size="1x" color="white" />
            )}
            <div>
              <NBadge
                text={_.upperFirst(t(item.source_type))}
                customStyle={{
                  borderRadius: "64px",
                }}
                //newColor={InitiativeStatusColor[item.status]}
                backgroundColor={"#ECF2FF"}
                fontColor={
                  item?.source_type === "external" ? "#2A3B53" : "#334FFF"
                }
              />
            </div>
          </div>
        )}
        <div className="image-circle">
          <FontAwesomeIcon
            icon={_.get(icons, item.initiative_type.name, "Email")}
          />
        </div>
      </CardHeader>
      <CardBody>
        <CardTitle className="text-center" onClick={changeSelectedItem}>
          <AnonymousContainer isAnonymous={isAnonymous}>
            {item.name && item.name.length > 43
              ? item.name.slice(0, 40) + "..."
              : item.name}
          </AnonymousContainer>
        </CardTitle>
        <CardDescription onClick={changeSelectedItem}>
          {item.objective && item.objective.length > 71
            ? item.objective.slice(0, 68) + "..."
            : item.objective}
        </CardDescription>
        <div className="mt-auto">
          {!showCriteria && (
            <>
              <RowDetail style={{ marginTop: "16px", marginBotton: "9px" }}>
                <FontAwesomeIcon
                  icon={faGraduationCap}
                  style={{ marginRight: "6px", width: "18px" }}
                />
                {translation("students", "startCase", t("students"))}:{" "}
                <strong className="colorTextSecondary">
                  {item.students_amount ? item.students_amount : 0}
                </strong>
              </RowDetail>
              <RowDetail>
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  style={{ marginRight: "6px", width: "18px" }}
                />
                {_.upperFirst(t(`titleTime.${item.initiative_type.name}`)) ??
                  _.upperFirst(t("titleTime.date"))}
                :{" "}
                <span>
                  {item.initiative_type.name === "Call"
                    ? `${moment(item.start_date).format(
                        "DD/MM/YYYY"
                      )} - ${moment(item.end_date).format("DD/MM/YYYY")}`
                    : moment(item.start_date).format("DD/MM/YYYY")}
                </span>
              </RowDetail>

              {item.initiative_type.name === "Call" && (
                <RowDetail>
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ marginRight: "6px", width: "18px" }}
                  />
                  {_.upperFirst(translation("called", "", t("called")))}:{" "}
                  <strong className="colorTextSecondary">
                    {item.associated_tasks.completed}
                  </strong>
                  /{item.associated_tasks.all}{" "}
                  <strong>{item.associated_tasks.percentage}%</strong>
                </RowDetail>
              )}
              {["Email", "WhatsApp", "LMS"].includes(item.initiative_type.name) && (
                <RowDetail>
                  <FontAwesomeIcon
                    icon={textTitle[item.initiative_type.name].icon}
                    style={{ marginRight: "6px", width: "18px" }}
                  />
                  {_.upperFirst(textTitle[item.initiative_type.name].title)}:{" "}
                  <strong className="colorTextSecondary">
                    {item.associated_tasks.all}
                  </strong>
                </RowDetail>
              )}
            </>
          )}
          {showCriteria && (
            <>
              <div
                style={{ fontSize: "var(--font-size-md)", marginTop: "20px" }}
                onClick={changeSelectedItem}
              >
                <strong>Criterios:</strong>
              </div>
              {item.client_filters && (
                <div className="font-size-caption" style={{ marginTop: "8px" }}>
                  {item.client_filters.map((itemClientFilter, key) => {
                    return (
                      <div
                        style={{ marginTop: "8px" }}
                        key={`client-filter-${key}`}
                      >
                        <img
                          src={IconCheck}
                          style={{ height: "15px", marginRight: "9px" }}
                          alt="image1"
                        />
                        {itemClientFilter.name}:{" "}
                        {itemClientFilter.type_component === "select" &&
                          item.clientfilter_values[
                            itemClientFilter.field
                          ].toString()}
                        {itemClientFilter.type_component === "boolean" &&
                          _.get(
                            boolValues,
                            item.clientfilter_values[itemClientFilter.field],
                            ""
                          )}
                        {itemClientFilter.type_component === "date" &&
                          item.clientfilter_values[
                            itemClientFilter.field
                          ].replace("++", " | ")}
                        {itemClientFilter.type_component === "range_number" && 
                          _.upperFirst(t("range_number_values", get_values_range_number(item.clientfilter_values[
                            itemClientFilter.field
                          ])))
                        }
                      </div>
                    );
                  })}
                  {item.client_filters.length === 0 && (
                    <div style={{ marginTop: "8px" }}>No tiene criterios</div>
                  )}
                </div>
              )}
              {!item.client_filters && (
                <div style={{ marginTop: "8px" }}>No tiene criterios</div>
              )}
            </>
          )}

          <div>
            {item.status === "active" &&
              item.initiative_type.name === "Call" &&
              !showCriteria && (
                <NButton
                  sizeStyle={ButtonSize.sm}
                  onClick={onClickTask}
                  style={{ width: "100%", marginTop: "20px" }}
                  outlined
                  color="second"
                >
                  {t("Go to tasks")}
                </NButton>
              )}
            <NButton
              sizeStyle={ButtonSize.sm}
              style={{ marginTop: "10px", width: "100%" }}
              onClick={onClickDetail}
              transparent
              color="second"
            >
              {t("See detail")}
            </NButton>
          </div>
        </div>
      </CardBody>
    </CardContainerDesign>
  );
};
export default CardInitiative;

const CardContainerDesign = styled(CardContainer)`
  ${(props) => (props.selected ? "border: 3px solid #334fff;" : "")}
`;

const CheckSelected = styled.div`
  background: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 8px;
  ${(props) =>
    props.selected
      ? `border-bottom: 3px solid #334fff;
  border-left: 3px solid #334fff;`
      : ""}

  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 3px 10px rgb(0 0 0 / 40%);
`;

const InputCheck = styled(Form.Check)`
  input {
    height: 15px;
    width: 15px;
  }
`;
