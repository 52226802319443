import React from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import icons from "./icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

const NIcon = (props) => {
  return (
    <FontAwesomeIcon icon={_.get(icons, props.iconname, faPlus)} {...props} />
  );
};

export default NIcon;
