import React, { useCallback, useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import moment from "moment";
import { Col } from "react-bootstrap";
import styles from "components/NCallingInfo/NCallingInfo.module.css";

import { AnonymousContainer } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
const RenderComment = ({ task }) => {
  const { t } = useTranslation();
  const student = useMemo(() => {
    const { student_tracking: studentInfo = null } = task;
    return studentInfo;
  }, [task]);
  const { user } = useSelector((state) => state.auth);

  const renderLastComments = useCallback(() => {
    const { last_contact: lastContact = null } = task;
    if (!lastContact) return null;

    return (
      <div>
        <Col className={styles.commentsBlock}>
          <div className="d-flex">
            <div>
              <p style={{ fontSize: 14 }} className="font-weight-bold">
                <u>{t("last_contact")}:</u>
              </p>
            </div>
            <div className="ml-2">
              <div style={{ fontSize: 14, marginBottom: "0px" }}>
                {!lastContact.call_comment && !lastContact.comments ? (
                  <>
                    {lastContact.call_response && (
                      <div>{lastContact.call_response}</div>
                    )}
                    <div>{lastContact.contact_reason}</div>
                  </>
                ) : (
                  <>
                    {lastContact.call_response && (
                      <div className="d-flex">
                        <div style={{ fontWeight: "500" }}>
                          {_.upperFirst(t("response"))}:
                        </div>
                        <div className="ml-1">
                          {lastContact.call_response}
                          {lastContact.call_comment && (
                            <div>{lastContact.call_comment}</div>
                          )}
                        </div>
                      </div>
                    )}

                    {lastContact.comments && (
                      <div>
                        <span style={{ fontWeight: "500" }}>
                          {_.upperFirst(t("observations"))}:
                        </span>{" "}
                        {lastContact.comments}
                      </div>
                    )}
                  </>
                )}

                {/* {!lastContact.call_comment && !lastContact.comments
                  ? lastContact.contact_reason
                  : `${
                      lastContact.call_comment
                        ? lastContact.call_comment + " "
                        : ""
                    }${lastContact.comments ?? ""}`} */}
              </div>

              <div>
                <small className="secondary_color font-weight-bold">
                  <AnonymousContainer isAnonymous={user?.anonymous}>
                    {lastContact.user_name}{" "}
                  </AnonymousContainer>
                  {lastContact.closed_at
                    ? `${moment(lastContact.closed_at).format(
                        "DD/MM/YYYY - HH:mm"
                      )}h`
                    : null}
                </small>
              </div>
            </div>
          </div>
        </Col>
      </div>
    );
  }, [task, user.anonymous, t]);
  const renderLastNote = useCallback(() => {
    const { recentNote: note = null } = student;
    if (!note) return null;

    const fullName = `${note.user.first_name ?? ""} ${
      note.user.last_name ?? ""
    }`;
    const createdAt = moment(note.created_at).format("DD-MM-YYYY HH:mm:SS");

    return (
      <div>
        <Col className={styles.commentsBlock}>
          <div className="d-flex">
            <div>
              <p style={{ fontSize: 14 }} className="font-weight-bold">
                <u>{t("last_observation")}:</u>
              </p>
            </div>
            <div className="ml-2">
              <p style={{ fontSize: 14, marginBottom: "0px" }}>{note.note}</p>
              <div>
                <small className="secondary_color font-weight-bold">
                  <AnonymousContainer isAnonymous={user?.anonymous}>
                    {fullName ?? ""}{" "}
                  </AnonymousContainer>
                  {createdAt}
                </small>
              </div>
            </div>
          </div>
        </Col>
      </div>
    );
  }, [student, user.anonymous, t]);
  return (
    <div>
      {renderLastComments()}
      <div className="mt-2">{renderLastNote()}</div>
    </div>
  );
};

export default RenderComment;
