import styled from "styled-components";
import CardNimbi from "./Card.styled";

const CardNimbiFixed = styled(CardNimbi)`
  position: fixed;
  bottom: 0;
  box-shadow: 0px -10px 30px 0px #7f7f7f29;
`;

export default CardNimbiFixed;
