import React, { useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { CardNimbi } from "components/Nimbiv2/styled";
import { validateAddInfo } from "../validations";
import { ContainerTitle, TitleCard } from "./styles";
import { StepTitle } from "components/Nimbiv2/InitiativesComponent";
import { NIcon } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";
import useTranslationLocal from "hooks/useTranslationLocal";
import { getGlobalConfig, isEmptyObject } from "utils";

const CardInfoInitiativeResume = ({
  formValues,
  edit,
  isExternal,
  onChangePosition,
}) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const { moments } = useSelector((state) => state.crm, _.isEqual);

  const momentsDerive = useMemo(() => {
    return moments.filter((item) => item.from_initiative === true);
  }, [moments]);

  const getIconExclamationCircle = () => {
    return (
      <NIcon
        iconname="exclamationCircle"
        style={{ marginLeft: "4px", color: "#E23232" }}
      ></NIcon>
    );
  };

  return (
    <div>
      <CardNimbi
        padding={"16px 35px 17px 18px"}
        bordercolor={
          !isEmptyObject(validateAddInfo(formValues)) ? "#E23232" : "#3AC8AC"
        }
      >
        <ContainerTitle>
          <TitleCard>{t("initiative_information")}</TitleCard>
          <div style={{ display: "flex" }}>
            <StepTitle>
              {t("step_number", {
                number: !edit ? (isExternal ? "4" : "3") : "1",
              })}{" "}
            </StepTitle>
            <NIcon
              iconname="edit"
              style={{
                marginLeft: "18px",
                fontSize: "var(--font-size-lg)",
                cursor: "pointer",
              }}
              onClick={onChangePosition}
            />
          </div>
        </ContainerTitle>
        <div style={{ marginTop: "24px" }}>
          <strong>{_.upperFirst(t("name"))}</strong>
          <div style={{ marginTop: "5px" }}>
            {formValues.name ? (
              formValues.name
            ) : (
              <div>
                {t("add_name")} {getIconExclamationCircle()}
              </div>
            )}
          </div>
        </div>
        <div style={{ marginTop: "24px" }}>
          {formValues.optionInitiative === "Email" && (
            <strong>{t("mailing_date")}</strong>
          )}
          {formValues.optionInitiative === "WhatsApp" && (
            <strong>{t("whatsapp_send_date")}</strong>
          )}
          {formValues.optionInitiative === "Call" && (
            <strong>{_.upperFirst(t("duration"))}</strong>
          )}
          {formValues.optionInitiative === "LMS" && (
            <strong>{t("lms_send_date")}</strong>
          )}
          <div style={{ marginTop: "5px" }}>
            {" "}
            {formValues.start_date ? (
              moment(formValues.start_date).format("DD/MM/YYYY")
            ) : (
              <div>
                {t("add_date")} {getIconExclamationCircle()}
              </div>
            )}
            {formValues.optionInitiative === "Call" && (
              <>
                {formValues.end_date ? " - " : ""}
                {formValues.end_date &&
                  moment(formValues.end_date).format("DD/MM/YYYY")}
              </>
            )}
          </div>
        </div>
        <div style={{ marginTop: "24px" }}>
          <strong>{_.upperFirst(t("objetive"))}</strong>
          <div style={{ marginTop: "5px" }}>
            {formValues.objective ? (
              formValues.objective
            ) : (
              <div>
                {t("add_target")}
                {getIconExclamationCircle()}
              </div>
            )}
          </div>
        </div>
        {formValues.source_type === "internal" &&
          getGlobalConfig("active_email_with_higher_priority", false) &&
          formValues.optionInitiative === "Email" && (
            <div style={{ marginTop: "24px" }}>
              <strong>{_.upperFirst(t("priority"))}</strong>
              <div style={{ marginTop: "5px" }}>
                {_.upperFirst(t(formValues.optionEmailPriority))}
              </div>
            </div>
          )}
        {getGlobalConfig("active_teams_initiative", false) && (
          <div style={{ marginTop: "24px" }}>
            <strong>{_.upperFirst(t("team"))}</strong>
            <div style={{ marginTop: "5px" }}>
              {formValues?.team_name ?? "-"}
            </div>
          </div>
        )}
        {formValues.source_type === "internal" && (
          <div style={{ marginTop: "24px" }}>
            <strong>
              {translation("campus", "startCase") +
                "/" +
                translation("tracking_object", "startCase")}
            </strong>
            <div style={{ marginTop: "10px" }}>
              {formValues.campos_selected && (
                <div>
                  {formValues.campos_selected.map((item, key) => {
                    if (key === 0) {
                      return item.campus;
                    }
                    return ", " + item.campus;
                  })}
                  {formValues.campos_selected.length === 1 &&
                    formValues.tracking_object_selected &&
                    ` - ${formValues.tracking_object_selected
                      .map((data, key) => {
                        if (key === 0) {
                          return data.name;
                        }
                        return data.name;
                      })
                      .join(", ")}`}
                </div>
              )}
            </div>
          </div>
        )}
        {formValues.optionInitiative === "Call" &&
          momentsDerive &&
          momentsDerive.length > 0 &&
          formValues.source_type === "internal" && (
            <div style={{ marginTop: "24px" }}>
              <strong>{t("derive_initiative")}</strong>

              <div style={{ marginTop: "5px" }}>
                <span style={{ fontWeight: "500" }}>
                  {_.upperFirst(t("derivation"))}:{" "}
                </span>
                {formValues.enter_action_plans
                  ? _.upperFirst(t("active"))
                  : _.upperFirst(t("inactive"))}
              </div>
              <div style={{ marginTop: "5px" }}>
                <span style={{ fontWeight: "500" }}>{t("action_plans")}: </span>
                {formValues.moments_selected &&
                formValues.moments_selected.length > 0 ? (
                  moments
                    .filter((item) =>
                      formValues.moments_selected.includes(item.id)
                    )
                    .map((item) => item.name)
                    .join(", ")
                ) : (
                  <>
                    {formValues.enter_action_plans
                      ? t("select_action_plans")
                      : "-"}
                    {formValues.enter_action_plans
                      ? getIconExclamationCircle()
                      : ""}
                  </>
                )}
              </div>
            </div>
          )}
        {getGlobalConfig("enable_send_all_contacts", false) &&
          formValues.source_type === "internal" &&
          formValues.optionInitiative === "Email" && (
            <div style={{ marginTop: "24px" }}>
              <strong>{_.upperFirst(t("send_all_student_contact"))}</strong>
              <div style={{ marginTop: "5px" }}>
                {formValues.send_all_contacts
                  ? _.upperFirst(t("active"))
                  : _.upperFirst(t("inactive"))}
              </div>
            </div>
          )}
      </CardNimbi>
    </div>
  );
};
export default CardInfoInitiativeResume;
