import { getGlobalConfig } from "utils";


export const validateStep1 = (formValues) => {
  return !formValues.name || !formValues.start_date || !formValues.objective;
};
export const validateStep2 = (formValues) => {
  if (formValues.optionInitiative === "Call") {
    if (!formValues.script_template) return true;
  }
  if (formValues.optionInitiative === "Email") {
    if (!formValues.email_template) return true;
  }
  return false;
};
export const validateStep3 = (formValues) => {
  if (formValues.source_type === "internal") {
    return (
      !formValues.student_trackings || formValues.student_trackings.length === 0
    );
  }
  return !formValues.base_file;
};

export const validateStep3File = (formValues) => {
  return !formValues.base_file;
};

export const validateStepFirst = (formValues) => {
  let errors = {};
  if (!formValues.optionCreate) {
    errors["optionCreate"] = true;
  }
  return errors;
};

export const validateStepInitial = (formValues) => {
  let errors = {};
  if (!formValues.optionInitiative) {
    errors["optionInitiative"] = true;
  }
  if (!formValues.source_type && formValues.optionCreate !== "copy")
    errors["source_type"] = true;
  return errors;
};

export const validateStepFile = (formValues) => {
  let errors = {};
  if (!formValues.base_file) {
    errors["base_file"] = true;
  }
  return errors;
};

export const validateAddInfo = (formValues) => {
  let errors = {};
  if (
    (!formValues.campos_selected || formValues.campos_selected.length === 0) &&
    formValues.source_type === "internal"
  ) {
    errors["campuses"] = "Campo requerido";
  }
  if (!formValues.name) {
    errors["name"] = "Campo requerido";
  }
  if (!formValues.start_date) {
    errors["start_date"] = "Campo requerido";
  }
  if (!formValues.end_date && formValues.optionInitiative === "Call") {
    errors["end_date"] = "Campo requerido";
  }
  if (!formValues.objective) {
    errors["objective"] = "Campo requerido";
  }
  if (
    formValues.enter_action_plans &&
    (!formValues.moments_selected || formValues.moments_selected.length === 0)
  ) {
    errors["enter_action_plans"] = "Selección requerido";
  }
  if (
    getGlobalConfig("active_email_with_higher_priority", false) &&
    (!formValues.optionEmailPriority) &&
    formValues.source_type === "internal"
  ) {
    errors["optionEmailPriority"] = "Campo requerido";
  }
  return errors;
};

export const validatePageTemplate = (formValues) => {
  let errors = {};
  if (
    formValues.optionInitiative === "Call" &&
    !formValues?.created_in_initiative
  ) {
    if (!formValues.script_template)
      errors["required_select_template"] = "Selección requerida";
  }
  if (formValues.optionInitiative === "Email") {
    if (!formValues.email_template)
      errors["required_select_template"] = "Selección requerida";
  }
  if (formValues.optionInitiative === "WhatsApp") {
    if (!formValues.whatsapp_template)
      errors["required_select_template"] = "Selección requerida";
  }
  return errors;
};

export const validatePageQuestionaries = (formValues) => {
  let errors = {};
  if (
    formValues.optionInitiative === "Call" &&
    !formValues?.question_selected
  ) {
    if (!formValues.script_template)
      errors["required_select_template"] = "Selección requerida";
  }
  if (formValues.optionInitiative === "Email") {
    if (!formValues.email_template)
      errors["required_select_template"] = "Selección requerida";
  }
  return errors;
};

export const validateSelectStudent = (formValues, client_area_max_internal_management) => {
  let errors = {};
  if (formValues.source_type === "internal") {
    if (
      !formValues.student_trackings ||
      formValues.student_trackings.length === 0
    ) {
      errors["student_trackings"] = "Selección requerida";
    }
    if (
      (formValues.student_trackings &&
      formValues.student_trackings.length > client_area_max_internal_management) ||
      (formValues.student_trackings &&
        formValues.student_trackings.length === 1 &&
        formValues.student_trackings[0] === 0 &&
        formValues.len_student_trackings > client_area_max_internal_management)
    ) {
      errors["student_trackings"] = `Los estudiantes seleccionados deben ser menor o igual a ${client_area_max_internal_management}`;
    }
  }
  // else {
  //   if (!formValues.base_file) {
  //     errors["required_select_student"] = "Selección requerida";
  //   }
  // }
  return errors;
};

export const validateStudentResponse = (formValues) => {
  let errors = {};
  if (
    !formValues?.student_responses ||
    formValues?.student_responses?.length === 0
  ) {
    errors["required_student_responses"] = "Campo requerido";
  }
  return errors;
};
