import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "components";
import { NButton } from "components/Nimbiv2";
import { Link } from "react-router-dom";
import styles from "./Form.module.css";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const FormComponent = ({ onSubmit, isPending }) => {
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(userName, password);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formBasicEmail" style={{ marginBottom: "20px" }}>
        <Form.Label>Ingrese el email de la cuenta</Form.Label>
        <Form.Control
          type="text"
          placeholder="Email"
          value={userName}
          onChange={(event) => setUsername(event.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>Ingrese su clave personal</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </Form.Group>
      <div style={{ marginTop: "24px", marginBottom: "50px" }}>
        <Link
          className={`${styles.textLink} d-flex justify-content-center`}
          to="/password-recovery"
        >
          He olvidado mi contraseña
        </Link>
      </div>
      <NButton
        variant="primary"
        type="submit"
        disabled={isPending}
        loading={isPending}
        block
        colorbutton="#334FFF"
        hoverbuttom="#112C9C"
        border="8px"
        padding="10px 16px"
      >
        {_.capitalize(t("login"))}
      </NButton>
    </Form>
  );
};

FormComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
};

FormComponent.defaultProps = { isPending: false };

export default FormComponent;
