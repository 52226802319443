import React, { useMemo } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { AnonymousContainer, CardNimbi } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { getGlobalConfig } from "utils";
import { useSelector } from "react-redux";
import { NBadge } from "components";
import { useTranslationLocal } from "hooks";
import { colorsBadge } from "utils/constants";
import moment from "moment";
import CardStudentSkeletons from "components/Skeletons/CardStudent";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { faClone } from "@fortawesome/pro-regular-svg-icons";

const CardStudent = ({ selectTracking }) => {
  const { translation } = useTranslationLocal();
  const { t } = useTranslation();
  const { personsProfile } = useSelector((state) => state.crm);
  const { user } = useSelector((state) => state.auth);
  //const [selectTracking, setSelectTracking] = useState(null);

  const validateDocument = useMemo(() => {
    return (
      personsProfile &&
      personsProfile.document_type &&
      personsProfile.document_number &&
      personsProfile.document_type !== "" &&
      personsProfile.document_number !== ""
    );
  }, [personsProfile]);

  const StudentTrackingIsActive = useMemo(() => {
    return selectTracking?.student_tracking_active ?? false;
  }, [selectTracking]);
  const colorStatus = {
    true: colorsBadge.light.success,
    false: colorsBadge.light.inactive,
  };

  const copyInfo = (data) => {
    copy(data);
    toast.info(`Copiado: ${data}`);
  };

  return (
    <CardNimbi padding="18px 20px 13px 20px" border="1px solid #8DA6FF">
      {!personsProfile && <CardStudentSkeletons />}
      {personsProfile && (
        <>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faUserCircle}
              style={{
                fontSize: "32px",
                color: "#8DA6FF",
                marginRight: "16px",
              }}
            />
            <div
              style={{
                fontSize: "24px",
                color: "#334FFF",
                fontWeight: "500",
              }}
            >
              {personsProfile?.first_name}{" "}
              <AnonymousContainer isAnonymous={user.anonymous} radius="5px">
                {personsProfile?.last_name}
              </AnonymousContainer>
            </div>
          </div>
          <div
            className="d-flex"
            style={{
              color: "#787C83",
              fontSize: "var(--font-size-md)",
              marginTop: "12px",
            }}
          >
            <AnonymousContainer isAnonymous={user.anonymous}>
              {getGlobalConfig("id_student_show", "yes") === "yes" &&
                personsProfile &&
                personsProfile.external_id &&
                personsProfile.external_id !== "" && (
                  <div className="mr-2">
                    <span style={{ fontWeight: "500" }}>ID:</span>{" "}
                    {personsProfile.external_id}
                    <OverlayTrigger
                      overlay={
                        <Tooltip>Copiar: {personsProfile.external_id}</Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        onClick={() => copyInfo(personsProfile.external_id)}
                        className={`ml-1`}
                        size="1x"
                        icon={faClone}
                        style={{ cursor: "pointer" }}
                      />
                    </OverlayTrigger>
                  </div>
                )}
              {validateDocument && (
                <div>
                  <span style={{ fontWeight: "500" }}>
                    {personsProfile.document_type === "Other"
                      ? translation("document_type_dynamic", "", t("other"))
                      : personsProfile.document_type}
                    :
                  </span>{" "}
                  {personsProfile.document_number}
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        Copiar: {personsProfile.document_number}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      onClick={() => copyInfo(personsProfile.document_number)}
                      className={`ml-1`}
                      size="1x"
                      icon={faClone}
                      style={{ cursor: "pointer" }}
                    />
                  </OverlayTrigger>
                </div>
              )}
            </AnonymousContainer>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: "7.5px" }}
          >
            <div
              style={{
                color: "#787C83",
                fontSize: "var(--font-size-md)",
              }}
            >
              <span style={{ fontWeight: "500" }}>Último contacto: </span>{" "}
              {personsProfile?.last_contact
                ? moment(personsProfile.last_contact.closed_at).format(
                    "YYYY-MM-DD"
                  )
                : "-"}
            </div>
            <NBadge
              text={_.upperFirst(
                StudentTrackingIsActive ? t("active") : t("inactive")
              )}
              customStyle={{
                borderRadius: "64px",
              }}
              backgroundColor={colorStatus[StudentTrackingIsActive].background}
              fontColor={colorStatus[StudentTrackingIsActive].fontColor}
            />
          </div>
        </>
      )}
    </CardNimbi>
  );
};

export default CardStudent;
