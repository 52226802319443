import React, { useEffect, useMemo, useState } from "react";
import { NButton, NInput } from "components/Nimbiv2";
import BadgeDropdown from "components/Nimbiv2/BadgeDropdown";
import { useSelector } from "react-redux";
import { getOptionsExternalManagement } from "services/externalManagement.service";
import { getText } from "utils";
import { useTranslation } from "react-i18next";
import BadgeDateDropdown from "components/Nimbiv2/BadgeDateDropdown";
import moment from "moment";

const HistoryFilter = ({ filters, applyFilter = () => {} }) => {
  const { t } = useTranslation();
  const {
    personMoments: studentMoments,
    personCourses: studentCourses,
    personInitiatives: studentInitiatives,
  } = useSelector((state) => state.crm);
  const [actionPlan, setActionPlan] = useState(null);
  const [course, setCourse] = useState(null);
  const [typeExternal, setTypeExternal] = useState(null);
  const [initiative, setInitiative] = useState(null);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState([null, null]);
  const [dateFormat, setDateFormat] = useState([null, null]);
  const [options, setOptions] = useState({
    channels: [],
    states: [],
    topics: [],
    types: [],
    subtopics: [],
  });

  const getOptions = async () => {
    const { data } = await getOptionsExternalManagement();
    setOptions(data);
  };
  useEffect(() => {
    getOptions();
  }, []);

  const optionsExternalTypes = useMemo(() => {
    let types = options?.types;
    types.unshift({ id: 0, name: "Todos los tipos de gestión" });
    return types;
  }, [options]);

  useEffect(() => {
    let newFilter = {};
    if (actionPlan && actionPlan?.id !== 0) {
      let f = {
        moment_id: actionPlan?.id,
        include_externals: (actionPlan?.id.toString() === "0") | 0,
      };
      newFilter = { ...newFilter, ...f };
    }
    if (course && course?.id !== 0) {
      let f = {
        course_id: course?.id,
        include_notes: (course?.id.toString() === "0") | 0,
      };
      newFilter = { ...newFilter, ...f };
    }
    if (typeExternal && typeExternal?.id !== 0) {
      let f = {
        external_management_id: typeExternal?.id,
      };
      newFilter = { ...newFilter, ...f };
    }
    if (initiative && initiative?.id !== 0) {
      let f = {
        initiative_id: initiative?.id,
      };
      newFilter = { ...newFilter, ...f };
    }
    if (search && search !== "") {
      newFilter = { search_data: search };
    }
    if (dateFormat && date.filter((item) => item !== null).length > 0) {
      newFilter = { date_range: dateFormat };
    }
    applyFilter(newFilter);
  }, [actionPlan, course, typeExternal, initiative, search, dateFormat]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <NInput
        placeholder="Buscar..."
        onChange={(data) => {
          setSearch(data);
        }}
        defaultValue={search}
      />
      <div
        className="d-flex"
        style={{
          paddingTop: "15px",
          paddingBottom: "15px",
          flexWrap: "wrap",
          rowGap: "10px",
        }}
      >
        {/* <BadgeDropdown text={"Fecha: "} /> */}
        <BadgeDateDropdown
          text={`Fecha: ${
            date.filter((item) => item !== null).length > 0
              ? dateFormat.join(" - ")
              : ""
          }`}
          dateSelected={date}
          onSelect={(dateRange) => {
            const initDateFormat = moment(dateRange[0]).format("YYYY-MM-DD");
            const endDateFormat = moment(dateRange[1]).format("YYYY-MM-DD");
            setDate(dateRange);
            if (dateRange[0] && dateRange[1]) {
              setDateFormat([initDateFormat, endDateFormat]);
            }
          }}
        />
        <BadgeDropdown
          text={`Tipo de gestión: ${typeExternal ? typeExternal?.name : ""}`}
          dataset={optionsExternalTypes}
          label="name"
          onSelect={(data) => {
            setTypeExternal(data);
          }}
        />
        <BadgeDropdown
          text={`Plan de acción: ${actionPlan ? actionPlan?.name : ""}`}
          dataset={studentMoments}
          label="name"
          onSelect={(data) => {
            setActionPlan(data);
          }}
        />
        <BadgeDropdown
          text={`Campaña: ${initiative ? initiative?.name : ""}`}
          dataset={studentInitiatives}
          label="name"
          onSelect={(data) => {
            setInitiative(data);
          }}
        />
        <BadgeDropdown
          text={`${getText("tracking_object", "startCase", t("course"))}: ${
            course ? course?.name : ""
          }`}
          dataset={studentCourses}
          label={"name"}
          onSelect={(data) => {
            setCourse(data);
          }}
        />
        {
          //(search !== "" && search !== null) ||
          (date[0] !== null ||
            dateFormat[0] !== null ||
            typeExternal ||
            actionPlan ||
            initiative ||
            course) && (
            <NButton
              outlined
              onClick={() => {
                setSearch(null);
                setDate([null, null]);
                setDateFormat([null, null]);
                setTypeExternal(null);
                setActionPlan(null);
                setInitiative(null);
                setCourse(null);
              }}
            >
              {t("clear_filters")}
            </NButton>
          )
        }
      </div>
    </div>
  );
};
export default HistoryFilter;
