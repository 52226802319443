import {
  getTasks,
  getStudentResponses,
  getGroupedTasks,
  getMoments,
  getMomentsWithoutTeam,
  getUsedTags,
  getCallResults,
  getPersonsProfile,
  getStudentTrackingsPerson,
  getPersonTasksHistory,
  getPersonHistory,
  getCourses,
  getActionTemplates,
  templateDispatcher,
  getAllCampus,
  getListCampus,
  getStudentCourses,
  getStudentMoments,
  getPeriods,
  getStudentInitiatives,
  getSurveyPeriods,
} from "services/crmService";

import { APP_MODULES, getSessionFilters } from "utils";
import * as CrmActions from "../crm.actions";
import * as SiteActions from "../site.actions";
import { handleErrorStatus } from "../../../services/errors";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

export const getTaskList = (initialParams = null) => {
  return (dispatch) => {
    const queryParams = getSessionFilters(APP_MODULES.TASK.FILTER_NAME, true);
    const params = initialParams ?? queryParams;
    dispatch(CrmActions.getTaskList(params));
    if ("moment" in params || "initiative_id" in params) {
      getTasks(initialParams ?? queryParams)
        .then(({ data }) => {
          dispatch(CrmActions.setTasksList(data));
          if (data.results.filters_data.length > 0) {
            dispatch(SiteActions.setClientFilter(data.results.filters_data));
          }
        })
        .catch((err) => {
          handleErrorResponse(err);
          dispatch(CrmActions.registerError({ err, key: "list" }));
        });
    }
  };
};

export const fetchStudentResponses = (queryParams) => {
  return (dispatch) => {
    dispatch(CrmActions.getStudentResponses());
    getStudentResponses(queryParams)
      .then(({ data }) => dispatch(CrmActions.setStudentResponses(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError(err));
      });
  };
};

export const fetchCallResults = (queryParams) => {
  return (dispatch) => {
    dispatch(CrmActions.getCallResults());
    getCallResults(queryParams)
      .then(({ data }) => dispatch(CrmActions.setCallResults(data.results)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError(err));
      });
  };
};

export const fetchGroupedTasks = (studentId, moment) => {
  return (dispatch) => {
    dispatch(CrmActions.excludeStudentFromTasks(studentId));
    getGroupedTasks(studentId, moment)
      .then(({ data }) => dispatch(CrmActions.setGroupedTasks(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError(err));
      });
  };
};

export const fetchMoments = () => {
  return (dispatch) => {
    dispatch(CrmActions.getMoments());
    getMoments()
      .then(({ data }) => dispatch(CrmActions.setMoments(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError(err));
      });
  };
};

export const fetchMomentsWithoutTeam = () => {
  return (dispatch) => {
    dispatch(CrmActions.getMoments());
    getMomentsWithoutTeam()
      .then(({ data }) => dispatch(CrmActions.setMoments(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError(err));
      });
  };
};

export const fetchCampus = () => {
  return (dispatch) => {
    dispatch(CrmActions.getCampus());
    getAllCampus({ page: 1, page_size: 1000 })
      .then(({ data }) => dispatch(CrmActions.setCampus(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError({ err, key: "campus" }));
      });
  };
};

export const fetchListCampus = () => {
  return (dispatch) => {
    dispatch(CrmActions.getListCampus());
    getListCampus({ page: 1, page_size: 1000 })
      .then(({ data }) => dispatch(CrmActions.setListCampus(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError({ err, key: "campus" }));
      });
  };
};

export const fetchUsedTags = () => {
  return (dispatch) => {
    dispatch(CrmActions.getUsedTags());
    getUsedTags()
      .then(({ data }) => dispatch(CrmActions.setUsedTags(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError(err));
      });
  };
};

export const fetchPersonsProfile = (studentId, params = {}) => {
  return (dispatch) => {
    dispatch(CrmActions.togglePending({ attr: "profile", sw: true }));
    getPersonsProfile(studentId, params)
      .then(({ data }) => dispatch(CrmActions.setPersonsProfile(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError({ err, key: "profile" }));
      });
  };
};

export const fetchStudentTrackingsPerson = (studentId, params = {}) => {
  return (dispatch) => {
    dispatch(CrmActions.togglePending({ attr: "persontasks", sw: true }));
    getStudentTrackingsPerson(studentId, params)
      .then(({ data }) => dispatch(CrmActions.setPersonTasks(data.results)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError({ err, key: "persontasks" }));
      });
  };
};

export const fetchPersonTasksHistory = (PersonId) => {
  return (dispatch) => {
    dispatch(
      CrmActions.togglePending({ attr: "persontaskshistory", sw: true })
    );
    getPersonTasksHistory(PersonId)
      .then(({ data }) => {
        dispatch(CrmActions.setPersonTasksHistory(data.results));
      })
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError({ err, key: "persontaskshistory" }));
      });
  };
};

export const fetchPersonHistory = (personId, filters = {}) => {
  return (dispatch) => {
    dispatch(CrmActions.togglePending({ attr: "personhistory", sw: true }));
    getPersonHistory(personId, filters)
      .then(({ data }) => {
        dispatch(CrmActions.setPersonEventHistory(data.data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError({ err, key: "personhistory" }));
      });
  };
};

export const fetchCourses = () => {
  return (dispatch) => {
    dispatch(CrmActions.getCourses());
    getCourses()
      .then(({ data }) => dispatch(CrmActions.setCourses(data.results)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError({ err, key: "course" }));
      });
  };
};

export const runTemplateDispatcher = (payload) => {
  return () => {
    templateDispatcher(payload)
      .then(() => {})
      .catch(handleErrorResponse);
  };
};

export const fetchTemplates = (channel) => {
  return (dispatch) => {
    getActionTemplates(channel)
      .then(({ data }) =>
        dispatch(
          CrmActions.setTemplateActions({
            channel,
            data: data.results,
          })
        )
      )
      .catch(handleErrorResponse);
  };
};

export const fetchStudentCourses = (studentId) => {
  return (dispatch) => {
    dispatch(CrmActions.togglePending({ attr: "personcourses", sw: true }));
    getStudentCourses(studentId)
      .then(({ data }) => {
        dispatch(CrmActions.setCourseStudents(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError({ err, key: "persontaskshistory" }));
      });
  };
};

export const fetchStudentMoments = (studentId) => {
  return (dispatch) => {
    dispatch(CrmActions.togglePending({ attr: "personmoments", sw: true }));
    getStudentMoments(studentId)
      .then(({ data }) => {
        dispatch(CrmActions.setMomentStudents(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError({ err, key: "persontaskshistory" }));
      });
  };
};

export const fetchStudentInitiatives = (studentId) => {
  return (dispatch) => {
    dispatch(CrmActions.togglePending({ attr: "personinitiatives", sw: true }));
    getStudentInitiatives(studentId)
      .then(({ data }) => {
        dispatch(CrmActions.setInitiativesStudents(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError({ err, key: "personinitiatives" }));
      });
  };
};

export const fetchPeriods = (queryParams) => {
  return (dispatch) => {
    dispatch(CrmActions.getPeriods());
    getPeriods(queryParams)
      .then(({ data }) => dispatch(CrmActions.setPeriods(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError(err));
      });
  };
};

export const fetchSurveyPeriods = (student_tracking_id, queryParams) => {
  return (dispatch) => {
    dispatch(CrmActions.getSurveyPeriods());
    getSurveyPeriods(student_tracking_id, queryParams)
      .then(({ data }) => dispatch(CrmActions.setSurveyPeriods(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(CrmActions.registerError(err));
      });
  };
};
