import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPersonArrowDownToLine,
  faPersonArrowUpFromLine,
  faEmptySet,
  faUserHeadset,
} from "@fortawesome/pro-regular-svg-icons";

import { APP_MODULES, getSessionFilters } from "utils";
import {
  setDataToLocalStorage,
  setSessionFilters,
  getDataFromLocalStorage,
} from "utils";
import { fetchStudents } from "store/actions/async/student-async.actions";
import NCheckBadge from "./NCheckBadge";
import { useTranslation } from "react-i18next";

const NRiskOrder = React.memo(
  ({ isDisabled = false, changeTab, riskFilter = false }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [riskOrder, setRiskOrder] = useState(() => {
      return getDataFromLocalStorage("risk_order", "");
    });
    const [showNoInfo, setShowNoInfo] = useState(() => {
      return getDataFromLocalStorage("show_no_info", false);
    });

    const [managedByMe, setManagedByMe] = useState(() => {
      return getDataFromLocalStorage("managed_by_me", false);
    });

    useEffect(() => {
      const managedByMe = getDataFromLocalStorage("managed_by_me", false);
      const currentOrder = getDataFromLocalStorage("risk_order", "");
      const currentShowNoInfo = getDataFromLocalStorage("show_no_info", false);
      const queryParams = getSessionFilters(
        APP_MODULES.STUDENT.FILTER_NAME,
        true
      );
      const newQueryParams = {
        ...queryParams,
        ...{
          //page: 1,
          risk_ordering: currentOrder,
          show_no_info: currentShowNoInfo,
          managedByMe: managedByMe,
        },
      };
      setSessionFilters(APP_MODULES.STUDENT.FILTER_NAME, newQueryParams);
      setRiskOrder(currentOrder);
      setShowNoInfo(currentShowNoInfo);
    }, [changeTab]);

    const toggleOrdering = useCallback(
      (ordering) => {
        setDataToLocalStorage("avoidReloadTasks", true);
        setSessionFilters(APP_MODULES.STUDENT.FILTER_NAME, {
          page: 1,
          risk_ordering: ordering,
          show_no_info: false,
          managed_by_me: false,
        });
        setRiskOrder(ordering);
        setManagedByMe(false);
        setShowNoInfo(false);
        dispatch(fetchStudents());
        setDataToLocalStorage("risk_order", ordering);
        setDataToLocalStorage("show_no_info", false);
      },
      [dispatch]
    );

    const handleAscChecked = (event) => {
      const isChecked = event.target.checked;
      const ordering = isChecked ? "ASC" : "";
      toggleOrdering(ordering);
    };

    const handleDescChecked = (event) => {
      const isChecked = event.target.checked;
      const ordering = isChecked ? "DESC" : "";
      toggleOrdering(ordering);
    };

    const handleShowNoInfoChecked = useCallback(
      (event) => {
        const isChecked = event.target.checked;
        setDataToLocalStorage("avoidReloadTasks", true);
        setSessionFilters(APP_MODULES.STUDENT.FILTER_NAME, {
          page: 1,
          show_no_info: isChecked,
          risk_ordering: "",
          managed_by_me: false,
        });
        setShowNoInfo(isChecked);
        setRiskOrder("");
        setManagedByMe(false);
        dispatch(fetchStudents());
        setDataToLocalStorage("show_no_info", isChecked);
        setDataToLocalStorage("risk_order", "");
      },
      [dispatch]
    );

    const handleManagedByMeChecked = useCallback(
      (event) => {
        const isChecked = event.target.checked;
        setManagedByMe(isChecked);
        setDataToLocalStorage("avoidReloadTasks", true);
        setSessionFilters(APP_MODULES.STUDENT.FILTER_NAME, {
          page: 1,
          show_no_info: false,
          risk_ordering: "",
          managed_by_me: isChecked,
        });
        setShowNoInfo(false);
        setRiskOrder("");
        dispatch(fetchStudents());
        setDataToLocalStorage("show_no_info", false);
        setDataToLocalStorage("risk_order", "");
        setDataToLocalStorage("managed_by_me", isChecked);
      },
      [dispatch]
    );

    return (
      // <div
      //   className="d-flex justify-content-start justify-content-lg-end align-items-center"
      //   style={{
      //     fontSize: "14px",
      //     height: "100%",
      //     flexWrap: "wrap",
      //     rowGap: "0.5rem",
      //   }}
      // >
      <>
        {riskFilter && (
          <>
            <NCheckBadge
              onChange={handleDescChecked}
              checked={riskOrder === "DESC"}
              disabled={isDisabled}
              label={t("higher_risk")}
              className="mr-2"
              icon={<FontAwesomeIcon icon={faPersonArrowUpFromLine} />}
            />
            <NCheckBadge
              onChange={handleAscChecked}
              checked={riskOrder === "ASC"}
              disabled={isDisabled}
              label={t("lower_risk")}
              className="mr-2"
              icon={<FontAwesomeIcon icon={faPersonArrowDownToLine} />}
            />
            <NCheckBadge
              onChange={handleShowNoInfoChecked}
              checked={showNoInfo}
              disabled={isDisabled}
              className="mr-2"
              label={t("no_risk_information")}
              icon={<FontAwesomeIcon icon={faEmptySet} />}
            />
          </>
        )}
        <NCheckBadge
          onChange={handleManagedByMeChecked}
          checked={managedByMe}
          disabled={isDisabled}
          label={t("managed_by_me")}
          icon={<FontAwesomeIcon icon={faUserHeadset} />}
        />
      </>
      // </div>
    );
  }
);
export default NRiskOrder;
