/* eslint-disable react/require-default-props */
import React, { useState, useCallback, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Highlighter } from "react-bootstrap-typeahead";
import { APP_MODULES, setSessionFilters } from "utils";
import NInputFilter from "../NInputFilter";
import { useTranslation } from "react-i18next";
import styles from "./NSearchStudents.module.css";
import { useTranslationLocal } from "hooks";

const {
  STUDENT: { FILTER_NAME: FILTER_STUDENT },
} = APP_MODULES;
const filterFields = [
  "first_name",
  "last_name",
  "document_number",
  "email",
  "external_id",
];

const NSearchStudents = ({
  filterKey,
  fieldName,
  onChange,
  asyncSearch,
  persist = false,
  isDisabled = false,
  defaultSearch,
  applyFiltersStudent = true,
  external = false,
  emptyLabel,
}) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const [filterCourse, setFilterCourse] = useState([]);

  useEffect(() => {
    if (defaultSearch && !_.isEmpty(defaultSearch)) {
      const array = [defaultSearch];
      setFilterCourse(array);
    } else {
      setFilterCourse([]);
    }
  }, [defaultSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterChanged = useCallback(
    (data) => {
      const [selected = {}] = data;
      if (applyFiltersStudent) {
        if (persist) {
          const query = selected[fieldName];
          setSessionFilters(FILTER_STUDENT, {
            page: 1,
            [filterKey]: query ?? "",
          });
        }
      }

      setFilterCourse(data);
      onChange(selected);
    },
    [fieldName, filterKey, persist, onChange, applyFiltersStudent]
  );

  const customMenuItem = (option, { text }) => {
    return (
      <div className={styles.filterBody}>
        <div>
          <Highlighter search={text}>
            {option.full_name
              ? option.full_name
              : `${option.first_name} ${option.last_name}`}
          </Highlighter>
        </div>
        {option?.external_id && (
          <div>
            <small>ID: {option?.external_id?.toLocaleString()}</small>
          </div>
        )}
        <div>
          <small>
            {(option.documentType ?? option.document_type ?? "Other") ===
            "Other"
              ? translation("document_type_dynamic", "", t("other"))
              : option.documentType ?? option.document_type ?? "RUT"}
            :{" "}
            {option?.document_number?.toLocaleString() ??
              option?.document_number}
          </small>
        </div>
      </div>
    );
  };

  const customInput = (text) => {
    if (text !== null) {
      let string = text.toString();
      return string.includes("@") ? string : string.replaceAll(".", "");
    }
    return text;
  };

  return (
    <NInputFilter
      handleChange={handleFilterChanged}
      data={[]}
      delay={800}
      compositeLabel={
        external
          ? ["document_number", "full_name"]
          : ["document_number", "first_name", "last_name"]
      }
      placeholder={translation(
        "placeholder_search_student",
        t("placeholder_search_student")
      )}
      selected={filterCourse}
      filterBy={filterFields}
      renderMenuItem={customMenuItem}
      asyncSearch={(query) => asyncSearch(query)}
      isDisabled={isDisabled}
      isAsync
      formatInput={customInput}
      emptyLabel={emptyLabel}
    />
  );
};

NSearchStudents.propTypes = {
  /**
   * The name of the search field to add to the request header.
   *  i.e: first_name, last_name.
   * in case it's general search, this value should be: 'search' instead.
   */
  filterKey: PropTypes.string.isRequired,

  /**
   * Name of the field from which the value is obtained in selected item.
   *  i.e: id, external_id, first_name, last_name... etc.
   * this value is persisted in the localstorage
   */
  fieldName: PropTypes.string.isRequired,

  /**
   * function to execute when an item is selected.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * indicates whether the results persist in LocalStorage or not.
   */
  persist: PropTypes.bool,
};

export default NSearchStudents;
