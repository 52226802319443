import React, { useEffect } from "react";
import _ from "lodash";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import "./App.sass";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { toast } from "react-toastify";
import RouteWrapper from "routes/RouteWrapper";
import RouteChangeTracker from "utils/RouteChangeTracker";
import ReactGA from "react-ga4";
import * as hocPages from "routes/hocPages";
import * as routePaths from "./routes/routePaths";
import {
  Login,
  NotFound,
  PasswordRecovery,
  PasswordReset,
  LoginSSO,
} from "./pages";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import "url-search-params-polyfill";
import NSiderBar from "components/Nimbiv2/NSiderBar";
import { isNewVersion } from "utils";
// import FreshChatBot from "components/Nimbiv2/FreshChatBot";
import { useDispatch, useSelector } from "react-redux";

registerLocale("es", es);
setDefaultLocale("es");
const TRACKING_ID = "G-H7PTXDH595";
ReactGA.initialize(TRACKING_ID);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const history = createBrowserHistory();
toast.configure({
  hideProgressBar: true,
  autoClose: 6000,
});

function App() {
  const { token, user } = useSelector((state) => state.auth, _.isEqual);
  const query = useQuery();
  const dispatch = useDispatch();

  const locationx = useLocation();

  useEffect(() => {
    isNewVersion(dispatch);
    localStorage.setItem("loadTaskResult", false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const exludeSider = [undefined, "", "/error/401"];
  return (
    <Router>
      <div className="App">
        <RouteChangeTracker />
        <div className={`app`}>
          {token && user && !exludeSider.includes(locationx.pathname) && (
            <NSiderBar />
          )}

          <Switch>
            <RouteWrapper
              path={routePaths.login.route}
              roles={routePaths.login.roles}
              component={Login}
              exact
            />
            <RouteWrapper
              path={"/valid"}
              roles={[]}
              component={hocPages.FeedbackClientSuccess}
              exact
            />
            <RouteWrapper
              path={"/invalid"}
              roles={[]}
              component={hocPages.FeedbackClientError}
              exact
            />
            <RouteWrapper
              path={routePaths.loginGeneral.route}
              roles={routePaths.loginGeneral.roles}
              component={Login}
              exact
            />
            <RouteWrapper
              path={routePaths.passwordRecovery.route}
              roles={routePaths.passwordRecovery.roles}
              component={PasswordRecovery}
              exact
            />
            <Route
              path={routePaths.passwordReset.route}
              roles={routePaths.passwordReset.roles}
              exact
            >
              <PasswordReset token={query.get("token")} />
            </Route>
            <Route
              path={routePaths.passwordCreate.route}
              roles={routePaths.passwordCreate.roles}
              exact
            >
              <PasswordReset token={query.get("token")} />
            </Route>
            <RouteWrapper
              path={routePaths.loginSSO.route}
              roles={routePaths.loginSSO.roles}
              component={LoginSSO}
              exact
            />
            <RouteWrapper
              path={routePaths.dashboard.route}
              roles={routePaths.dashboard.roles}
              component={hocPages.dashboard}
              exact
            />
            <RouteWrapper
              path={routePaths.tasks.mainPage.route}
              roles={routePaths.tasks.mainPage.roles}
              component={hocPages.tasks}
              exact
            />
            <RouteWrapper
              path={routePaths.tasks.detail.route}
              roles={routePaths.tasks.detail.roles}
              component={hocPages.tasksDetail}
              exact
            />
            <RouteWrapper
              path={routePaths.reports.route}
              roles={routePaths.reports.roles}
              component={hocPages.reports}
              exact
            />
            <RouteWrapper
              path={routePaths.students.mainPage.route}
              roles={routePaths.students.mainPage.roles}
              component={hocPages.students}
              exact
            />
            <RouteWrapper
              path={routePaths.students.detail.route}
              roles={routePaths.students.detail.roles}
              component={hocPages.studentDetail}
              exact
            />
            <RouteWrapper
              path={routePaths.administration.mainPage.route}
              roles={routePaths.administration.mainPage.roles}
              component={() => hocPages.administration()}
              exact
            />
            <RouteWrapper
              path={routePaths.administration.add.route}
              roles={routePaths.administration.add.roles}
              component={() => hocPages.administration("add")}
              exact
            />
            <RouteWrapper
              path={routePaths.administration.edit.route}
              roles={routePaths.administration.edit.roles}
              component={() => hocPages.administration("edit")}
              exact
            />
            <RouteWrapper
              path={routePaths.moments.mainPage.route}
              roles={routePaths.moments.mainPage.roles}
              component={hocPages.moments}
              exact
            />
            <RouteWrapper
              path={routePaths.moments.detail.route}
              roles={routePaths.moments.detail.roles}
              component={hocPages.momentDetail}
              exact
            />
            <RouteWrapper
              path={routePaths.initiatives.mainPage.route}
              roles={routePaths.initiatives.mainPage.roles}
              component={hocPages.initiatives}
              exact
            />
            <RouteWrapper
              path={routePaths.help.mainPage.route}
              roles={routePaths.help.mainPage.roles}
              component={() => hocPages.help()}
              exact
            />
            {/* <RouteWrapper
              path={routePaths.externalManagement.add.route}
              roles={routePaths.externalManagement.add.roles}
              component={() => hocPages.pageExternalManagement("add")}
              exact
            />
            <RouteWrapper
              path={routePaths.externalManagement.detail.route}
              roles={routePaths.externalManagement.detail.roles}
              component={() => hocPages.pageExternalManagement("detail")}
              exact
            />
            <RouteWrapper
              path={routePaths.externalManagement.mainPage.route}
              roles={routePaths.externalManagement.mainPage.roles}
              component={() => hocPages.pageExternalManagement()}
              exact
            /> */}
            <RouteWrapper
              path={routePaths.help.detail.route}
              roles={routePaths.help.detail.roles}
              component={() => hocPages.help("detail")}
              exact
            />
            <RouteWrapper
              path={routePaths.initiatives.add.route}
              roles={routePaths.initiatives.add.roles}
              component={hocPages.initiativeCreate}
              exact
            />
            <RouteWrapper
              path={routePaths.initiatives.edit.route}
              roles={routePaths.initiatives.edit.roles}
              component={hocPages.initiativeEdit}
              exact
            />
            <RouteWrapper
              path={routePaths.initiatives.detail.route}
              roles={routePaths.initiatives.detail.roles}
              component={hocPages.initiativeDetail}
              exact
            />
            <RouteWrapper
              path={routePaths.associated.add.route}
              roles={routePaths.associated.add.roles}
              component={hocPages.AddAssociated}
              exact
            />
            <Route path="/error/:code?" component={NotFound} exact />
            <RouteWrapper
              path={"*"}
              roles={[]}
              component={hocPages.pageError}
              exact
            />
          </Switch>
        </div>
      </div>
      {/* {token && user && !exludeSider.includes(locationx.pathname) && (
        <FreshChatBot
          dataClient={getFreshchatClient()}
          dataBot={getFreshchatBot()}
          firstName={user.first_name}
          lastName={user.last_name}
          email={user.username}
          language="es-LA"
        />
      )} */}
    </Router>
  );
}

export default App;
