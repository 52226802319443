import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { NButton, NSpinnerPoints } from "components/Nimbiv2";
import { DividerH, RowItem } from "components/Nimbiv2/styled";
import { ButtonSize } from "utils/constants";
import { Accordion, Card, Col, NBadge } from "components";
import { useTranslationLocal } from "hooks";
import { getGlobalConfig } from "utils";
import { toast } from "react-toastify";
import { read, utils } from "xlsx";
import { createMassiveMilestones } from "services/student.service";
import { ToggleHeader } from "components/Nimbiv2/NAccordion";

const FormMilestonesMassive = ({ openSide, onClose = () => {} }) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const [baseFile, setBaseFile] = useState(null);
  const [errors, setErrors] = useState([]);
  const [selectMenu, setSelectMenu] = useState(null);
  const [loadErrors, setLoadErrors] = useState(null);
  const client_area_max_milestones = useSelector(
    (state) => state.auth.user?.client_area_max_milestones
  );

  const getLineErrors = (rows) => {
    setLoadErrors(true);
    let errors = [];
    for (let i = 0; i < rows.length; i++) {
      let data = [];
      let item = rows[i];
      if (!("Rut" in item && item["Rut"] !== "")) {
        data.push("Rut");
      }
      if (data.length > 0) {
        errors.push({
          nro_line: i + 1,
          error_description: `Las columnas ${data.join(", ")} estan vacias`,
        });
      }
    }
    setLoadErrors(false);
    return errors;
  };

  useEffect(() => {
    setErrors([]);
    setBaseFile(null);
  }, [openSide]);

  const onDrop = (listFiles) => {
    if (listFiles.length > 0) {
      var reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      setBaseFile(null);
      reader.onload = (e) => {
        const bstr = e.target.result;
        setErrors([]);
        const wb = read(bstr, { type: rABS ? "binary" : "array" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        var excelRows = utils.sheet_to_row_object_array(ws);
        var number = excelRows.length;
        if (number > 0 && number <= client_area_max_milestones) {
          const listErrors = getLineErrors(excelRows);
          if (listErrors.length === 0) {
            setBaseFile(listFiles[0]);
          } else {
            setErrors(listErrors);
          }
        } else {
          if (number === 0)
            setErrors([
              {
                nro_line: null,
                error_description: "El archivo no puede estar vacío",
              },
            ]);
          if (number > client_area_max_milestones)
            setErrors([
              { nro_line: null, error_description: "Error de datos" },
            ]);
        }
      };
      if (rABS) reader.readAsBinaryString(listFiles[0]);
      else reader.readAsArrayBuffer(listFiles[0]);
    }
  };

  const uploadFileMilestonesMassive = async () => {
    setLoadErrors(true);
    let form_data = new FormData();
    form_data.append("base_file", baseFile);
    const { data, status } = await createMassiveMilestones(form_data);
    if (status === 200) {
      let text_success = `Los datos se están procesando, en unos minutos podrá ver la información cargada en la sección de hitos`;
      if (data?.count_student_ignore > 0) {
        text_success =
          text_success +
          `, se ignorarán ${data?.count_student_ignore} estudiantes`;
      }
      toast.success(text_success);
      onClose();
      setBaseFile(null);
    } else {
      if (status === 400) {
        if (data?.title === "student_ignore") {
          setBaseFile(null);
          setErrors(data?.message?.students);
        } else {
          setBaseFile(null);
          toast.error(data?.message);
          setErrors([{ nro_line: null, error_description: data?.message }]);
        }
      } else {
        setErrors([
          {
            nro_line: null,
            error_description: "Hay columnas con datos no rellenados",
          },
        ]);
      }
    }
    setLoadErrors(false);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: onDrop,
    maxSize: 5242880,
    accept: {
      "application/vnd.ms-excel": [".xlsx"],
    },
  });
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ flexGrow: "1" }}>
        <div>
          {
            "Importa cumplimiento de hitos masivamente para tus alumnos. No olvides escribir el rut correctamente"
          }
        </div>
        <div>
          <div
            style={{
              marginTop: "48px",
              fontSize: "var(--font-size-lg)",
              fontWeight: "500",
            }}
          >
            {t("use_following_format")}{" "}
            <a
              href={getGlobalConfig("milestone_format_file", "#")}
              style={{ color: "var(--call-in-action-second)" }}
            >
              {t("download_format")}
            </a>
          </div>
          <div style={{ marginTop: "20px" }}>
            <div className="container">
              <ContainerDropZone
                {...getRootProps({ className: "dropzone" })}
                style={{ padding: baseFile ? "0px" : "40px" }}
              >
                {baseFile && (
                  <div style={{ width: "100%" }}>
                    <div style={{ padding: "20px 30px 15px 30px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {baseFile.path}
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          style={{ color: "#E23232" }}
                          cursor="pointer"
                          onClick={() => {
                            setBaseFile(null);
                          }}
                        />
                      </div>
                    </div>
                    <DividerH />
                    <div style={{ padding: "50px 30px 70px 30px" }}>
                      <div
                        style={{
                          color: "#00B098",
                          fontSize: "var(--font-size-lg)",
                          lineHeight: "140%",
                        }}
                      >
                        Archivo cargado con éxito
                      </div>
                      <div>
                        <div
                          style={{
                            background: "#00B098",
                            height: "5px",
                            borderRadius: "50px",
                            marginTop: "4px",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
                {!baseFile && (
                  <>
                    <input {...getInputProps()} />
                    <p style={{ marginBottom: "4px", fontSize: "18px" }}>
                      {t("drag_excel")}
                    </p>
                    <p
                      style={{
                        marginBottom: "4px",
                        fontSize: "18px",
                      }}
                    >
                      {t("or")}
                    </p>
                    <NButton
                      sizeStyle={ButtonSize.small}
                      type="button"
                      onClick={open}
                      icon={<FontAwesomeIcon icon={faSearch} />}
                    >
                      {t("select_on_computer")}
                    </NButton>
                  </>
                )}
              </ContainerDropZone>
              <div style={{ marginTop: "24px" }}>
                <NBadge
                  text={t("milestones_max_rows_file", {
                    max_students: client_area_max_milestones,
                    students: translation("students", "", t("students")),
                  })}
                  customStyle={{
                    //background: "#EDF1F7",
                    background: "#ECF2FF",
                    color: "#334FFF",
                    fontSize: "10px",
                    padding: "6px",
                    borderRadius: "64px",
                    textTransform: "none",
                  }}
                />
              </div>

              {/* {errors.hasOwnProperty("base_file") && (
              <div style={{ marginTop: "15px", color: "#E23232" }}>
                * Falta cargar el archivo, es requerido
                <NIcon
                  iconname="exclamationCircle"
                  style={{ marginLeft: "4px" }}
                ></NIcon>
              </div>
            )} */}
            </div>
          </div>
        </div>
        {loadErrors && (
          <div style={{ marginTop: "20px" }}>
            <NSpinnerPoints />
          </div>
        )}
        {errors.length > 0 && (
          <div
            style={{
              background: "#FFEBEB",
              borderRadius: "8px",
              marginTop: "20px",
            }}
          >
            <NAccordionError
              onSelect={(item) => {
                setSelectMenu(item);
              }}
            >
              <Card>
                <ToggleHeader
                  eventKey="0"
                  title={"Hay errores detectados"}
                  selectMenu={selectMenu}
                />
                <Accordion.Collapse eventKey="0">
                  <Card.Body style={{ background: "#FFEBEB" }}>
                    <div style={{ marginTop: "10px" }}>
                      Hemos registrado errores{" "}
                      {errors.filter((item) => item.nro_line !== null).length >
                      0
                        ? "en las siguientes filas:"
                        : "."}{" "}
                      <br />
                      <strong>
                        {errors.map((item) => item.nro_line).join(", ")}
                      </strong>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      Puedes:
                      <ul>
                        <li>Volver a subir el archivo con las correcciones</li>
                      </ul>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      Detalle de errores:
                      <ul>
                        {errors.map((item, key) => (
                          <li key={`${key}-error`}>
                            {item.nro_line ? `Fila ${item.nro_line}: ` : ""}
                            {item.error_description}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </NAccordionError>
          </div>
        )}
      </div>
      <div className="footer mt-4">
        <RowItem>
          <Col xs="4">
            <NButton
              style={{ width: "100%", color: "#3B4567" }}
              transparent
              onClick={() => {
                onClose();
              }}
            >
              {_.upperFirst(t("cancel"))}
            </NButton>
          </Col>
          <Col xs="8">
            <NButton
              style={{ width: "100%" }}
              color={"second"}
              onClick={uploadFileMilestonesMassive}
              disabled={baseFile ? false : true}
            >
              {_.upperFirst(t("save"))}
            </NButton>
          </Col>
        </RowItem>
      </div>
    </div>
  );
};
export default FormMilestonesMassive;
const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};
const ContainerDropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 40px;
  border-width: 2px;
  border-radius: 10px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  // background-color: #fafafa;
  color: black;
  font-size: var(--font-size-lg);
  outline: none;
  transition: border 0.24s ease-in-out;
`;
const NAccordionError = styled(Accordion)`
  .card-header {
    background: #ffebeb !important;
    font-weight: 600;
  }
  .card {
    border: 0px;
  }
`;
