import React from "react";
import NBadge from "components/NBadge";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
const BadgeDateDropdown = ({
  text,
  isDisabled,
  dateSelected = null,
  label = "",
  onSelect = () => {},
}) => {
  const CustomToggle = React.forwardRef(
    ({ value, onClick, className }, ref) => (
      <div
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        style={{ marginRight: "10px" }}
      >
        <NBadge
          text={<div>{text}</div>}
          customStyle={{
            padding: "10px",
            borderRadius: "50px",
            fontSize: "14px",
            background: "#D9E1F2",
            color: "#2A2B2E",
            cursor: "pointer",
          }}
          colorIcon={"#9CABC2"}
          icon={<FontAwesomeIcon icon={faCaretDown} />}
          positionIcon={"right"}
          //disabled={disabled}
        />
      </div>
    )
  );
  const [startDatex, endDate] = dateSelected;
  return (
    <div>
      <DatePicker
        startDate={startDatex}
        endDate={endDate}
        onChange={onSelect}
        customInput={<CustomToggle />}
        dateFormat="yyyy-MM-dd"
        selectsRange={true}
        maxDate={new Date()}
      />
    </div>
  );
};
export default BadgeDateDropdown;
