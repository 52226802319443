import React from "react";
import StudentDetailV2 from "./student_detail_v2";
import StudentDetailV3 from "./student_detail_v3";
import { getGlobalConfig } from "utils";

const StudentDetail = () => {
  const version3 = getGlobalConfig("enable_student_version_3", false);
  return version3 ? <StudentDetailV3 /> : <StudentDetailV2 />;
};

export default StudentDetail;
