import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalContactInfo from "containers/Task/detail/Modals/ModalContactInfo";
import { ContactInfo } from "models";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editPersonProfile } from "services/crmService";
import { fetchPersonsProfile } from "store/actions/async/crm-async.action";
import { updateTaskStudentInfo } from "store/actions/crm.actions";
import { updateStudentInfo } from "store/actions/student.actions";

const EditContactModal = () => {
  const dispatch = useDispatch();
  const [showModalContact, setShowModalContact] = useState(null);
  const [person, setPerson] = useState(null);
  const [isSaving, setSaving] = useState(false);
  const { personsProfile } = useSelector((state) => state.crm);

  useEffect(() => {
    if (personsProfile) {
      let contacts = [];

      if ("contactinfo_set" in personsProfile) {
        contacts = new ContactInfo(personsProfile.contactinfo_set).getList();
      }

      const auxProfile = { ...personsProfile, contactinfo_set: contacts };
      setPerson(auxProfile);
    }
  }, [personsProfile]);
  const updateStudent = ({ key, value }) => {
    const auxPerson = { ...person, [key]: value };
    setPerson(auxPerson);
  };

  const handleSaveChanges = async () => {
    const finalPerson = { ...person };
    let error = false;
    const contacts = [...finalPerson.contactinfo_set].map((c) => {
      if (c.error && !error) {
        error = true;
      }
      return c.deserialize();
    });
    if (!error) {
      finalPerson.contactinfo_set = contacts;

      setSaving(true);
      const payload = {
        studentId: finalPerson.id,
        payload: {
          first_name: finalPerson.first_name,
          last_name: finalPerson.last_name,
          //  birth_date: moment(studentBirthDate).format("YYYY-MM-DD"),
          gender: finalPerson.gender,
          contactinfo_set: finalPerson.contactinfo_set,
        },
      };

      const { status } = await editPersonProfile(payload);

      if (status === 200) {
        dispatch(updateTaskStudentInfo(finalPerson));
        dispatch(updateStudentInfo(finalPerson));
        setSaving(false);
        dispatch(fetchPersonsProfile(finalPerson.id));
        setShowModalContact(false);
        // notifyClose();
      } else {
        toast.error("Error!, no se pudo realizar la actualización");
      }
    } else {
      toast.error("Error en el formato ingresado");
    }
  };
  return (
    <div style={{ display: "contents" }}>
      {person && (
        <ModalContactInfo
          show={showModalContact}
          contacts={person ? person.contactinfo_set : []}
          onHide={() => {
            setShowModalContact(false);
          }}
          onUpdateStudent={updateStudent}
          callSave={handleSaveChanges}
          isSaving={isSaving}
        />
      )}
      <FontAwesomeIcon
        icon={faPencil}
        style={{ color: "#334FFF", cursor: "pointer", fontSize: "18px" }}
        onClick={() => {
          setShowModalContact(true);
        }}
      />
    </div>
  );
};
export default EditContactModal;
