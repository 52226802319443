import React, { useCallback, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { TextError, NButton } from "components/Nimbiv2";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { Formik } from "formik";
import { Col, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { enterActionPlan } from "services/student.service";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchEnterableActionPlans } from "store/actions/async/student-async.actions";
import { RowItem } from "components/Nimbiv2/styled";
import { fetchListMomentReason } from "services/momentService";
import { fetchStudentCourses } from "store/actions/async/crm-async.action";
const ModalEnterActionPlan = ({
  show = false,
  setShow = () => {},
  selectTracking,
  momentsActive,
  setMomentsActive,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [reason, setReason] = useState(null);
  const [loadingReason, setLoadingReason] = useState(false);
  const dispatch = useDispatch();
  const { enterable_action_plans } = useSelector((state) => state.student);
  const validate = (values) => {
    const errors = {};
    if (!values.action_plan) {
      errors.action_plan = t("selection_required");
    }

    return errors;
  };
  const getListReason = async (value) => {
    if (value) {
      setLoadingReason(true);
      const { data } = await fetchListMomentReason({ moment_id: value });
      setReason(data);
      setLoadingReason(false);
    } else {
      setReason(null);
    }
  };

  const onFormSubmit = useCallback(
    async (values) => {
      setLoading(true);
      values["student_tracking_id"] = selectTracking.student_tracking_id;
      const { data, status } = await enterActionPlan(id, values);
      if (status === 200) {
        toast.success("Acción realizada exitosamente");
        let moments = { ...momentsActive };
        moments[selectTracking.student_tracking_id] = data;
        setMomentsActive(moments);
        dispatch(
          fetchEnterableActionPlans(id, {
            student_tracking_id: selectTracking.student_tracking_id,
          })
        );
        dispatch(fetchStudentCourses(id));
        setCheck(false);
        setShow(false);
        setLoading(false);
      } else {
        toast.error("Error en la acción solicitada");
      }
      setLoading(false);
    },
    [selectTracking, id, dispatch, momentsActive, setMomentsActive, setShow]
  );
  return (
    <ModalDesign
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "0px" }}>
        <Modal.Title className="h4">{t("enter_action_plan")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: "40px" }}>
          {t("select_action_plan_enter")}
        </div>
        <Formik initialValues={{}} validate={validate} onSubmit={onFormSubmit}>
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            values,
          }) => (
            <div>
              <Form.Control
                as="select"
                name="action_plan"
                onChange={(event) => {
                  handleChange(event);
                  let value = event.target.value;
                  getListReason(value);
                  setFieldValue("reason_admission", null, false);
                }}
              >
                <option value="">---{_.upperFirst(t("select"))}---</option>
                {enterable_action_plans &&
                  enterable_action_plans.map((item, key) => (
                    <option key={`moment-derive-${key}`} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Control>
              <TextError
                text={errors["action_plan"]}
                marginTop="5px"
                icon={faExclamationCircle}
              />
              {reason && reason?.length > 0 && (
                <>
                  <strong>{t("select_reason_admission")}</strong>
                  <Form.Control
                    as="select"
                    name="enter_reason_id"
                    onChange={handleChange}
                    disabled={loadingReason || !reason}
                  >
                    <option value="">
                      --- {_.upperFirst(t("select_reason_admission"))} ---
                    </option>
                    {reason &&
                      reason.map((item, key) => (
                        <option key={`key-reason-${item.id}`} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Control>
                  <TextError
                    text={errors["enter_reason_id"]}
                    marginTop="5px"
                    icon={faExclamationCircle}
                  />
                </>
              )}
              <div style={{ marginTop: "40px", marginBottom: "20px" }}>
                <Form.Check
                  id={`confirm-enter-action-plan`}
                  name={`confirm-enter-action-plan`}
                  type="checkbox"
                  data-key="confirmEnterActionPlan"
                  label={t("confirm_enter_action_plan")}
                  checked={check}
                  onChange={(event) => {
                    setCheck(event.target.checked);
                  }}
                  disabled={false}
                  custom
                />
              </div>
              <RowItem>
                <Col xs="6">
                  <NButton
                    style={{
                      marginTop: "10px",
                      float: "right",
                      width: "100%",
                    }}
                    outlined
                    onClick={() => {
                      setShow(false);
                    }}
                    disabled={loading}
                  >
                    {_.upperFirst(t("cancel"))}
                  </NButton>
                </Col>
                <Col xs="6">
                  <NButton
                    style={{
                      marginTop: "10px",
                      float: "right",
                      width: "100%",
                    }}
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={loading || !(values.action_plan && check)}
                  >
                    {_.upperFirst(t("enter"))}
                  </NButton>
                </Col>
              </RowItem>
            </div>
          )}
        </Formik>
      </Modal.Body>
    </ModalDesign>
  );
};
export default ModalEnterActionPlan;

const ModalDesign = styled(Modal)`
  .modal-header {
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 0px;
  }
  .modal-body {
    padding-top: 8px;
    font-size: 16px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .modal-content {
    border-radius: 8px;
  }
`;
