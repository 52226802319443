import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import {
  CardNimbi,
  DividerH,
  IconContainer,
  RowItem,
} from "components/Nimbiv2/styled";
import { Card, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import { ToggleHeader } from "components/Nimbiv2/NAccordion";
import { CardBody } from "components/Nimbiv2/CardGeneral";
import { ContainerEmpty } from "components/Nimbiv2";
import Icons from "./icons";
import colors from "utils/risk";
import { useTranslation } from "react-i18next";
import { applyTextType, getGlobalConfig } from "utils";
import { useTranslationLocal } from "hooks";
import { NBadge } from "components";
import EmptyRiskSVG from "assets/images/EmptyRiskSVG";
import styled from "styled-components";
import Collapse from "react-bootstrap/Collapse";

const PageRisk = ({ version }) => {
  const [selectMenu, setSelectMenu] = useState([]);
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const { risk } = useSelector((state) => state.student);

  useEffect(() => {
    let data = [];
    if (version === 3) {
      if (risk && risk?.risk_detail) {
        risk.risk_detail.map((item) => {
          data.push(item.factor);
          return null;
        });
      }
      setSelectMenu(data);
    }
  }, [risk, version]);

  const get_percentage = (value, showScore = true, showGreaterZero = false) => {
    var color = "rgb(69, 83, 98)"; // value = 0
    if (value > 0) color = "rgb(226, 50, 50)";
    else if (value < 0) color = "rgb(15, 205, 179)";
    if (getGlobalConfig("circle_percentage_shapley_values", false)) {
      return `
      <span style="
    background: ${color};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
">
    
</span>
      `;
    }
    value *= 100;
    if (showGreaterZero && value <= 0) {
      return "";
    }
    if (!showScore) {
      return "";
    }
    return `
      <span style="color: ${color}; margin-left: 5px;">
        ${value.toFixed(1)}%
      </span>
    `;
  };
  const htmlTemplate = (item) => {
    const scriptMarkdown = item.risk_description;
    return (
      // eslint-disable-next-line react/no-danger
      <div dangerouslySetInnerHTML={{ __html: scriptMarkdown }} />
    );
  };

  return (
    <>
      <CardNimbi padding={version === 3 ? "0px" : "24px"}>
        {(!risk || risk?.risk_score === null || !risk?.risk_category) && (
          <div className="mb-4">
            <ContainerEmpty
              text={"Aún no hay datos de riesgo para mostrar"}
              version="2"
              image={<EmptyRiskSVG />}
              description={
                <div>
                  Al momento de empezar a gestionar el alumno, podrás ver todos{" "}
                  <br />
                  los factores de riesgo en esta sección
                </div>
              }
            />
          </div>
        )}
        {risk && risk?.risk_category && risk?.risk_score !== null && (
          <RowItem>
            <Col lg="4" sm="12">
              <div style={{ background: "#F8FBFF", padding: "17px 22px" }}>
                <IconContainer
                  colorBackground={colors[risk.risk_category]["soft"]}
                >
                  <FontAwesomeIcon
                    icon={faFlag}
                    color={colors[risk.risk_category]["bold"]}
                  ></FontAwesomeIcon>
                </IconContainer>
                <div style={{ marginTop: "12px", fontWeight: "bold" }}>
                  {translation("risk_level", "startCase", t("risk_level"))}
                </div>
                <div
                  style={{
                    marginTop: "-10px",
                    fontWeight: "bold",
                    color: colors[risk.risk_category]["bold"],
                    fontSize: "56px",
                  }}
                >
                  {risk.risk_score}
                  {typeof risk.risk_score === "string" ? "" : "%"}
                </div>
                <div
                  style={{
                    fontSize: "var(--font-size-md)",
                    marginTop: "-15px",
                  }}
                >
                  {_.upperFirst(translation("risk"))}{" "}
                  <strong>{risk.risk_category}</strong>
                </div>
                {risk?.risk_tag && risk?.risk_tag !== "" && (
                  <div>
                    <NBadge
                      text={risk?.risk_tag}
                      customStyle={{
                        marginTop: "19px",
                        padding: "5px 18px",
                        borderRadius: "64px",
                      }}
                      backgroundColor="#C54646"
                      fontColor={"#FFFFFF"}
                      big
                    ></NBadge>
                  </div>
                )}
              </div>
            </Col>
            <Col lg="8" sm="12">
              <AccordionDesign
              // onSelect={(item) => {
              //   setSelectMenu(item);
              // }}
              >
                {risk.risk_detail?.map((item) => (
                  <div key={item.factor}>
                    <Card>
                      <ToggleHeader
                        eventKey={item.factor}
                        title={`${item.factor} ${
                          get_percentage(item.score, true, true) !== ""
                            ? "|"
                            : ""
                        } ${get_percentage(item.score, true, true)}`}
                        selectMenu={selectMenu}
                        icon={_.get(Icons, item.icon, faExclamationTriangle)}
                        flexTitle={true}
                        onClick={() => {
                          if (!selectMenu?.includes(item.factor)) {
                            let data = [...selectMenu];
                            data.push(item.factor);
                            setSelectMenu(data);
                          } else {
                            let data = selectMenu.filter((i) => {
                              return i !== item.factor;
                            });
                            setSelectMenu(data);
                          }
                        }}
                      />
                      <Collapse in={selectMenu?.includes(item.factor)}>
                        <CardBody style={{ padding: "11px 11px 11px 65px" }}>
                          {((item &&
                            item.subfactors &&
                            item.subfactors.length === 0) ||
                            (item && !item.subfactors)) && (
                            <div>{t("no-sub-factor-negatively")}</div>
                          )}
                          {item &&
                            item.subfactors &&
                            item.subfactors.map((subfactor) => (
                              <div
                                key={subfactor.name}
                                className="d-flex"
                                style={{
                                  fontSize: "var(--font-size-md)",
                                  marginTop: "8px",
                                }}
                              >
                                <div className="mr-auto">
                                  <strong>
                                    {subfactor.name}
                                    {subfactor.hasOwnProperty("value")
                                      ? ":"
                                      : ""}
                                  </strong>{" "}
                                  {subfactor.hasOwnProperty("value") &&
                                    applyTextType(
                                      subfactor.value,
                                      subfactor.format,
                                      null,
                                      null,
                                      t("no_information")
                                    )}
                                </div>
                                <div
                                  style={{
                                    fontWeight: "500",
                                    color: "#E23232",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: get_percentage(
                                      subfactor.score,
                                      false,
                                      false
                                    ),
                                  }}
                                />
                              </div>
                            ))}
                        </CardBody>
                      </Collapse>
                    </Card>
                    <DividerH />
                  </div>
                ))}
              </AccordionDesign>
            </Col>
          </RowItem>
        )}
      </CardNimbi>
      {risk && risk?.risk_description && risk?.risk_description !== "" && (
        <CardNimbi padding={version === 3 ? "0px" : "24px"} className="mt-2">
          {htmlTemplate(risk)}
        </CardNimbi>
      )}
    </>
  );
};

export default PageRisk;

export const AccordionDesign = styled.div`
  .card {
    border-radius: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }
  .card-header {
    background-color: white;
    border-bottom: 0px;
    color: #2a2b2e;
    font-weight: 500;
    padding: 12px 20px 12px 0px;
  }
`;
