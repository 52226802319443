export const GET_TASK_LIST = "GET_TASK_LIST";
export const SET_TASK_LIST = "SET_TASK_LIST";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const IS_GETTING_DATA = "IS_GETTING_DATA";
export const LOAD_STUDENT_RESPONSES = "LOAD_STUDENT_RESPONSES";
export const SET_STUDENT_RESPONSES = "SET_STUDENT_RESPONSES";
export const GET_STUDENT_RESPONSES = "GET_STUDENT_RESPONSES";
export const SET_CALL_RESULTS = "SET_CALL_RESULTS";
export const GET_CALL_RESULTS = "GET_CALL_RESULTS";
export const SAVE_TASK = "SAVE_TASK";
export const GETTING_GROUPED_TASKS = "GETTING_GROUPED_TASKS";
export const SET_GROUPED_TASKS = "SET_GROUPED_TASKS";
export const SET_ROW_INFO = "SET_ROW_INFO";
export const SET_PERSON_TASK_HISTORY = "SET_PERSON_TASK_HISTORY";
export const SET_PERSON_HISTORY = "SET_PERSON_HISTORY";
export const UNGROUP_TASKS = "UNGROUP_TASKS";
export const EXCLUDE_STUDENT_FROM_TASKS = "EXCLUDE_STUDENT_FROM_TASKS";
export const RESTORE_TASKS = "RESTORE_TASKS";
export const GET_MOMENTS = "GET_MOMENTS";
export const SET_MOMENTS = "SET_MOMENTS";
export const GET_CAMPUS = "GET_CAMPUS";
export const SET_CAMPUS = "SET_CAMPUS";
export const MARK_MOMENT = "MARK_MOMENT";
export const GET_USED_TAGS = "GET_USED_TAGS";
export const SET_USED_TAGS = "SET_USED_TAGS";
export const SET_PERSONS_PROFILE = "SET_PERSONS_PROFILE";
export const SET_PERSON_TASKS = "SET_PERSON_TASKS";
export const TOGGLE_PENDING = "TOGGLE_PENDING";
export const UPDATE_TASK_STUDENT_INFO = "UPDATE_TASK_STUDENT_INFO";
export const TOGGLE_TAG_STATUS = "TOGGLE_TAG_STATUS";
export const TOGGLE_ACTION_ROW = "TOGGLE_ACTION_ROW";
export const TOGGLE_DISABLE_TASK = "TOGGLE_DISABLE_TASK";
export const REMOVE_TASK = "REMOVE_TASK";
export const GET_COURSES = "GET_COURSES";
export const SET_COURSES = "SET_COURSES";
export const TOGGLE_CLEAR_FILTER = "TOGGLE_CLEAR_FILTER";
export const GET_TEMPLATES = "GET_TEMPLATES";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const GET_LIST_CAMPUS = "GET_LIST_CAMPUS";
export const SET_LIST_CAMPUS = "SET_LIST_CAMPUS";
export const SET_STUDENT_COURSES = "SET_STUDENT_COURSES";
export const SET_STUDENT_MOMENTS = "SET_STUDENT_MOMENTS";
export const SET_TAB_SELECT_TASK = "SET_TAB_SELECT_TASK";
export const CLEAN_LIST_COURSES = "CLEAN_LIST_COURSES";
export const CLEAN_TASK = "CLEAN_TASK";
export const GET_PERIODS = "GET_PERIODS";
export const SET_PERIODS = "SET_PERIODS";
export const SET_STUDENT_INITIAIVES = "SET_STUDENT_INITIAIVES";
export const SET_VISIBLE_GROUPED = "SET_VISIBLE_GROUPED";
export const CLEAN_TASK_GROUP = "CLEAN_TASK_GROUP";
export const GET_SURVEY_PERIODS = "GET_SURVEY_PERIODS";
export const SET_SURVEY_PERIODS = "SET_SURVEY_PERIODS";

export const isGettingData = () => ({ type: IS_GETTING_DATA, payload: null });

// getting all tasks
const defaultQueryParams = {
  page: 1,
  page_size: process.env.REACT_APP_DEFAULT_PAGESIZE,
  client_filters: null,
};
export const getTaskList = (queryParams = defaultQueryParams) => ({
  type: GET_TASK_LIST,
  payload: queryParams,
});
export const setTasksList = (results) => ({
  type: SET_TASK_LIST,
  payload: results,
});
export const registerError = ({ err = null, key = "" }) => ({
  type: REGISTER_ERROR,
  payload: { err, key },
});
export const setGroupedTasks = (data) => ({
  type: SET_GROUPED_TASKS,
  payload: data,
});
export const ungroupTasks = () => ({ type: UNGROUP_TASKS, payload: null });
export const setRowInfo = (info) => ({ type: SET_ROW_INFO, payload: info });
export const excludeStudentFromTasks = (studentId) => ({
  type: EXCLUDE_STUDENT_FROM_TASKS,
  payload: studentId,
});
export const restoreTasks = () => ({ type: RESTORE_TASKS, payload: null });
export const getMoments = () => ({ type: GET_MOMENTS, payload: null });
export const setMoments = (data) => ({ type: SET_MOMENTS, payload: data });
export const getCampus = () => ({ type: GET_CAMPUS, payload: null });
export const setCampus = (data) => ({ type: SET_CAMPUS, payload: data });
export const getListCampus = () => ({ type: GET_LIST_CAMPUS, payload: null });
export const setListCampus = (data) => ({
  type: SET_LIST_CAMPUS,
  payload: data,
});
export const getUsedTags = () => ({ type: GET_USED_TAGS, payload: null });
export const setUsedTags = (data) => ({ type: SET_USED_TAGS, payload: data });
export const setPersonsProfile = (data) => ({
  type: SET_PERSONS_PROFILE,
  payload: data,
});
export const setPersonTasks = (data) => ({
  type: SET_PERSON_TASKS,
  payload: data,
});
export const setPersonTasksHistory = (data) => ({
  type: SET_PERSON_TASK_HISTORY,
  payload: data,
});
export const setPersonEventHistory = (data) => ({
  type: SET_PERSON_HISTORY,
  payload: data,
});
export const markMoment = (momentId) => ({
  type: MARK_MOMENT,
  payload: momentId,
});
export const togglePending = ({ attr, sw }) => ({
  type: TOGGLE_PENDING,
  payload: { attr, sw },
});
export const toggleClearFilter = ({ attr, sw }) => ({
  type: TOGGLE_CLEAR_FILTER,
  payload: { attr, sw },
});
export const toggleActionRow = ({ taskId, lastTaskEvent }) => ({
  type: TOGGLE_ACTION_ROW,
  payload: { taskId, lastTaskEvent },
});
export const removeTask = (taskId) => ({ type: REMOVE_TASK, payload: taskId });
export const toggleDisableTask = ({ taskId, sw, assigned_to }) => ({
  type: TOGGLE_DISABLE_TASK,
  payload: { taskId, sw, assigned_to },
});
export const updateTaskStudentInfo = (student) => ({
  type: UPDATE_TASK_STUDENT_INFO,
  payload: student,
});
export const toggleTagStatus = ({ tagName, sw }) => ({
  type: TOGGLE_TAG_STATUS,
  payload: { tagName, sw },
});

// reset student responses
export const getStudentResponses = () => ({
  type: GET_STUDENT_RESPONSES,
  payload: null,
});

// student responses
export const setStudentResponses = (data) => ({
  type: SET_STUDENT_RESPONSES,
  payload: data,
});

// reset call results
export const getCallResults = (data) => ({
  type: GET_CALL_RESULTS,
  payload: null,
});

// call results
export const setCallResults = (data) => ({
  type: SET_CALL_RESULTS,
  payload: data,
});

// task
export const createTask = (row) => ({ type: SAVE_TASK, payload: row });

// courses
export const getCourses = () => ({ type: GET_COURSES, payload: null });
export const setCourses = (data) => ({ type: SET_COURSES, payload: data });

// action templates
export const setTemplateActions = ({ channel, data }) => ({
  type: SET_TEMPLATES,
  payload: { channel, data },
});

export const setTabSelectTask = (data) => ({
  type: SET_TAB_SELECT_TASK,
  payload: data,
});

export const setCourseStudents = (data) => ({
  type: SET_STUDENT_COURSES,
  payload: data,
});

export const setMomentStudents = (data) => ({
  type: SET_STUDENT_MOMENTS,
  payload: data,
});

export const setInitiativesStudents = (data) => ({
  type: SET_STUDENT_INITIAIVES,
  payload: data,
});

export const cleanListCourses = () => ({
  type: CLEAN_LIST_COURSES,
  payload: null,
});

export const cleanTasks = () => ({
  type: CLEAN_TASK,
  payload: null,
});

// periods
export const getPeriods = () => ({ type: GET_PERIODS, payload: null });
export const setPeriods = (data) => ({ type: SET_PERIODS, payload: data });
//
export const setVisibleGroup = (data) => ({
  type: SET_VISIBLE_GROUPED,
  payload: data,
});
export const cleanTaskGroup = () => ({
  type: CLEAN_TASK_GROUP,
  payload: null,
});

// surveys
export const getSurveyPeriods = () => ({ type: GET_SURVEY_PERIODS, payload: null });
export const setSurveyPeriods = (data) => ({ type: SET_SURVEY_PERIODS, payload: data });