import React from "react";
import _ from "lodash";
import TabZendesk from "components/ModalProfile/TabZendesk";
import { CardNimbi } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { getText } from "utils";

const PageZendeskStudent = ({ person }) => {
  const { t } = useTranslation();
  return (
    <CardNimbi height="100%" padding="16px 26px">
      <div className="d-flex justify-content-between">
        <div style={{ fontWeight: "600", fontSize: "20px" }}>
          {_.upperFirst(getText("zendesk", "startCase", t("portal_help")))}
        </div>
      </div>
      <TabZendesk person={person} />
    </CardNimbi>
  );
};
export default PageZendeskStudent;
