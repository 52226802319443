import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Dropdown, OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import {
  APP_MODULES,
  setDataToLocalStorage,
  getSessionFilters,
  cleanClientFilterValues,
  setSessionFilters,
  deleteLocalStorage,
  getDataFromLocalStorage,
} from "utils";
import { getTaskList } from "store/actions/async/crm-async.action";
import { searchStudentsInMoment } from "services/student.service";
import styles from "../Task.module.css";
import NDynamicFilters from "components/Nimbiv2/NDynamicFilters";
import {
  applyInitiativeFilter,
  applyMomentFilter,
  filterMomentSelectActive,
} from "utils/moments";
import {
  AnonymousContainer,
  CommentBlock,
  NTabs,
  RowItem,
} from "components/Nimbiv2/styled";
import { cleanAllFilter } from "store/actions/site.actions";
import { cleanTasks, setTabSelectTask } from "store/actions/crm.actions";
import { useTranslation } from "react-i18next";
import ContentLoader from "react-content-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";

const QUERY_PARAMS = {
  FILTER_KEY: "student_tracking__student__external_id",
  FIELD_NAME: "external_id",
  SEARCH_KEY: "searchTaskMoment",
  FILTER_SIMPLE_KEY: "simple_student__document_number",
  FIELD_SIMPLE_NAME: "document_number",
};

const TaskFilters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    pending: { list: isPendingList },
    pagination: { pageSize, count: totalRows },
    moments,
    tabTask: tab,
    singleLoads: { moments: singleMomentsLoaded },
    isMomentPending,
  } = useSelector((state) => state.crm);
  const { user } = useSelector((state) => state.auth);
  const {
    initiativesCall,
    singleLoads: { initiativesCall: singleInitiativeLoaded },
    pending: { initiativesCall: isPendingInitiatives },
    selectInitiativeCall,
  } = useSelector((state) => state.initiative);

  const momentList = filterMomentSelectActive(moments ?? []);
  const [initialSearch, setInitialSearch] = useState(
    getDataFromLocalStorage(QUERY_PARAMS.SEARCH_KEY, {})
  );

  const activeMoment = useMemo(() => {
    const results = moments.filter((moment) => moment.selected);
    return results.length > 0 ? results[0] : null;
  }, [moments]);

  // const isPendingSelect = useMemo(() => {
  //   return (
  //     (isPendingInitiatives && tab === "initiatives") ||
  //     (isMomentPending && tab === "moments")
  //   );
  // }, [isPendingInitiatives, isMomentPending, tab]);

  const activeInitiative = useMemo(() => {
    const results = initiativesCall.filter(
      (initiative) =>
        initiative.selected || initiative.id === selectInitiativeCall
    );
    return results.length > 0 ? results[0] : null;
  }, [initiativesCall, selectInitiativeCall]);

  const searchStudents = useCallback(
    async (query) => {
      let params = {};
      let payload = {};
      if (tab === "moments") {
        if (!activeMoment) return Promise.resolve([]);
        params = { page_size: 20 };
        payload = { moment_id: activeMoment.id, value: query };
      } else {
        if (!activeInitiative) return Promise.resolve([]);
        params = { page_size: 20 };
        payload = {
          moment_id: -1,
          initiative_id: activeInitiative.id,
          value: query,
        };
      }
      const response = await searchStudentsInMoment(params, payload);
      let results;
      if (response.data) results = response.data.results;
      return Promise.resolve(results ?? []);
    },
    [activeMoment, activeInitiative, tab]
  );

  const cleanSearchTemporal = (value) => {
    setInitialSearch({});
    let queryParams = value
      ? value
      : getSessionFilters(APP_MODULES.TASK.FILTER_NAME, true);
    delete queryParams[QUERY_PARAMS.FILTER_KEY];
    delete queryParams[QUERY_PARAMS.FILTER_SIMPLE_KEY];
    deleteLocalStorage(QUERY_PARAMS.SEARCH_KEY);
    setSessionFilters(APP_MODULES.TASK.FILTER_NAME, queryParams, true);
  };

  const getAllTasks = useCallback(
    (selected) => {
      let queryParams = getSessionFilters(APP_MODULES.TASK.FILTER_NAME, true);
      if (!_.isEmpty(selected)) {
        setDataToLocalStorage("avoidReloadTasks", true);
        const query = selected[QUERY_PARAMS.FIELD_NAME];
        const payload = {
          ...queryParams,
          ...{ page: 1, page_size: 10 },
        };
        if (tab === "initiatives") {
          payload["external"] = activeInitiative?.source_type === "external";
          if (activeInitiative?.source_type === "external") {
            payload[QUERY_PARAMS.FILTER_SIMPLE_KEY] =
              selected["document_number"];
          } else {
            payload[QUERY_PARAMS.FILTER_KEY] = query ?? "";
          }
        } else {
          payload[QUERY_PARAMS.FILTER_KEY] = query ?? "";
        }
        setSessionFilters(APP_MODULES.TASK.FILTER_NAME, payload);
        setDataToLocalStorage(QUERY_PARAMS.SEARCH_KEY, selected);
        dispatch(getTaskList(payload));
      } else {
        cleanSearchTemporal(queryParams);
        dispatch(getTaskList(queryParams));
      }
    },
    [dispatch, activeInitiative, tab]
  );

  const getAllResults = useCallback(() => {
    dispatch(getTaskList());
  }, [dispatch]);

  return (
    <>
      <RowItem style={{ marginTop: "24px" }}>
        <Col lg="12" style={{ paddingLeft: "0px" }}>
          <NTabs
            defaultActiveKey={tab}
            onSelect={(data) => {
              if (tab !== data) {
                localStorage.removeItem("filters");
                cleanClientFilterValues();
                dispatch(cleanAllFilter());
                setDataToLocalStorage("tabTaskSelected", { tab: data });
                dispatch(setTabSelectTask(data));
                dispatch(cleanTasks());
                if (data === "moments") {
                  if (momentList.length > 0) {
                    let reloadTasks = tab !== "moments" && data === "moments";
                    if (reloadTasks) {
                      cleanSearchTemporal();
                      applyMomentFilter(
                        dispatch,
                        momentList[0].id,
                        pageSize,
                        reloadTasks
                      );
                    }
                  }
                } else {
                  if (initiativesCall.length > 0) {
                    let reloadTasks =
                      tab !== "initiatives" && data === "initiatives";
                    if (reloadTasks) {
                      cleanSearchTemporal();
                      applyInitiativeFilter(
                        dispatch,
                        initiativesCall[0].pk,
                        pageSize,
                        reloadTasks,
                        true,
                        initiativesCall[0].source_type
                      );
                    }
                  }
                }
              }
            }}
          >
            <Tab
              eventKey="moments"
              title={_.upperFirst(t("action_plans"))}
            ></Tab>
            <Tab
              eventKey="initiatives"
              title={_.upperFirst(t("initiatives"))}
            ></Tab>
          </NTabs>
        </Col>
      </RowItem>

      <RowItem>
        <Col className={`mt-1 p-0`} md={12} style={{ paddingLeft: "0px" }}>
          {tab === "moments" && !isMomentPending && momentList.length === 0 && (
            <div style={{ width: "100%" }}>
              <CommentBlock>
                No hay planes de acción con tareas de llamada
              </CommentBlock>
            </div>
          )}
          <AnonymousContainer isAnonymous={user?.anonymous}>
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                {isPendingInitiatives && tab === "initiatives" && (
                  <SelectedPeding />
                )}
                {isMomentPending && tab === "moments" && <SelectedPeding />}
                {tab === "moments" &&
                  !isMomentPending &&
                  momentList.length > 0 && (
                    <SelectedMoment
                      momentList={momentList}
                      isPendingList={isPendingList}
                      pageSize={pageSize}
                    />
                  )}

                {tab === "initiatives" &&
                  !isPendingInitiatives &&
                  initiativesCall.length > 0 && (
                    <div>
                      <SelectInitiative
                        isPendingList={isPendingList}
                        initiativesCall={initiativesCall}
                        pageSize={pageSize}
                        cleanSearch={() => {
                          cleanSearchTemporal();
                        }}
                      />
                    </div>
                  )}
              </div>
            </div>
          </AnonymousContainer>
        </Col>
      </RowItem>

      <RowItem className={`align-items-center mt-3 ${styles.toolbarBlock}`}>
        <Col className={`mb-3 p-0 ${styles.tabContainer}`} md={12}>
          <NDynamicFilters
            panel={APP_MODULES.TASK.ID}
            isPendingList={
              isPendingList || !singleInitiativeLoaded || !singleMomentsLoaded
            }
            onGetSingleStudent={getAllTasks}
            onGetSuggestStudents={searchStudents}
            onLazySecondList={getAllResults}
            showPriorityFilter
            changeTab={tab}
            defaultSearch={initialSearch}
            dynamicFilter={tab === "moments"}
            idMomentFilter={moments.find((moment) => moment.selected)?.id}
            countItems={totalRows}
            isPending={isPendingList || isPendingInitiatives || isMomentPending}
            external={
              tab === "initiatives"
                ? activeInitiative?.source_type === "external"
                : false
            }
          ></NDynamicFilters>
        </Col>
      </RowItem>
    </>
  );
};

export default React.memo(TaskFilters);

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#!"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ color: "#455362" }}
  >
    {children} &#x25bc;
  </a>
));
const SelectedMoment = ({ momentList, isPendingList, pageSize }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const moments = useSelector((state) => state.crm.moments);

  const momentSelected = useMemo(() => {
    return moments.find((moment) => moment.selected) ?? null;
  }, [moments]);

  if (moments.length === 0) return null;

  return (
    <div
      style={{
        fontSize: "20px",
        lineHeight: "36px",
        fontWeight: "bold",
      }}
      className="d-flex align-items-center"
    >
      {/* {moments.find((moment) => moment.selected)?.name} */}
      {t("action_plan")}:
      <Dropdown
        disabled={isPendingList}
        style={{ marginLeft: "5px" }}
        onSelect={(value) => {
          const data = getDataFromLocalStorage("filters");
          if (data) {
            delete data["client_filters"];
            setDataToLocalStorage("filters", data);
          }
          cleanClientFilterValues();
          dispatch(cleanAllFilter());
          applyMomentFilter(dispatch, value, pageSize);
        }}
      >
        <Dropdown.Toggle as={CustomToggle}>
          {moments.find((moment) => moment.selected)?.name}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {moments &&
            momentList.map((moment, key) => {
              return (
                <Dropdown.Item
                  active={
                    moments.find((moment) => moment.selected)?.id === moment.id
                  }
                  eventKey={moment.id}
                  key={`option-action-${key}`}
                >
                  {moment.name}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
      <OverlayTrigger
        key="s"
        placement="top"
        overlay={<Tooltip id="tooltip-top">{t("go_to_action_plan")}</Tooltip>}
      >
        <FontAwesomeIcon
          icon={faArrowUpRightFromSquare}
          color="#334FFF"
          onClick={() => {
            history.push(`/planes-de-accion/${momentSelected?.id}/`);
          }}
          style={{ cursor: "pointer", marginLeft: "16px" }}
        />
      </OverlayTrigger>
    </div>
  );
};

const SelectInitiative = ({
  isPendingList,
  pageSize,
  cleanSearch = () => {},
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { initiativesCall, selectInitiativeCall } = useSelector(
    (state) => state.initiative
  );

  const initiativeSelected = useMemo(() => {
    return (
      initiativesCall.find(
        (initiative) =>
          initiative.selected || initiative.id === selectInitiativeCall
      ) ?? null
    );
  }, [initiativesCall, selectInitiativeCall]);

  if (initiativesCall.length === 0) return null;

  return (
    <div
      style={{
        fontSize: "20px",
        lineHeight: "36px",
        fontWeight: "bold",
      }}
      className="d-flex"
    >
      {/* {
        initiatives.find(
          (initiative) =>
            initiative.selected || initiative.id === selectInitiativeCall
        )?.name
        
      } */}
      {_.upperFirst(t("initiative"))}:
      <Dropdown
        disabled={isPendingList}
        style={{ minWidth: "180px", marginLeft: "5px" }}
        onSelect={(value) => {
          cleanSearch();
          const source_type = initiativesCall.find((initiative) => {
            return initiative.id.toString() === value;
          }).source_type;
          applyInitiativeFilter(
            dispatch,
            value,
            pageSize,
            true,
            true,
            source_type
          );
        }}
      >
        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
          {
            initiativesCall.find(
              (initiative) =>
                initiative.selected || initiative.id === selectInitiativeCall
            )?.name
          }
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {initiativesCall &&
            initiativesCall.map((item, key) => {
              return (
                <Dropdown.Item
                  eventKey={item.id}
                  key={`option-action-${key}`}
                  active={
                    initiativesCall.find(
                      (initiative) =>
                        initiative.selected ||
                        initiative.id === selectInitiativeCall
                    )?.id === item.id
                  }
                >
                  {item.name}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
        <OverlayTrigger
          key="s"
          placement="top"
          overlay={<Tooltip id="tooltip-top">{t("go_to_initiative")}</Tooltip>}
        >
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            className="ml-2"
            color="#334FFF"
            onClick={() => {
              history.push(`/campañas/${initiativeSelected?.pk}/`);
            }}
            style={{ cursor: "pointer", marginLeft: "16px" }}
          />
        </OverlayTrigger>
      </Dropdown>
    </div>
  );
};

const SelectedPeding = () => {
  return (
    <div>
      <ContentLoader speed={2} height={36}>
        <rect x="0" y="0" rx="0" ry="0" width="240" height="96" />
      </ContentLoader>
    </div>
  );
};
