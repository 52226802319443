import React from "react";
import { CardNimbi } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";

const CardExternalLinks = ({ personsProfile, extras, customLinks }) => {
  const { t } = useTranslation();
  return (
    <div>
      {(personsProfile?.link_panel ||
        personsProfile?.link_inquisidor ||
        personsProfile?.link_inquisidor ||
        personsProfile?.link_zendesk ||
        (extras && "url_external_list" in extras) ||
        (customLinks &&
          customLinks?.content &&
          customLinks?.content?.length > 0)) && (
        <CardNimbi padding="16px" height="auto" style={{ marginTop: "auto" }}>
          <div
            style={{
              fontSize: "v14px",
              paddingTop: "2px",
            }}
          >
            <div
              style={{
                flexWrap: "wrap",
                display: "flex",
                columnGap: "10px",
                rowGap: "2px",
              }}
            >
              <span style={{ fontWeight: "500" }}>{t("external_links")}: </span>
              {personsProfile && personsProfile.link_panel && (
                <div>
                  <a
                    href={personsProfile.link_panel}
                    style={{
                      color: "#334FFF",
                      fontWeight: "500",
                    }}
                  >
                    {t("view_dashboard")}
                  </a>
                </div>
              )}
              {personsProfile && personsProfile.link_inquisidor && (
                <div>
                  <a
                    href={personsProfile.link_inquisidor}
                    style={{
                      color: "#334FFF",
                      fontWeight: "500",
                    }}
                  >
                    {t("see_the_nquisitor")}
                  </a>
                </div>
              )}
              {personsProfile && personsProfile.link_zendesk && (
                <div>
                  <a
                    href={personsProfile.link_zendesk}
                    style={{
                      color: "#334FFF",
                      fontWeight: "500",
                    }}
                  >
                    {t("view_zendesk")}
                  </a>
                </div>
              )}
              {extras &&
                "url_external_list" in extras &&
                extras["url_external_list"].map((item, key) => {
                  return (
                    <div key={`key-url-extras${key}`}>
                      <a
                        href={item.url}
                        style={{
                          color: "#334FFF",
                          fontWeight: "500",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.title}
                      </a>
                    </div>
                  );
                })}
              {customLinks &&
                customLinks?.content &&
                customLinks?.content?.length > 0 &&
                customLinks?.content
                  ?.filter((item) => item?.value && item?.value !== "")
                  .map((item, key) => {
                    return (
                      <div key={`key-url-extras${key}`}>
                        <a
                          href={item.value}
                          style={{
                            color: "#334FFF",
                            fontWeight: "500",
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.label}
                        </a>
                      </div>
                    );
                  })}
            </div>
          </div>
        </CardNimbi>
      )}
    </div>
  );
};
export default CardExternalLinks;
