import React, { useMemo } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NBadge } from "components";
import { colorsBadge } from "utils/constants";
import {
  faArrowCircleUp,
  faArrowCircleDown,
  faMinusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

const RiskScoreTask = ({
  item,
  propertyRiskTag,
  property = "priority_score",
}) => {
  const { t } = useTranslation();

  const getRisk = useMemo(() => {
    return item[property] ?? null;
  }, [item, property]);

  const getRiskTag = useMemo(() => {
    return item[propertyRiskTag] ?? item?.student_tracking?.risk_tag;
  }, [item, propertyRiskTag]);

  if (getRisk === null) {
    return <div>{t("no_information")}</div>;
  }

  if (typeof getRisk === "number") {
    if (getRisk <= 100 && getRisk >= 70) {
      return (
        <NBadge
          outlined
          newColor={colorsBadge.dark.danger}
          icon={
            <FontAwesomeIcon
              icon={faArrowCircleUp}
              className="mr-1"
              style={{ fontSize: "11px" }}
            />
          }
          text={`${_.upperFirst(t("high"))} ${getRisk}%`}
          customStyle={{ borderRadius: "64px" }}
          big
          textTooltip={getRiskTag}
        />
      );
    }
    if (getRisk < 70 && getRisk >= 40) {
      return (
        <NBadge
          outlined
          newColor={colorsBadge.dark.warning}
          icon={
            <FontAwesomeIcon
              icon={faMinusCircle}
              className="mr-1"
              style={{ fontSize: "11px" }}
            />
          }
          text={`${_.upperFirst(t("medium"))} ${getRisk}%`}
          customStyle={{ borderRadius: "64px" }}
          big
          textTooltip={getRiskTag}
        />
      );
    }
    if (getRisk < 40 && getRisk >= 0) {
      return (
        <NBadge
          outlined
          newColor={colorsBadge.dark.success}
          icon={
            <FontAwesomeIcon
              icon={faArrowCircleDown}
              className="mr-1"
              style={{ fontSize: "11px" }}
            />
          }
          text={`${_.upperFirst(t("low"))} ${getRisk}%`}
          customStyle={{ borderRadius: "64px" }}
          big
          textTooltip={getRiskTag}
        />
      );
    }
  } else {
    if (getRisk === "Alto") {
      return (
        <NBadge
          outlined
          newColor={colorsBadge.dark.danger}
          icon={
            <FontAwesomeIcon
              icon={faArrowCircleUp}
              className="mr-1"
              style={{ fontSize: "11px" }}
            />
          }
          text={`${_.upperFirst(t("high"))}`}
          customStyle={{ borderRadius: "64px" }}
          big
          textTooltip={getRiskTag}
        />
      );
    }
    if (getRisk === "Medio") {
      return (
        <NBadge
          outlined
          newColor={colorsBadge.dark.warning}
          icon={
            <FontAwesomeIcon
              icon={faMinusCircle}
              className="mr-1"
              style={{ fontSize: "11px" }}
            />
          }
          text={`${_.upperFirst(t("medium"))}`}
          customStyle={{ borderRadius: "64px" }}
          big
          textTooltip={getRiskTag}
        />
      );
    }
    if (getRisk === "Bajo") {
      return (
        <NBadge
          outlined
          newColor={colorsBadge.dark.success}
          icon={
            <FontAwesomeIcon
              icon={faArrowCircleDown}
              className="mr-1"
              style={{ fontSize: "11px" }}
            />
          }
          text={`${_.upperFirst(t("low"))}`}
          customStyle={{ borderRadius: "64px" }}
          big
          textTooltip={getRiskTag}
        />
      );
    }
    if (getRisk !== "Bajo" && getRisk !== "Medio" && getRisk !== "Alto") {
      return (
        <NBadge
          outlined
          newColor={colorsBadge.dark.information}
          icon={
            <FontAwesomeIcon
              icon={faMinusCircle}
              className="mr-1"
              style={{ fontSize: "11px" }}
            />
          }
          text={getRisk}
          customStyle={{ borderRadius: "64px" }}
          big
          textTooltip={getRiskTag}
        />
      );
    }
  }
  return <div>{_.upperFirst(t("no_information_line"))}</div>;
};
export default RiskScoreTask;
