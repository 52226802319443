import React from "react";
import ContentLoader from "react-content-loader";

const CardStudentSkeletons = (props) => {
  return (
    <ContentLoader
      viewBox="0 0 420 91"
      height={91}
      width={420}
      speed={2}
      backgroundColor="transparent"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <circle cx="15" cy="15" r="15" />
      <rect x="40" y="0" rx="0" ry="0" width="200" height="30" />
      <rect x="0" y="50" rx="0" ry="0" width="300" height="15" />
      <rect x="0" y="70" rx="0" ry="0" width="300" height="15" />
    </ContentLoader>
  );
};

export default CardStudentSkeletons;
