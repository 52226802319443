import React, { useEffect, useState } from "react";
import _ from "lodash";
import { CardNimbi } from "components/Nimbiv2/styled";
import { Tasks } from "components";
import { useSelector } from "react-redux";
import { getText } from "utils";
import { useTranslation } from "react-i18next";

const PageProcessStudent = () => {
  const { t } = useTranslation();
  const [person, setPerson] = useState(null);
  const { personsProfile } = useSelector((state) => state.crm);
  const { user } = useSelector((state) => state.auth);
  //contents
  useEffect(() => {
    if (personsProfile) {
      setPerson(personsProfile);
    }
  }, [personsProfile]);
  return (
    <CardNimbi padding="16px 26px">
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: "24px" }}
      >
        <div style={{ fontWeight: "600", fontSize: "20px" }}>
          {_.upperFirst(getText("tracking_objects", "startCase", t("courses")))}
        </div>
      </div>
      <CardNimbi style={{ height: "auto" }} padding="0px">
        <Tasks
          data={person ? person.tasks : []}
          marginTop={false}
          isAnonymous={user?.anonymous}
        />
      </CardNimbi>
    </CardNimbi>
  );
};
export default PageProcessStudent;
