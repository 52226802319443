import i18n from "i18n";
import {
  GET_TASK_LIST,
  SET_TASK_LIST,
  GET_STUDENT_RESPONSES,
  SET_STUDENT_RESPONSES,
  REGISTER_ERROR,
  IS_GETTING_DATA,
  SET_GROUPED_TASKS,
  SET_ROW_INFO,
  UNGROUP_TASKS,
  EXCLUDE_STUDENT_FROM_TASKS,
  RESTORE_TASKS,
  SET_MOMENTS,
  MARK_MOMENT,
  GET_MOMENTS,
  SET_USED_TAGS,
  GET_CALL_RESULTS,
  SET_CALL_RESULTS,
  SET_PERSONS_PROFILE,
  SET_PERSON_TASKS,
  TOGGLE_PENDING,
  UPDATE_TASK_STUDENT_INFO,
  TOGGLE_TAG_STATUS,
  TOGGLE_ACTION_ROW,
  TOGGLE_DISABLE_TASK,
  REMOVE_TASK,
  SET_PERSON_TASK_HISTORY,
  SET_PERSON_HISTORY,
  GET_COURSES,
  SET_COURSES,
  TOGGLE_CLEAR_FILTER,
  SET_TEMPLATES,
  GET_CAMPUS,
  SET_CAMPUS,
  GET_LIST_CAMPUS,
  SET_LIST_CAMPUS,
  SET_STUDENT_COURSES,
  SET_STUDENT_MOMENTS,
  SET_TAB_SELECT_TASK,
  CLEAN_LIST_COURSES,
  CLEAN_TASK,
  GET_PERIODS,
  SET_PERIODS,
  SET_STUDENT_INITIAIVES,
  SET_VISIBLE_GROUPED,
  CLEAN_TASK_GROUP,
  GET_SURVEY_PERIODS,
  SET_SURVEY_PERIODS
} from "store/actions/crm.actions";

import {
  markMoment,
  enableAllRows,
  getDataFromLocalStorage,
  typesTaskEvent,
  isEmptyObject,
  getTextByLanguage,
} from "utils";
import { getTabSelected } from "utils/tasks";

const initialState = {
  isPending: false,
  isMomentPending: false,
  tabTask: getTabSelected() ?? "moments",
  tasks: [],
  auxTasks: [],
  groupedTasks: [],
  isGroupedVisible: false,
  selectedRow: null,
  personTaskHistory: [],
  moments: [],
  usedTags: [],
  studentResponseList: [],
  callResults: [],
  personsProfile: null,
  periods: null,
  personInitiatives: [],
  pending: {
    profile: true,
    persontasks: false,
    persontaskshistory: false,
    list: false,
    course: false,
    campus: false,
    studentResponseList: false,
    callResults: false,
    personhistory: false,
    personcourses: false,
    periods: false,
    groupedTasks: false,
  },
  pagination: {
    count: 0,
    next: null,
    previous: null,
    page: 1,
    pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  },
  courses: [],
  activeFilter: {
    course: false,
  },
  templates: {
    email: [],
    call: [],
  },
  campus: [],
  singleLoads: {
    moments: false,
    studentResponses: false,
    campus: false,
    usedTags: false,
    callResults: false,
    emailTemplates: false,
    callTemplates: false,
    listCampus: false,
  },
  personCourses: [],
  personMoments: [],
  listCampus: null,
};

const crmReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASK_LIST: {
      return {
        ...state,
        isPending: true,
        pending: { ...state.pending, list: true },
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          pageSize: Number(action.payload.page_size),
        },
      };
    }

    case SET_TASK_LIST: {
      const { count, next, previous, results } = action.payload;
      let auxPagination = { ...state.pagination };
      auxPagination = { ...auxPagination, count, next, previous };
      const session = getDataFromLocalStorage("user", null);

      results.tasks_data.map((task) => {
        let disableTask = false;

        // disable the task if it is currently processing by another user
        if (task.assigned_to && session) {
          disableTask = task.assigned_to.username !== session.username;
        }

        // eslint-disable-next-line no-param-reassign
        task.isOpened = false;
        // eslint-disable-next-line no-param-reassign
        task.isDisabled = disableTask;
        return null;
      });

      return {
        ...state,
        isPending: false,
        pending: { ...state.pending, list: false },
        pagination: auxPagination,
        tasks: results.tasks_data,
        auxTasks: results.tasks_data,
      };
    }

    case IS_GETTING_DATA: {
      return { ...state, isPending: true };
    }

    case GET_STUDENT_RESPONSES: {
      return {
        ...state,
        studentResponseList: [],
        pending: { ...state.pending, studentResponseList: true },
      };
    }

    case SET_STUDENT_RESPONSES: {
      let auxList = [...action.payload] ?? [];
      const pos = auxList.findIndex(
        (e) => String(e.response_text).toLowerCase() === "otro"
      );

      // move the element to the last position
      if (pos >= 0) {
        const pulled = auxList.splice(pos, 1)[0];
        auxList = [...auxList, pulled];
      }

      return {
        ...state,
        studentResponseList: auxList,
        singleLoads: { ...state.singleLoads, studentResponses: true },
        pending: { ...state.pending, studentResponseList: false },
      };
    }

    case GET_CALL_RESULTS: {
      return {
        ...state,
        callResults: [],
        pending: { ...state.pending, callResults: true },
      };
    }

    case SET_CALL_RESULTS: {
      let auxList = [...action.payload] ?? [];
      const pos = auxList.findIndex(
        (e) => String(e.code).toLowerCase() === "up_to_date"
      );

      // move the element to the last position
      if (pos >= 0) {
        const pulled = auxList.splice(pos, 1)[0];
        auxList = [...auxList, pulled];
      }

      return {
        ...state,
        callResults: auxList,
        singleLoads: { ...state.singleLoads, callResults: true },
        pending: { ...state.pending, callResults: false },
      };
    }

    case REGISTER_ERROR: {
      const auxPending = { ...state.pending };
      if (action.payload.key) auxPending[action.payload.key] = false;

      return {
        ...state,
        isPending: false,
        isMomentPending: false,
        pending: auxPending,
      };
    }

    case SET_GROUPED_TASKS: {
      const {
        results: { tasks_data },
      } = action.payload;
      const session = getDataFromLocalStorage("user", null);
      const resultItems = tasks_data.map((task) => {
        let disableTask = false;
        if (task.assigned_to && session) {
          disableTask = task.assigned_to.username !== session.username;
        }
        // eslint-disable-next-line no-param-reassign
        task.isOpened = false;
        // eslint-disable-next-line no-param-reassign
        task.isDisabled = disableTask;
        return task;
      });
      return {
        ...state,
        isPending: false,
        pending: { ...state.pending, list: false, groupedTasks: false },
        isGroupedVisible: true,
        groupedTasks: resultItems,
      };
    }

    case SET_VISIBLE_GROUPED: {
      const visible = action.payload;
      return {
        ...state,
        isGroupedVisible: visible,
      };
    }

    case UNGROUP_TASKS: {
      const auxTasks = [...state.tasks];
      auxTasks.map((task) => {
        // eslint-disable-next-line no-param-reassign
        task.isOpened = false;
        // eslint-disable-next-line no-param-reassign
        task.isDisabled = false;
        return null;
      });

      return {
        ...state,
        isPending: false,
        pending: { ...state.pending, list: false },
        isGroupedVisible: false,
        // groupedTasks: [],
        tasks: auxTasks,
      };
    }

    case SET_ROW_INFO: {
      return {
        ...state,
        selectedRow: action.payload,
      };
    }

    case SET_PERSONS_PROFILE: {
      return {
        ...state,
        pending: { ...state.pending, profile: false },
        personsProfile: {
          ...state.personsProfile,
          ...action.payload,
        },
      };
    }

    case SET_PERSON_TASKS: {
      return {
        ...state,
        pending: { ...state.pending, persontasks: false },
        personsProfile: {
          ...state.personsProfile,
          tasks: action.payload,
        },
      };
    }

    case SET_PERSON_TASK_HISTORY: {
      return {
        ...state,
        pending: { ...state.pending, persontaskshistory: false },
        personTaskHistory: [...action.payload] ?? [],
      };
    }

    case SET_PERSON_HISTORY: {
      return {
        ...state,
        pending: { ...state.pending, personhistory: false },
        personHistory: [...action.payload] ?? [],
      };
    }

    case EXCLUDE_STUDENT_FROM_TASKS: {
      const currentTasks = [...state.auxTasks];
      //const studentId = action.payload;
      // const filteredTasks = currentTasks.filter(
      //   (task) => task.student_tracking.id !== studentId
      // );

      return {
        ...state,
        tasks: currentTasks,
        pending: { ...state.pending, groupedTasks: true },
      };
    }

    case RESTORE_TASKS: {
      return {
        ...state,
        tasks: [...state.auxTasks],
      };
    }

    case SET_MOMENTS: {
      const newMoments = [...action.payload].map((m) => {
        const isEmpty = state.moments.length === 0;
        let isSelected = false;
        if (!isEmpty)
          isSelected = state.moments.find((item) => item.id === m.id).selected;
        return { ...m, selected: isSelected };
      });

      return {
        ...state,
        isMomentPending: false,
        singleLoads: { ...state.singleLoads, moments: true },
        moments: newMoments,
      };
    }

    case GET_CAMPUS: {
      return {
        ...state,
        pending: { ...state.pending, campus: true },
      };
    }

    case SET_CAMPUS: {
      const { results } = action.payload;
      return {
        ...state,
        campus: results,
        pending: { ...state.pending, campus: false },
        singleLoads: { ...state.singleLoads, campus: true },
      };
    }

    case SET_USED_TAGS: {
      const finalTags = [...action.payload.results].map((t) => {
        return { ...t, value: t.tag, selected: false };
      });

      return {
        ...state,
        usedTags: finalTags,
        singleLoads: { ...state.singleLoads, usedTags: true },
      };
    }

    case MARK_MOMENT: {
      return {
        ...state,
        moments: markMoment(action.payload, [...state.moments]),
      };
    }

    case GET_MOMENTS: {
      return {
        ...state,
        isMomentPending: true,
      };
    }

    case TOGGLE_PENDING: {
      let data = {};
      if (action && action.payload && action.payload.attr === "personcourses") {
        data = { personCourses: [] };
      }

      if (action && action.payload && action.payload.attr === "profile") {
        data = { personsProfile: null };
      }

      return {
        ...state,
        pending: { ...state.pending, [action.payload.attr]: action.payload.sw },
        ...data,
      };
    }

    case UPDATE_TASK_STUDENT_INFO: {
      const studenId = action.payload.id;
      const fullName = `${action.payload.first_name} ${action.payload.last_name}`;
      const auxTasks = [...state.tasks];
      const studentTasks = auxTasks.filter(
        (task) => task.student_tracking.id === studenId
      );
      studentTasks.map((task) => {
        // eslint-disable-next-line no-param-reassign
        task.student_tracking.full_name = fullName;
        return null;
      });

      return { ...state, tasks: auxTasks };
    }

    case TOGGLE_TAG_STATUS: {
      const auxTags = [...state.usedTags];

      if (action.payload.sw) {
        auxTags.map((tag) => {
          // eslint-disable-next-line no-param-reassign
          tag.selected =
            tag.tag === action.payload.tagName
              ? (tag.selected = action.payload.sw)
              : !action.payload.sw;

          return tag;
        });
      } else {
        const wantedTag = auxTags.find(
          (tag) => tag.tag === action.payload.tagName
        );
        wantedTag.selected = false;
      }

      return { ...state, usedTags: auxTags };
    }

    case TOGGLE_ACTION_ROW: {
      const { taskId, lastTaskEvent } = action.payload;
      const isEmptyTaskEvent = isEmptyObject(lastTaskEvent);
      const { isGroupedVisible } = { ...state };
      const auxTasks = isGroupedVisible
        ? [...state.groupedTasks]
        : [...state.tasks];
      //const auxTasks = [...state.tasks];
      const items = auxTasks.filter((task) => task.id === taskId);
      const item = items.length ? items[0] : null;

      if (!item) {
        return { ...state };
      }

      item.isOpened = !item.isOpened;
      const restoreAllStatus = enableAllRows(auxTasks);

      if (restoreAllStatus) {
        auxTasks.map((task) => {
          let finalStatus = false;

          // disable the task when is open in another browser
          if (!isEmptyTaskEvent) {
            const isSameTask = lastTaskEvent.taskId === task.id;
            finalStatus =
              isSameTask && lastTaskEvent.action === typesTaskEvent.TAKEN;
          }

          // eslint-disable-next-line no-param-reassign
          if (!task.assigned_to) {
            task.isDisabled = finalStatus;
          }
          return null;
        });
      } else {
        auxTasks.map((task) => {
          // eslint-disable-next-line no-param-reassign
          if (!task.assigned_to) {
            task.isDisabled = !task.isOpened;
          }
          return null;
        });
      }
      // for grouped tasks
      if (isGroupedVisible) {
        return { ...state, groupedTasks: auxTasks };
      }

      return {
        ...state,
        tasks: auxTasks,
        auxTasks,
      };
    }

    case TOGGLE_DISABLE_TASK: {
      const { taskId, sw, assigned_to } = action.payload;
      const auxTasks = [...state.tasks];
      const allTasksClosed = enableAllRows(auxTasks);
      // apply state change only when tasks are closed
      if (auxTasks.length) {
        const task = auxTasks.find((t) => t.id === taskId);
        if (task) {
          if (allTasksClosed) {
            task.isDisabled = sw;
          }
          if (sw) {
            task.assigned_to = assigned_to;
          } else {
            task.assigned_to = null;
          }
        }
      }
      // apply state change group
      const auxTasksGroup = [...state.groupedTasks];
      const allTasksClosedGroup = enableAllRows(auxTasksGroup);
      if (auxTasksGroup.length) {
        const task = auxTasksGroup.find((t) => t.id === taskId);
        if (task) {
          if (allTasksClosedGroup) {
            task.isDisabled = sw;
          }
          if (sw) {
            task.assigned_to = assigned_to;
          } else {
            task.assigned_to = null;
          }
        }
      }
      //--------------------

      return { ...state, tasks: auxTasks, groupedTasks: auxTasksGroup };
    }

    case REMOVE_TASK: {
      // remove item in task
      const auxTasks = [...state.tasks];
      const pos = auxTasks.findIndex((t) => t.id === action.payload);
      if (pos !== null) auxTasks.splice(pos, 1);

      // remove item in task group
      const auxTasksGroup = [...state.groupedTasks];
      const posGroup = auxTasksGroup.findIndex((t) => t.id === action.payload);
      if (posGroup !== null) auxTasksGroup.splice(posGroup, 1);
      //--------------------
      return { ...state, tasks: auxTasks, groupedTasks: auxTasksGroup };
    }

    case CLEAN_TASK_GROUP: {
      const auxTasksGroup = [...state.groupedTasks];
      const resultItems = auxTasksGroup.map((task) => {
        let disableTask = false;
        // eslint-disable-next-line no-param-reassign
        task.isOpened = false;
        // eslint-disable-next-line no-param-reassign
        task.isDisabled = disableTask;
        return task;
      });
      return { ...state, groupedTasks: resultItems };
    }

    case GET_COURSES: {
      return {
        ...state,
        pending: { ...state.pending, course: true },
      };
    }

    case SET_COURSES: {
      return {
        ...state,
        pending: { ...state.pending, course: false },
        courses: action.payload,
      };
    }

    case TOGGLE_CLEAR_FILTER: {
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          [action.payload.attr]: action.payload.sw,
        },
      };
    }

    case SET_TEMPLATES: {
      const { channel, data } = action.payload;
      const templateResource = `${channel}Templates`;
      return {
        ...state,
        templates: { ...state.templates, [channel]: data },
        singleLoads: { ...state.singleLoads, [templateResource]: true },
      };
    }

    case GET_LIST_CAMPUS: {
      return {
        ...state,
        pending: { ...state.pending, listCampus: true },
      };
    }

    case SET_LIST_CAMPUS: {
      const { results } = action.payload;
      return {
        ...state,
        listCampus: results,
        pending: { ...state.pending, listCampus: false },
        singleLoads: { ...state.singleLoads, listCampus: true },
      };
    }

    case SET_STUDENT_COURSES: {
      let all_courses =
        getTextByLanguage("all_courses", "startCase", i18n.language) ??
        "Todos los cursos";
      if (!all_courses) {
        all_courses = "Todos los cursos";
      }
      return {
        ...state,
        pending: { ...state.pending, personcourses: false },
        personCourses: [{ name: all_courses, id: 0 }, ...action.payload] ?? [
          { name: all_courses, id: 0 },
        ],
      };
    }
    case SET_STUDENT_MOMENTS: {
      let all_moments =
        getTextByLanguage("all_moments", "startCase", i18n.language) ??
        "Todos los planes de acción";
      if (!all_moments) {
        all_moments = "Todos los planes de acción";
      }
      return {
        ...state,
        pending: { ...state.pending, personmoments: false },
        personMoments: [{ name: all_moments, id: 0 }, ...action.payload] ?? [
          { name: all_moments, id: 0 },
        ],
      };
    }
    case SET_STUDENT_INITIAIVES: {
      let all_initiatives =
        getTextByLanguage("all_moments", "startCase", i18n.language) ??
        "Todas las iniciativas";
      if (!all_initiatives) {
        all_initiatives = "Todas las iniciativas";
      }
      return {
        ...state,
        pending: { ...state.pending, personinitiatives: false },
        personInitiatives: [
          { name: all_initiatives, id: 0 },
          ...action.payload,
        ] ?? [{ name: all_initiatives, id: 0 }],
      };
    }
    case SET_TAB_SELECT_TASK: {
      const data = action.payload;
      return {
        ...state,
        tabTask: data,
      };
    }

    case CLEAN_LIST_COURSES: {
      return {
        ...state,
        personCourses: [],
      };
    }

    case GET_PERIODS: {
      return {
        ...state,
        pending: { ...state.pending, periods: true },
      };
    }

    case SET_PERIODS: {
      const data = action.payload;
      return {
        ...state,
        periods: data,
        pending: { ...state.pending, periods: false },
      };
    }

    case GET_SURVEY_PERIODS: {
      return {
        ...state,
        pending: { ...state.pending, surveyPeriods: true },
      };
    }

    case SET_SURVEY_PERIODS: {
      const data = action.payload;
      return {
        ...state,
        surveyPeriods: data,
        pending: { ...state.pending, surveyPeriods: false },
      };
    }

    case CLEAN_TASK: {
      return {
        ...state,
        tasks: [],
        pagination: {
          count: 0,
          next: null,
          previous: null,
          page: 1,
          pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
        },
      };
    }

    default:
      return { ...state };
  }
};

export default crmReducers;
