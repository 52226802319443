import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faEnvelope,
  faCalendarAlt,
  faPhone,
  faDownload,
  faRotateRight,
  faMessageLines,
} from "@fortawesome/pro-regular-svg-icons";

import { IconCheck } from "assets/images";
import { NBadge } from "components";
import {
  AnonymousContainer,
  CardNimbi,
  DividerH,
  IconContainer,
  RowItem,
} from "components/Nimbiv2/styled";
import { applyInitiativeFilter } from "utils/moments";
import { useTranslationLocal } from "hooks";
import { NSpinnerPoints, NButton, NAuthor } from "components/Nimbiv2";
import { fetchMoments } from "store/actions/async/crm-async.action";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { colorsBadge } from "utils/constants";
import { getGlobalConfig } from "utils";
import FormRelaunchCampaign from "../FormRelaunchCampaign";
import { toast } from "react-toastify";
import { resendEmailInitiative } from "services/initiativeService";
import { get_values_range_number } from "utils/initiatives";

const boolValues = {
  1: "SI",
  0: "NO",
  True: "SI",
  False: "NO",
  undefined: "NO",
};
const PageInformation = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const [openForm, setOpenForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    initiative,
    pending: { initiative: isPending },
  } = useSelector((state) => state.initiative);
  const {
    moments,
    singleLoads: { moments: singleMomentsLoaded },
  } = useSelector((state) => state.crm, _.isEqual);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if ((moments || moments.length === 0) && !singleMomentsLoaded) {
      dispatch(fetchMoments());
    }
  }, [moments, dispatch, singleMomentsLoaded]);

  const page = {
    Email: {
      title: "mail_initiative",
      template: "emails_sent",
      namebutton: "mail",
      date: "mailing_date",
      detail: "emails_sent",
    },
    Call: {
      title: "call_initiative",
      template: "scheduled_call",
      namebutton: "pattern",
      date: "titleTime.Call",
      detail: "calls_made",
    },
    WhatsApp: {
      title: "whatsapp_initiative",
      template: "whatsapp_sent",
      namebutton: "whatsapp",
      date: "whatsapp_send_date",
      detail: "whatsapp_sent",
    },
    LMS: {
      title: "lms_initiative",
      template: "lms_sent",
      namebutton: "lms",
      date: "lms_send_date",
      detail: "lms_sent",
    },
  };

  // const InitiativeStatusColor = {
  //   active: "#3AC8AC",
  //   paused: "#E23232",
  //   completed: "#989DA5",
  //   draft: "#F6D42D",
  //   canceled: "#E23232",
  //   programmed: "#989DA5",
  // };

  // const InitiativeStatusColor = {
  //   active: colorsBadge.dark.success.background,
  //   paused: colorsBadge.dark.danger.background,
  //   completed: colorsBadge.dark.inactive.background,
  //   draft: colorsBadge.dark.warning.background,
  //   canceled: colorsBadge.dark.danger.background,
  //   programmed: colorsBadge.dark.inactive.background,
  // };
  const InitiativeStatusColor = {
    active: colorsBadge.light.success,
    paused: colorsBadge.light.danger,
    completed: colorsBadge.light.inactive,
    draft: colorsBadge.light.warning,
    canceled: colorsBadge.light.danger,
    programmed: colorsBadge.light.inactive,
  };

  const initiativeTypes = {
    Call: {
      template: "script_template",
      icon: faPhone,
    },
    Email: {
      template: "email_template",
      icon: faEnvelope,
    },
    WhatsApp: {
      template: "whatsapp_template",
      icon: faWhatsapp,
    },
    LMS: {
      template: "lms_template",
      icon: faMessageLines,
    },
  };
  const initiativeTypeName = useMemo(() => {
    return initiative?.initiative_type.name;
  }, [initiative]);

  const resendEmail = useCallback(async () => {
    setLoading(true);
    const { status } = await resendEmailInitiative(initiative.pk);
    if (status === 200) {
      toast.success("Correos reenviados");
    } else {
      toast.error("Error en el reenvio de correos");
    }
    setLoading(false);
  }, [initiative]);

  return (
    <div>
      {isPending && <NSpinnerPoints />}
      {!isPending && isPending !== null && initiative != null && (
        <RowItem>
          <Col
            lg="8"
            md="6"
            sm="12"
            style={{ paddingLeft: "0px" }}
            className="pr-0 pr-sm-2"
          >
            <CardNimbi padding="32px 24px">
              <strong style={{ fontSize: "var(--font-size-lg)" }}>
                {_.upperFirst(t("objetive"))}
              </strong>
              <div
                style={{ marginTop: "16px", fontSize: "var(--font-size-md)" }}
              >
                {initiative.objective}
              </div>
              {initiative.source_type === "external" && (
                <>
                  <strong
                    style={{
                      marginTop: "32px",
                      fontSize: "var(--font-size-lg)",
                    }}
                  >
                    {t("uploaded_file")}
                  </strong>
                  <div
                    style={{
                      marginTop: "16px",
                      fontSize: "var(--font-size-md)",
                      display: "flex",
                      color: "rgb(51, 79, 255)",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      style={{ marginRight: "6px", marginTop: "1px" }}
                    />
                    <a
                      style={{ color: "rgb(51, 79, 255)" }}
                      href={initiative.base_file}
                    >
                      {t("download_file")}
                    </a>
                  </div>
                </>
              )}
              {initiative.source_type !== "external" && (
                <>
                  <strong
                    style={{
                      marginTop: "32px",
                      fontSize: "var(--font-size-lg)",
                    }}
                  >
                    {t("criteria_used")}
                  </strong>
                  {initiative.client_filters && (
                    <div
                      style={{
                        marginTop: "8px",
                        fontSize: "var(--font-size-md)",
                      }}
                    >
                      {initiative.client_filters.map((item, key) => {
                        return (
                          <div
                            style={{ marginTop: "8px" }}
                            key={`client-filter-${key}`}
                          >
                            <img
                              src={IconCheck}
                              style={{ height: "19px", marginRight: "9px" }}
                              alt="image1"
                            />
                            {item.name}:{" "}
                            {item.type_component === "select" &&
                              initiative.clientfilter_values[
                                item.field
                              ].toString()}
                            {item.type_component === "boolean" &&
                              _.get(
                                boolValues,
                                initiative.clientfilter_values[item.field],
                                ""
                              )}
                            {item.type_component === "date" &&
                              initiative.clientfilter_values[
                                item.field
                              ].replace("++", " | ")}
                            {item.type_component === "range_number" && 
                              _.upperFirst(t("range_number_values", get_values_range_number(initiative.clientfilter_values[
                                item.field
                              ])))
                            }
                          </div>
                        );
                      })}
                      {initiative.client_filters.length === 0 && (
                        <div style={{ marginTop: "8px" }}>
                          {t("has_no_criteria")}
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
              {getGlobalConfig("active_email_with_higher_priority", false) &&
                initiative.source_type === "internal" &&
                initiativeTypeName === "Email" && (
                  <>
                    <strong
                      style={{
                        marginTop: "32px",
                        fontSize: "var(--font-size-lg)",
                      }}
                    >
                      {_.upperFirst(t("priority"))}
                    </strong>
                    <div
                      style={{
                        marginTop: "8px",
                        fontSize: "var(--font-size-md)",
                      }}
                    >
                      <div style={{ marginTop: "8px" }}>
                        {_.upperFirst(
                          initiative.email_with_higher_priority
                            ? t("institutional_email")
                            : t("personal_email")
                        )}
                      </div>
                    </div>
                  </>
                )}
              {getGlobalConfig("active_teams_initiative", false) && (
                <>
                  <strong
                    style={{
                      marginTop: "32px",
                      fontSize: "var(--font-size-lg)",
                    }}
                  >
                    {t("assigned_team")}
                  </strong>
                  <div
                    style={{
                      marginTop: "8px",
                      fontSize: "var(--font-size-md)",
                    }}
                  >
                    <div style={{ marginTop: "8px" }}>
                      {initiative.team?.name ?? "---"}
                    </div>
                  </div>
                </>
              )}
              {initiative.source_type !== "external" && (
                <>
                  <strong
                    style={{
                      marginTop: "32px",
                      fontSize: "var(--font-size-lg)",
                    }}
                  >
                    {translation("campus", "startCase") +
                      "/" +
                      translation("tracking_object", "startCase")}
                  </strong>
                  <AnonymousContainer isAnonymous={user?.anonymous} radius="6px">
                    <div
                      style={{
                        marginTop: "16px",
                        fontSize: "var(--font-size-md)",
                      }}
                    >
                      {initiative.campuses.map((item, key) => {
                        if (key === 0) {
                          return item.name;
                        }
                        return ", " + item.name;
                      })}
                      {initiative.tracking_objects.length > 0 &&
                        initiative.tracking_objects &&
                        ` - ${initiative.tracking_objects
                          .map((data, key) => {
                            if (key === 0) {
                              return data.name;
                            }
                            return data.name;
                          })
                          .join(", ")}`}
                      {initiative.campuses.length === 0 &&
                        initiative.tracking_objects.length === 0 && (
                          <div>-</div>
                        )}
                    </div>
                  </AnonymousContainer>
                </>
              )}

              {initiative.initiative_type.name === "Call" &&
                initiative.source_type !== "external" &&
                moments &&
                initiative.moments &&
                initiative.moments.length > 0 && (
                  <>
                    <strong
                      style={{
                        marginTop: "32px",
                        fontSize: "var(--font-size-lg)",
                      }}
                    >
                      {t("associated_action_plans_initiative")}:
                    </strong>
                    <AnonymousContainer
                      isAnonymous={user?.anonymous}
                      radius="6px"
                    >
                      <div
                        style={{
                          marginTop: "16px",
                          fontSize: "var(--font-size-md)",
                        }}
                      >
                        {moments
                          .filter((item) =>
                            initiative.moments.includes(item.id)
                          )
                          .map((item) => item.name)
                          .join(", ")}
                      </div>
                    </AnonymousContainer>
                  </>
                )}

              {initiative?.initiative_type.name === "Call" &&
                initiative?.student_responses &&
                initiative?.student_responses?.length > 0 && (
                  <>
                    <strong
                      style={{
                        marginTop: "32px",
                        fontSize: "var(--font-size-lg)",
                      }}
                    >
                      {"Respuesta de estudiantes asociados"}:
                    </strong>
                    <AnonymousContainer
                      isAnonymous={user?.anonymous}
                      radius="6px"
                    >
                      <div
                        style={{
                          marginTop: "16px",
                          fontSize: "var(--font-size-md)",
                        }}
                      >
                        {initiative.student_responses.map((item) => {
                          return <div>{item.response_text}</div>;
                        })}
                      </div>
                    </AnonymousContainer>
                  </>
                )}

              {getGlobalConfig("enable_send_all_contacts", false) &&
                initiative.initiative_type.name === "Email" &&
                initiative.source_type !== "external" && (
                  <>
                    <strong
                      style={{
                        marginTop: "32px",
                        fontSize: "var(--font-size-lg)",
                      }}
                    >
                      {_.upperFirst(t("send_all_student_contact"))}
                    </strong>
                    <AnonymousContainer
                      isAnonymous={user?.anonymous}
                      radius="6px"
                    >
                      <div
                        style={{
                          marginTop: "16px",
                          fontSize: "var(--font-size-md)",
                        }}
                      >
                        {initiative.send_all_contacts
                          ? _.upperFirst(t("active"))
                          : _.upperFirst(t("inactive"))}
                      </div>
                    </AnonymousContainer>
                  </>
                )}

              {initiative.initiative_type.name === "Call" &&
                initiative.call_form && (
                  <>
                    <strong
                      style={{
                        marginTop: "32px",
                        fontSize: "var(--font-size-lg)",
                      }}
                    >
                      {_.upperFirst(t("Cuestionario"))}
                    </strong>
                    <AnonymousContainer
                      isAnonymous={user?.anonymous}
                      radius="6px"
                    >
                      <div
                        style={{
                          marginTop: "16px",
                          fontSize: "var(--font-size-md)",
                          fontWeight: "bold",
                        }}
                      >
                        {initiative.call_form?.description}
                      </div>
                      <div
                        style={{
                          marginTop: "5px",
                          fontSize: "var(--font-size-md)",
                        }}
                      >
                        {initiative.call_form?.detail}
                      </div>
                    </AnonymousContainer>
                  </>
                )}

              <strong
                style={{ marginTop: "32px", fontSize: "var(--font-size-lg)" }}
              >
                {t(page[initiative.initiative_type.name].template)}
              </strong>
              <div style={{ marginTop: "16px" }}>
                <CardDesign>
                  <div className="mr-auto">
                    <strong style={{ fontSize: "var(--font-size-md)" }}>
                      {initiative[
                        initiativeTypes[initiative.initiative_type.name]
                          .template
                      ]?.name ?? ""}
                      {/* {initiative.initiative_type.name === "Call" &&
                        initiative.script_template && (
                          <>{initiative.script_template.name}</>
                        )}
                      {initiative.initiative_type.name === "Email" &&
                        initiative.email_template && (
                          <>{initiative.email_template.name}</>
                        )}
                      {initiative.initiative_type.name === "WhatsApp" &&
                        initiative.whatsapp_template && (
                          <>{initiative.whatsapp_template.name}</>
                        )} */}
                    </strong>
                    <div style={{ fontSize: "var(--font-size-small)" }}>
                      {initiative.initiative_type.name === "Call" && (
                        <>
                          {_.upperFirst(t("duration"))}{" "}
                          {moment(initiative.start_date).format("DD/MM")} -{" "}
                          {moment(initiative.end_date).format("DD/MM")}
                        </>
                      )}
                      {["Email", "WhatsApp", "LMS"].includes(
                        initiative.initiative_type.name
                      ) && (
                        <>
                          {t("shipping_date")}:{" "}
                          {moment(initiative.start_date).format("DD/MM")}
                        </>
                      )}
                    </div>
                  </div>
                  {/* <NButton outlined>
                    Ver {page[initiative.initiative_type.name].namebutton}
                  </NButton> */}
                </CardDesign>
              </div>
              <DividerH style={{ marginTop: "32px" }} />
              <div
                style={{
                  marginTop: "19px",
                }}
              >
                <NAuthor
                  photo_url={initiative.owner.photo_url}
                  full_name={initiative.owner.full_name}
                  role={initiative.owner.role}
                />
              </div>
            </CardNimbi>
          </Col>
          <Col
            lg="4"
            md="6"
            sm="12"
            style={{ paddingRight: "0px" }}
            className="pt-3 pt-md-0 pl-0 pl-sm-2"
          >
            <CardNimbi style={{ height: "auto" }} padding="32px 24px">
              <div className="d-flex">
                <div className="mr-auto"></div>
                <NBadge
                  text={_.upperFirst(t([initiative.status]))}
                  customStyle={{
                    borderRadius: "64px",
                  }}
                  backgroundColor={
                    InitiativeStatusColor[initiative.status].background
                  }
                  fontColor={InitiativeStatusColor[initiative.status].fontColor}
                />
              </div>
              <strong
                style={{ fontSize: "var(--font-size-md)", color: "#989DA5" }}
              >
                {t(page[initiative.initiative_type.name].title)} -{" "}
                {_.upperCase(t(initiative.source_type + "_detail"))}
              </strong>
              <strong style={{ fontSize: "26px", marginTop: "4px" }}>
                <AnonymousContainer isAnonymous={user?.anonymous} radius="6px">
                  {initiative.name}
                </AnonymousContainer>
              </strong>
              <div style={{ marginTop: "24px" }}>
                <div style={{ display: "flex" }}>
                  <IconContainer>
                    <FontAwesomeIcon icon={faGraduationCap} />
                  </IconContainer>

                  <div style={{ marginLeft: "14px" }}>
                    <strong style={{ marginTop: "16px" }}>
                      {t("Number of students", {
                        students: translation("students", "", t("students")),
                      })}
                    </strong>
                    <div
                      style={{
                        color: "#334FFF",
                        marginTop: "4px",
                        fontWeight: "bold",
                      }}
                    >
                      {initiative.students_amount}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "24px" }}>
                  <IconContainer>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </IconContainer>
                  <div style={{ marginLeft: "14px" }}>
                    <strong style={{ marginTop: "16px" }}>
                      {t(page[initiative.initiative_type.name].date)}
                    </strong>
                    <div style={{ marginTop: "4px" }}>
                      {moment(initiative.start_date).format("DD/MM/YYYY")}
                      {initiative.initiative_type.name === "Call" && (
                        <>
                          {" "}
                          -{" "}
                          {moment(initiative.end_date, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "24px" }}>
                  <IconContainer>
                    <FontAwesomeIcon
                      icon={initiativeTypes[initiativeTypeName].icon}
                    />
                  </IconContainer>
                  <div style={{ marginLeft: "14px" }}>
                    <strong style={{ marginTop: "16px" }}>
                      {t(page[initiative.initiative_type.name].detail)}
                    </strong>
                    <div style={{ marginTop: "4px" }}>
                      {["Email", "WhatsApp", "LMS"].includes(
                        initiative.initiative_type.name
                      ) && (
                        <strong>{initiative.associated_tasks.completed}</strong>
                      )}
                      {initiative.initiative_type.name === "Call" && (
                        <>
                          <strong style={{ color: "#334FFF" }}>
                            {initiative.associated_tasks.completed}
                          </strong>{" "}
                          /{" "}
                          {initiative.status === "programmed"
                            ? initiative.students_amount
                            : initiative.associated_tasks.all}{" "}
                          {
                            <strong>
                              (
                              {initiative.status === "programmed"
                                ? "0"
                                : initiative.associated_tasks.percentage ??
                                  "100"}
                              )%
                            </strong>
                          }
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {initiative.initiative_type.name === "Call" &&
                initiative.status === "active" && (
                  <>
                    <DividerH
                      style={{ marginTop: "32px", marginBottom: "32px" }}
                    />
                    <NButton
                      onClick={() => {
                        localStorage.removeItem("filters");
                        localStorage.removeItem("clientFilters");
                        applyInitiativeFilter(
                          dispatch,
                          initiative.pk,
                          10,
                          true,
                          false,
                          initiative.source_type
                        );
                        history.push("/tareas");
                      }}
                      color="second"
                    >
                      {t("Go to tasks")}
                    </NButton>
                  </>
                )}
              {initiative.status === "draft" && (
                <>
                  <DividerH
                    style={{ marginTop: "32px", marginBottom: "32px" }}
                  />
                  <NButton
                    outlined
                    onClick={() => {
                      history.push(`/campañas/${initiative.pk}/edit/`);
                    }}
                  >
                    {t("edit_initiative")}
                  </NButton>
                </>
              )}
              {initiative?.status === "completed" &&
                initiative?.initiative_type?.name === "Call" &&
                initiative?.not_called &&
                initiative?.not_called?.length > 0 && (
                  <>
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginBottom: "8px", marginTop: "30px" }}
                    >
                      Se incluirá a los{" "}
                      <span style={{ margin: "0px 4px", fontWeight: "600" }}>
                        {initiative?.not_called?.length}{" "}
                        {initiative?.not_called &&
                        initiative?.not_called?.length > 1
                          ? "alumnos"
                          : "alumno"}
                      </span>{" "}
                      sin respuesta
                    </div>
                    <NButton
                      positionIcon="left"
                      onClick={() => {
                        setOpenForm(true);
                      }}
                      icon={<FontAwesomeIcon icon={faRotateRight} />}
                    >
                      {"Relanzar campaña"}
                    </NButton>
                  </>
                )}
              {initiative?.status === "completed" &&
                initiative?.initiative_type?.name === "Email" &&
                initiative?.message_bounced !== null && (
                  <>
                    {!(initiative?.message_bounced === 0 || loading) && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ marginBottom: "8px", marginTop: "30px" }}
                      >
                        {initiative?.message_bounced > 1 && 
                        (<>
                          Se incluirá a los{" "}
                          <span style={{ margin: "0px 4px", fontWeight: "600" }}>
                            {initiative?.message_bounced}{" "}alumnos
                          </span>{" "}
                          con correos que rebotaron
                        </>)}
                        {initiative?.message_bounced <= 1 && 
                        (<>
                          Se incluirá a{" "}
                          <span style={{ margin: "0px 4px", fontWeight: "600" }}>
                            {initiative?.message_bounced}{" "}alumno
                          </span>{" "}
                          que su correo rebotó
                        </>)}
                      </div>
                    )}
                    <NButton
                      positionIcon="left"
                      onClick={() => {
                        resendEmail();
                      }}
                      icon={<FontAwesomeIcon icon={faRotateRight} />}
                      disabled={initiative?.message_bounced === 0 || loading}
                      loading={loading}
                      style={
                        initiative?.message_bounced === 0 || loading
                          ? { marginBottom: "8px", marginTop: "30px" }
                          : {}
                      }
                    >
                      {"Reenviar correos"}
                    </NButton>
                  </>
                )}
            </CardNimbi>
          </Col>
        </RowItem>
      )}
      <FormRelaunchCampaign
        open={openForm}
        onClose={() => {
          setOpenForm(false);
        }}
        initiative={initiative}
      ></FormRelaunchCampaign>
    </div>
  );
};

export default PageInformation;

const CardDesign = styled.div`
  background: linear-gradient(
      0deg,
      rgba(166, 196, 255, 0.2),
      rgba(166, 196, 255, 0.2)
    ),
    #ffffff;
  border-radius: 8px;
  padding: 11px 13px;
  display: flex;
`;
