import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NSpinnerPoints } from "components/Nimbiv2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faShare } from "@fortawesome/pro-regular-svg-icons";
import { RowItem } from "components/Nimbiv2/styled";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import CardIndicator from "components/Nimbiv2/CardIndicator";
import { useTranslationLocal } from "hooks";

const PageIndicatorLMS = () => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const {
    initiative,
    insights,
    pending: { initiative: isPending, insights: isPendingInsights },
  } = useSelector((state) => state.initiative);

  const total_email_send = {
    title: t("total_lms_emails_sent"),
    total: "0",
    description: t("lms_student_have_been_contacted", {
      students: translation("students", "startCase", t("students")),
    }),
    icon: "inboxOut",
  };
  const total_email_error = {
    title: t("lms_emails_were_not_received"),
    total: "0",
    description: t("lms_emails_were_not_received"),
    icon: "inboxIn",
  };

  return (
    <ContainerPage>
      {(isPending || isPendingInsights) && <NSpinnerPoints />}
      {!isPending &&
        isPending !== null &&
        initiative !== null &&
        insights !== null && (
          <div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faChartLine}
                style={{ fontSize: "var(--font-size-lg)", marginRight: "8px" }}
              />
              <TitleText>{t("overall_performance")}</TitleText>
            </div>
            <RowItem style={{ marginTop: "40px" }}>
              <Col lg="3" sm="6" md="6">
                <CardIndicator
                  data={total_email_send}
                  total={insights.insights.total + ""}
                />
              </Col>
              <Col lg="3" sm="6" md="6" className="mt-3 mt-sm-0">
                <CardIndicator
                  data={total_email_error}
                  total={insights.insights.bounced + ""}
                  action={() => {}}
                  actionLabel={t("resend")}
                  actionIcon={faShare}
                />
              </Col>
            </RowItem>
          </div>
        )}
    </ContainerPage>
  );
};

export default PageIndicatorLMS;
const TitleText = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */

  /* text/primary */

  color: #2a2b2e;
`;
const ContainerPage = styled.div`
  @media screen and (min-width: 1480px) {
    .max-7 {
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .max-5 {
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .max-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .w-max-100 {
      height: 100%;
      .card {
        height: 100% !important;
      }
    }
    .mr-max-1 {
      margin-right: 10px;
    }
  }
`;
