import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faUser,
  faEnvelopeOpen,
  faPhoneAlt,
} from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import { ContactInfo } from "models";
import DatePicker from "react-datepicker";
import copy from "copy-to-clipboard";
import { uuidv4 } from "utils";
import ContactList from "./ContactList";

import styles from "./ModalProfile.module.css";
import { AnonymousContainer } from "components/Nimbiv2/styled";

const CONTACT_TYPE = {
  EMAIL: "email",
  PHONE: "phone",
};

const TabPersonal = ({ student, onUpdateStudent }) => {
  const refInputLastName = useRef();
  const refInputFirstName = useRef();
  const [studentBirthDate, setStudentBirthDate] = useState(new Date());
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (student) {
      setStudentBirthDate(moment(student.birth_date).toDate());

      // update the ref inputs
      if (refInputFirstName.current && refInputLastName.current) {
        refInputFirstName.current.value = student.first_name;
        refInputLastName.current.value = student.last_name;
      }
    } else {
      setStudentBirthDate(new Date());
    }
  }, [student]);

  const copyValue = (value) => copy(value);

  const getUserThumb = (photo, isBig = false) => {
    // if (photo) {
    //   const imgStyles = { height: isBig ? 100 : 52, width: "auto" };
    //   return <Image style={imgStyles} src={user.profile_picture} alt="thumb" roundedCircle />;
    // }

    return <FontAwesomeIcon icon={faUser} size={isBig ? "5x" : "1x"} />;
  };

  const handleChangedDate = (date) => {
    onUpdateStudent({ key: "birth_date", value: date });
  };

  const updateInputValue = (event, refInput = "first_name") => {
    onUpdateStudent({ key: refInput, value: event.target.value });
  };

  const handleGenderChange = (event) =>
    onUpdateStudent({ key: "gender", value: event.target.value });

  const updateContacts = (newContactList) => {
    onUpdateStudent({ key: "contactinfo_set", value: newContactList });
  };

  const addContact = (contactType) => {
    const newContact = new ContactInfo({
      id: uuidv4(8),
      contact_type: contactType,
      is_allowed: "yes",
      is_new: true,
      error: true,
    });
    updateContacts([newContact, ...student.contactinfo_set]);
  };

  if (!student) return null;

  return (
    <Row className={styles.tabContainer}>
      <Col sm={2} className="mt-4 d-flex justify-content-center">
        {getUserThumb("student?.profile_picture", true)}
      </Col>
      <Col>
        <Row className="mt-4">
          <Col sm={4}>
            <span className="ml-1">ID alumno</span>
            <AnonymousContainer isAnonymous={user?.anonymous}>
              <div className={styles.fieldBox}>
                <span className={styles.text1}>{student.external_id}</span>
                <FontAwesomeIcon
                  className={`ml-2 ${styles.iconField}`}
                  size="1x"
                  icon={faClone}
                  onClick={() => copyValue(student.external_id)}
                  aria-hidden="true"
                />
              </div>
            </AnonymousContainer>
          </Col>
          <Col sm={4}>
            <span className="ml-1">N° documento</span>
            <AnonymousContainer isAnonymous={user?.anonymous}>
              <div className={styles.fieldBox}>
                <span className={styles.text1}>
                  {`${student.document_number} (${student.document_type})`}
                </span>
                <FontAwesomeIcon
                  className={`ml-2 ${styles.iconField}`}
                  size="1x"
                  icon={faClone}
                  onClick={() => copyValue(student.document_number)}
                  aria-hidden="true"
                />
              </div>
            </AnonymousContainer>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={4}>
            <span className={`ml-1 ${styles.label2}`}>Nombres</span>
            <div className={styles.fieldBox2}>
              <input
                className={styles.inputInfo}
                type="text"
                ref={refInputFirstName}
                onChange={(event) => updateInputValue(event)}
              />
              <FontAwesomeIcon
                className={styles.iconField}
                size="1x"
                icon={faClone}
                onClick={() => copyValue(student?.first_name)}
                aria-hidden="true"
              />
            </div>
          </Col>
          <Col sm={6}>
            <span className={`ml-1 ${styles.label2}`}>Apellidos</span>
            <div className={styles.fieldBox2}>
              <input
                className={styles.inputInfo}
                type="text"
                ref={refInputLastName}
                onChange={(event) => updateInputValue(event, "last_name")}
              />
              <FontAwesomeIcon
                className={`ml-2 ${styles.iconField}`}
                size="1x"
                icon={faClone}
                onClick={() => copyValue(student?.last_name)}
                aria-hidden="true"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          {1 === 0 && (
            <Col sm={4}>
              <span className={`ml-1 ${styles.label2}`}>Nacimiento</span>
              <div className={styles.fieldBox2}>
                <DatePicker
                  className={styles.inputInfo}
                  selected={studentBirthDate}
                  maxDate={new Date()}
                  onChange={(date) => handleChangedDate(date)}
                  showYearDropdown
                  showMonthDropdown
                  yearDropdownItemNumber={10}
                  locale="es"
                  dateFormat="dd/MM/yyyy"
                />
                <FontAwesomeIcon
                  className={`ml-2 ${styles.iconField}`}
                  size="1x"
                  icon={faClone}
                  onClick={() => copyValue("personsProfile.birth_date")}
                  aria-hidden="true"
                />
              </div>
            </Col>
          )}
          <Col>
            <span className={`ml-1 ${styles.label2}`}>Género</span>
            <div
              className={`d-flex align-items-center ${styles.radiosContainer}`}
            >
              <Form.Check
                className="mr-4"
                type="radio"
                name="gender"
                id="female-rad"
                label="Femenino"
                value="F"
                checked={student?.gender === "F"}
                onChange={handleGenderChange}
                custom
              />
              <Form.Check
                className="mr-4"
                type="radio"
                name="gender"
                id="male-rad"
                label="Masculino"
                value="M"
                checked={student?.gender === "M"}
                onChange={handleGenderChange}
                custom
              />
              <Form.Check
                className="mr-4"
                type="radio"
                name="gender"
                id="other-rad"
                label="Otro"
                value="O"
                checked={student?.gender === "O"}
                onChange={handleGenderChange}
                custom
              />
            </div>
          </Col>
        </Row>
        <Container className={`mt-4 ${styles.contactBlock}`} fluid>
          <Row style={{ paddingBottom: "12px" }}>
            <Col className="d-flex align-items-center" xs={4}>
              <div>Informaciones de contacto</div>
            </Col>
            <Col>
              <div className={styles.addGroup}>
                <Button
                  className={styles.AddBtn}
                  variant="light"
                  style={{
                    background: "#f8f9fa",
                    color: "#212529",
                    borderColor: "#f8f9fa",
                  }}
                  onClick={() => addContact(CONTACT_TYPE.EMAIL)}
                >
                  <FontAwesomeIcon
                    className={`icons_color ${styles.addIcon}`}
                    icon={faEnvelopeOpen}
                  />
                  {"   Agregar Correo"}
                </Button>

                <Button
                  className={styles.AddBtn}
                  variant="light"
                  style={{
                    background: "#f8f9fa",
                    color: "#212529",
                    borderColor: "#f8f9fa",
                    marginLeft: "5px",
                  }}
                  onClick={() => addContact(CONTACT_TYPE.PHONE)}
                >
                  <FontAwesomeIcon
                    className={`icons_color ${styles.addIcon}`}
                    icon={faPhoneAlt}
                  />
                  {"   Agregar Teléfono"}
                </Button>
              </div>
            </Col>
          </Row>
          {student && student.contactinfo_set ? (
            <AnonymousContainer isAnonymous={user?.anonymous}>
              <ContactList
                contacts={student.contactinfo_set}
                onUpdate={updateContacts}
              />
            </AnonymousContainer>
          ) : null}
        </Container>
      </Col>
    </Row>
  );
};

TabPersonal.propTypes = {
  student: PropTypes.shape({}).isRequired,
  onUpdateStudent: PropTypes.func.isRequired,
};

export default React.memo(TabPersonal);
