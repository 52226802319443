import React, { forwardRef } from "react";
import styles from "containers/Student/Student.module.css";
import moment from "moment";
import { Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/pro-regular-svg-icons";

const PopoverLastContact = forwardRef(({ data, ...props }, ref) => (
  <Popover {...props} className={styles.contactPopover} ref={ref}>
    <Popover.Content>
      <div className={styles.title}>{data.contact_reason}</div>
      {data.completed_at && (
        <div className={styles.dateInfo}>
          {moment(data.completed_at).format("DD/MM/YYYY - HH:mm")}h
        </div>
      )}
      {data.user_name && (
        <div className={styles.contactUser}>
          <FontAwesomeIcon className="mr-3" icon={faUserAlt} size="1x" />
          {data.user_name}
        </div>
      )}
      <div style={{ display: "none" }} className={styles.historyLink}>
        Ver historial completo
      </div>
    </Popover.Content>
  </Popover>
));

export default PopoverLastContact;
