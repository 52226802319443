import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { NNavbarDashboard, ScrollToTopOnMount } from "components/Nimbiv2";
import { useTranslationLocal } from "hooks";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModule } from "store/actions/site.actions";
import { useTranslation } from "react-i18next";
import {
  fetchPersonHistory,
  fetchPersonsProfile,
  fetchStudentCourses,
  fetchStudentTrackingsPerson,
  fetchStudentInitiatives,
  fetchStudentMoments,
} from "store/actions/async/crm-async.action";

import CardStudent from "containers/Student/v3/CardStudent";
import MenuItemStudent from "containers/Student/v3/MenuItemStudent";
import {
  getDataFromLocalStorage,
  getGlobalConfig,
  getText,
  verifyPermissionTab,
} from "utils";
// import { HistoryTask, InfoStudent, PageRisk } from "containers/Student/detail";
import {
  ExternalManagementPage,
  ObservationPage,
  MilestonePage,
  SurveyPage,
} from "containers/Student/detail";
import PageInfoStudent from "containers/Student/v3/PageInfoStudent";
import {
  fetchStudentNotes,
  fetchStudentInsights,
  fetchStudentRisk,
  fetchInfoCustomPerson,
  //  fetchProgressAttendance,
  //  fetchProgressGrades,
} from "store/actions/async/student-async.actions";
import PageHistoryStudent from "containers/Student/v3/PageHistoryStudent";
import PageRiskStudent from "containers/Student/v3/PageRiskStudent";
import PageProcessStudent from "containers/Student/v3/PageProcessStudent";
import PageAcedemicAdvancement from "containers/Student/v3/PageAcademicAdvancement";
import PageGradesStudent from "containers/Student/v3/PageGradesStudent";
import PageAttendanceStudent from "containers/Student/v3/PageAttendanceStudent";
import PageZendeskStudent from "containers/Student/v3/PageZendeskStudent";
import PageChangeLogStudent from "containers/Student/v3/PageChangeLogStudent";
// import PageExternalManagement from "containers/Student/v3/PageExternalManagement";
import * as StudentActions from "store/actions/student.actions";
import PageActionPlan from "containers/Student/v3/PageActionPlan";
import RiskHistoryPage from "containers/Student/detail/RiskHistoryPage";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import CardExternalLinks from "containers/Student/detail/CardExternalLinks";

const StudentDetailV3 = () => {
  const dispatch = useDispatch();
  const { translation } = useTranslationLocal();
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const [person, setPerson] = useState(null);
  const [tabSelect, setTabSelect] = useState("information");
  const [selectTracking, setSelectTracking] = useState(null);
  const extras = getDataFromLocalStorage("extras", {});
  const { personsProfile, personCourses } = useSelector((state) => state.crm);
  const { customInfoPerson } = useSelector((state) => state.task);
  // const { user } = useSelector((state) => state.auth);
  const {
    // insights,
    courseSelected,
    // pending: { insightsPending: isPending },
  } = useSelector((state) => state.student);

  const customLinks = useMemo(() => {
    const searchLinks = customInfoPerson.find(
      (item) => item.key_value === "links"
    );
    return searchLinks;
  }, [customInfoPerson]);

  //contents
  useEffect(() => {
    if (personsProfile) {
      setPerson(personsProfile);
    }
  }, [personsProfile]);

  useEffect(() => {
    dispatch(fetchStudentCourses(id));
    dispatch(fetchPersonsProfile(id));
    dispatch(fetchStudentTrackingsPerson(id, { page: 1, page_size: 100 }));
    dispatch(fetchPersonHistory(id));
    dispatch(fetchStudentNotes(id));
    dispatch(fetchStudentMoments(id));
    dispatch(fetchStudentInitiatives(id));
    // dispatch(fetchEnterableActionPlans(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (courseSelected) {
      dispatch(fetchStudentInsights(id, { course: courseSelected }));
      dispatch(fetchStudentRisk(id, { course: courseSelected }));
    }
  }, [dispatch, id, courseSelected]);

  useEffect(() => {
    dispatch(
      setActiveModule({
        title: translation(
          "studentDetailPageTitle",
          null,
          _.upperFirst(t("info_student"))
        ),
        subtitle: null,
        breadcrumbles: [
          {
            title: location.state
              ? _.upperFirst(t(location.state.back))
              : _.upperFirst(translation("students", "", t("students"))),
            url: location.state ? location.state.link : "/alumnos",
          },
          {
            title: `${
              personsProfile
                ? `${personsProfile.first_name ?? ""} ${
                    personsProfile.last_name ?? ""
                  }`
                : "..."
            }`,
            url: null,
          },
        ],
      })
    );
  }, [dispatch, personsProfile, location.state, translation, t]);

  useEffect(() => {
    if (personCourses.length > 0) {
      const new_array = personCourses.filter(
        (item) => item.id !== 0 || item.is_active === true
      );
      if (new_array.length > 0) {
        const itemSelect = new_array[0].id;
        dispatch(fetchInfoCustomPerson(id, { course: itemSelect }));
        dispatch(
          StudentActions.setStudentCourseSelected({
            courseSelected: itemSelect,
          })
        );
        setSelectTracking(new_array[0]);
      } else {
        dispatch(fetchInfoCustomPerson(id));
      }
    }
  }, [personCourses, dispatch, id]);

  const changeTrackingObject = (data) => {
    dispatch(fetchInfoCustomPerson(id, { course: data }));
    const new_array = personCourses.filter(
      (item) => item.id === parseInt(data)
    );
    dispatch(
      StudentActions.setStudentCourseSelected({
        courseSelected: data,
      })
    );
    setSelectTracking(new_array[0]);
  };
  const student_tracking_id = useMemo(() => {
    if (courseSelected) {
      const new_array = personCourses.filter(
        (item) => item?.id?.toString() === courseSelected.toString()
      );
      if (new_array.length > 0) {
        return new_array[0].student_tracking_id;
      }
    }
    return null;
  }, [courseSelected, personCourses]);

  let menu = [
    {
      name: getText("information", "startCase", t("information")),
      key: "information",
      show: true,
    },
    {
      name: getText("risk", "startCase", t("risk")),
      key: "risk",
      show: !getGlobalConfig("hide_nimbi_risk", false),
    },
    {
      name: getText("history", "startCase", t("record")),
      key: "history",
      show: true,
    },
    {
      name: getText("tracking_objects", "startCase", t("courses")),
      key: "tracking_objects",
      show: true,
    },
    {
      name: "Avance académico",
      key: "academic_advancement",
      show: verifyPermissionTab("tabTaskDetail", "academic_advancement"),
    },
    {
      name: _.upperFirst(t("grades")),
      key: "progress_grades",
      show: verifyPermissionTab("tabProfile", "progress_grades"),
    },
    {
      name: _.upperFirst(t("attendance")),
      key: "progress_attendance",
      show: verifyPermissionTab("tabProfile", "progress_attendance"),
    },
    {
      name: _.upperFirst(t("external_management")),
      key: "external_management",
      show: verifyPermissionTab("tabProfile", "externalManagement"),
    },
    {
      name: getText("action_plans", "startCase", t("action_plans")),
      key: "action_plans",
      show: true,
    },
    {
      name: getText("observations", "startCase", t("observations")),
      key: "observations",
      show: true,
    },
    {
      name: getText("zendesk", "startCase", t("portal_help")),
      key: "zendesk",
      show: verifyPermissionTab("tabProfile", "zendesk"),
    },
    {
      name: "Historial de cambios",
      key: "change_logs",
      show: verifyPermissionTab("tabProfile", "change_logs"),
    },
    {
      name: "Historial de riesgo",
      key: "risk_history",
      show: verifyPermissionTab("tabProfile", "risk_history"),
    },
    {
      name: _.upperFirst(t("surveys")),
      key: "surveys",
      show: verifyPermissionTab("tabProfile", "surveys"),
    },
    // {
    //   name: "",
    //   key: "",
    //   show:
    //     verifyPermissionTab("tabProfile", "milestone") &&
    //     personsProfile?.periodo_ingreso_carrera,
    // },
  ];
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="#!"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{
        color: "#455362",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10.5px 16px 10.5px 8px",
        fontSize: "16px",
        fontWeight: "600",
        cursor: "pointer",
        backgroundColor: "rgb(236, 242, 255)",
        borderRadius: "10px",
        margin: "20px 0px",
      }}
    >
      {children}
    </a>
  ));
  return (
    <div style={{ paddingBottom: "20px" }}>
      <ScrollToTopOnMount />
      <NNavbarDashboard breadcrumbles={true} activeCircle={false} />
      <div
        className="flex-column flex-lg-row"
        style={{ display: "flex", marginTop: "16px" }}
      >
        <div>
          <div className="d-lg-none">
            <div
              style={{
                marginBottom: "10px",
                paddingRight: "10px",
                paddingLeft: "10px",
              }}
            >
              <CardStudent selectTracking={selectTracking} />
              <ContainerMenuDropdown>
                <Dropdown
                  style={{ marginLeft: "5px" }}
                  onSelect={(value) => {
                    setTabSelect(value);
                  }}
                >
                  <Dropdown.Toggle as={CustomToggle}>Menu</Dropdown.Toggle>

                  <Dropdown.Menu>
                    {menu &&
                      menu
                        .filter((item) => item?.show)
                        .map((moment, key) => {
                          return (
                            <Dropdown.Item
                              active={moment.key === tabSelect}
                              eventKey={moment.key}
                              key={`option-menu-${key}`}
                            >
                              {moment.name}
                            </Dropdown.Item>
                          );
                        })}
                    {verifyPermissionTab("tabProfile", "milestone") &&
                      personsProfile?.periodo_ingreso_carrera && (
                        <Dropdown.Item
                          active={tabSelect === "milestone"}
                          eventKey="milestone"
                          key="option-menu-milestone"
                        >
                          {getText("milestone", "startCase", t("milestone"))}
                        </Dropdown.Item>
                      )}
                  </Dropdown.Menu>
                </Dropdown>
              </ContainerMenuDropdown>
              <CardExternalLinks
                personsProfile={personsProfile}
                extras={extras}
                customLinks={customLinks}
              />
            </div>
          </div>
        </div>
        <div
          className="d-none d-lg-flex"
          style={{
            minWidth: "340px",
            maxWidth: "390px",
            paddingLeft: "10px",
            paddingRight: "14px",
            display: "none",
            minHeight: "85vh",
            flexDirection: "column",
            maxHeight: "max-content",
          }}
        >
          <div>
            <CardStudent selectTracking={selectTracking} />
          </div>
          <div style={{ marginTop: "16px" }}>
            <MenuItemStudent
              title={getText("information", "startCase", t("information"))}
              active={tabSelect === "information"}
              onClick={() => {
                setTabSelect("information");
              }}
            />
            {!getGlobalConfig("hide_nimbi_risk", false) && (
              <MenuItemStudent
                title={getText("risk", "startCase", t("risk"))}
                active={tabSelect === "risk"}
                onClick={() => {
                  setTabSelect("risk");
                }}
              />
            )}
            <MenuItemStudent
              title={getText("history", "startCase", t("record"))}
              active={tabSelect === "history"}
              onClick={() => {
                setTabSelect("history");
              }}
            />
            <MenuItemStudent
              title={getText("tracking_objects", "startCase", t("courses"))}
              active={tabSelect === "tracking_objects"}
              onClick={() => {
                setTabSelect("tracking_objects");
              }}
            />

            {verifyPermissionTab("tabTaskDetail", "academic_advancement") && (
              <MenuItemStudent
                title="Avance académico"
                active={tabSelect === "academic_advancement"}
                onClick={() => {
                  setTabSelect("academic_advancement");
                }}
              />
            )}
            {verifyPermissionTab("tabProfile", "progress_grades") && (
              <MenuItemStudent
                title={_.upperFirst(t("grades"))}
                active={tabSelect === "progress_grades"}
                onClick={() => {
                  setTabSelect("progress_grades");
                }}
              />
            )}
            {verifyPermissionTab("tabProfile", "progress_attendance") && (
              <MenuItemStudent
                title={_.upperFirst(t("attendance"))}
                active={tabSelect === "progress_attendance"}
                onClick={() => {
                  setTabSelect("progress_attendance");
                }}
              />
            )}
            {verifyPermissionTab("tabProfile", "externalManagement") && (
              <MenuItemStudent
                title={_.upperFirst(t("external_management"))}
                active={tabSelect === "external_management"}
                onClick={() => {
                  setTabSelect("external_management");
                }}
              />
            )}
            <MenuItemStudent
              title={getText("action_plans", "startCase", t("action_plans"))}
              active={tabSelect === "action_plans"}
              onClick={() => {
                setTabSelect("action_plans");
              }}
            />
            <MenuItemStudent
              title={getText("observations", "startCase", t("observations"))}
              active={tabSelect === "observations"}
              onClick={() => {
                setTabSelect("observations");
              }}
            />

            {verifyPermissionTab("tabProfile", "zendesk") && (
              <MenuItemStudent
                title={getText("zendesk", "startCase", t("portal_help"))}
                active={tabSelect === "zendesk"}
                onClick={() => {
                  setTabSelect("zendesk");
                }}
              />
            )}
            {verifyPermissionTab("tabProfile", "change_logs") && (
              <MenuItemStudent
                title={"Historial de cambios"}
                active={tabSelect === "change_logs"}
                onClick={() => {
                  setTabSelect("change_logs");
                }}
              />
            )}
            {verifyPermissionTab("tabProfile", "risk_history") && (
              <MenuItemStudent
                title={"Historial de riesgo"}
                active={tabSelect === "risk_history"}
                onClick={() => {
                  setTabSelect("risk_history");
                }}
              />
            )}
            {verifyPermissionTab("tabProfile", "surveys") && (
              <MenuItemStudent
                title={_.upperFirst(t("surveys"))}
                active={tabSelect === "surveys"}
                onClick={() => {
                  setTabSelect("surveys");
                }}
              />
            )}
            {verifyPermissionTab("tabProfile", "milestone") &&
              personsProfile?.periodo_ingreso_carrera && (
                <MenuItemStudent
                  title={getText("milestone", "startCase", t("milestone"))}
                  active={tabSelect === "milestone"}
                  onClick={() => {
                    setTabSelect("milestone");
                  }}
                />
              )}
          </div>
          <CardExternalLinks
            personsProfile={personsProfile}
            extras={extras}
            customLinks={customLinks}
          />
        </div>
        <div
          style={{
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
            minHeight: "85vh",
          }}
        >
          {tabSelect === "information" && (
            <div>
              <PageInfoStudent
                changeTrackingObject={changeTrackingObject}
                selectTracking={selectTracking}
              />
            </div>
          )}
          {tabSelect === "history" && (
            <div style={{ height: "100%" }}>
              <PageHistoryStudent />
            </div>
          )}
          {tabSelect === "risk" && (
            <div style={{ height: "100%" }}>
              <PageRiskStudent
                student_tracking_id={student_tracking_id}
                selectTracking={selectTracking}
                changeTrackingObject={changeTrackingObject}
              />
            </div>
          )}
          {tabSelect === "tracking_objects" && (
            <div style={{ height: "100%" }}>
              <PageProcessStudent />
            </div>
          )}
          {tabSelect === "observations" && <ObservationPage studentID={id} />}
          {tabSelect === "academic_advancement" && (
            <div style={{ height: "100%" }}>
              <PageAcedemicAdvancement
                student_tracking_id={student_tracking_id}
                selectTracking={selectTracking}
                changeTrackingObject={changeTrackingObject}
              />
            </div>
          )}
          {tabSelect === "action_plans" && (
            <PageActionPlan
              studentID={id}
              selectTracking={selectTracking}
              changeTrackingObject={changeTrackingObject}
            />
          )}
          {tabSelect === "progress_grades" &&
            student_tracking_id &&
            verifyPermissionTab("tabProfile", "progress_grades") && (
              <PageGradesStudent
                student_tracking_id={student_tracking_id}
                selectTracking={selectTracking}
                changeTrackingObject={changeTrackingObject}
              />
            )}
          {tabSelect === "progress_attendance" &&
            student_tracking_id &&
            verifyPermissionTab("tabProfile", "progress_attendance") && (
              <PageAttendanceStudent
                student_tracking_id={student_tracking_id}
                selectTracking={selectTracking}
                changeTrackingObject={changeTrackingObject}
              />
            )}
          {tabSelect === "zendesk" && <PageZendeskStudent person={person} />}
          {tabSelect === "change_logs" &&
            verifyPermissionTab("tabProfile", "externalManagement") && (
              <PageChangeLogStudent
                student_tracking_id={student_tracking_id}
                selectTracking={selectTracking}
                changeTrackingObject={changeTrackingObject}
              />
            )}
          {tabSelect === "external_management" &&
            verifyPermissionTab("tabProfile", "externalManagement") && (
              <ExternalManagementPage
                student_tracking_id={student_tracking_id}
                selectTracking={selectTracking}
                changeTrackingObject={changeTrackingObject}
              />
            )}
          {tabSelect === "risk_history" &&
            verifyPermissionTab("tabProfile", "risk_history") && (
              <RiskHistoryPage student_tracking_id={student_tracking_id} />
            )}
          {tabSelect === "surveys" &&
            student_tracking_id &&
            verifyPermissionTab("tabProfile", "surveys") && (
              <SurveyPage
                student_tracking_id={student_tracking_id}
                selectTracking={selectTracking}
                changeTrackingObject={changeTrackingObject}
              />
            )}
          {tabSelect === "milestone" &&
            student_tracking_id &&
            verifyPermissionTab("tabProfile", "milestone") && (
              <MilestonePage
                student_tracking_id={student_tracking_id}
                selectTracking={selectTracking}
                changeTrackingObject={changeTrackingObject}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default StudentDetailV3;
const ContainerMenuDropdown = styled.div`
  .dropdown-menu {
    width: 100%;
  }
`;
