import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NTable } from "components/Nimbiv2";
import { faFlag, faLightbulbOn } from "@fortawesome/pro-regular-svg-icons";
import LastContact from "./columns/LastContact";
import ExpirationDate from "./columns/ExpirationDate";
import Actions from "./columns/Actions";
import ClientFactory from "models/ClientFactory";

import LastManagement from "../LastManagement";
import { ModalProfile, NBadge, OverlayTrigger, Tooltip } from "components";

import {
  fetchPersonHistory,
  fetchPersonsProfile,
  fetchStudentTrackingsPerson,
  fetchStudentCourses,
  fetchGroupedTasks,
  fetchStudentMoments,
} from "store/actions/async/crm-async.action";

import {
  getDataFromLocalStorage,
  getExtraColumns,
  getGlobalConfig,
} from "utils";
import ActionsToggle from "./columns/ActionsToggle";
import {
  fetchInfoCustomPerson,
  fetchStudentNotes,
} from "store/actions/async/student-async.actions";
import {
  AnonymousContainer,
  IconContainer,
  NNameContrainerUnderline,
} from "components/Nimbiv2/styled";
import useTranslationLocal from "hooks/useTranslationLocal";
import { TagList } from "components/Nimbiv2/PageTask";
import RiskScoreTask from "./columns/RiskScoreTask";
import { useTranslation } from "react-i18next";
import TaskGroupSide from "../TaskGroupSide";

const TableTask = ({
  dataSource,
  isPending,
  toggleTasks,
  processTaskRequest,
  showEmptyWidget,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const Institution = new ClientFactory().client();

  const { tabTask } = useSelector((state) => state.crm);
  const new_columns = getExtraColumns("tableTaskNew");

  const [showLastContact, setShowLastContact] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [studentInfo, setStudentInfo] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const extras = getDataFromLocalStorage("extras", {});
  const table_task_extras = extras["tableTask"] ?? [];
  const user = getDataFromLocalStorage("user", {});
  const handleClose = () => {
    setStudentInfo(null);
    setShowLastContact(false);
  };

  const handleCloseProfile = () => setShowProfile(false);

  const toggleProfileModal = (task) => {
    const { student_id: studentId } = task.student_tracking;
    dispatch(fetchPersonsProfile(studentId));
    dispatch(fetchStudentTrackingsPerson(studentId));
    dispatch(fetchPersonHistory(studentId));
    dispatch(fetchStudentNotes(studentId));
    dispatch(fetchStudentCourses(studentId));
    dispatch(fetchStudentMoments(studentId));
    if (
      task &&
      task?.student_tracking &&
      task?.student_tracking?.tracking_object
    ) {
      dispatch(
        fetchInfoCustomPerson(studentId, {
          course: task.student_tracking.tracking_object.id,
        })
      );
    }
    setShowProfile(true);
  };

  const toggleContactModal = (task) => {
    setStudentInfo({ full_name: task.student_tracking.full_name });
    dispatch(fetchPersonHistory(task.student_tracking.student_id));
    setShowLastContact(true);
  };

  const groupTasks = (task, position) => {
    dispatch(fetchGroupedTasks(task.student_tracking.id, task?.moment_id));
    setItemSelected(task);
  };

  const columns = [
    {
      columnName: "student",
      title: translation("student"),
      width: "191",
      key: "icon",
      fixed: true,
      style: { left: "0px", zIndex: 1 },
      render: (item, key, position, keyName) => (
        <div>
          <div className="d-flex">
            <div
              style={{
                fontSize: "var(--font-size-caption)",
                lineHeight: "140%",
                fontWeight: 500,
                letterSpacing: "0.3px",
              }}
              onClick={() => {
                toggleProfileModal(item);
              }}
              className="d-flex align-items-center"
            >
              {item.last_note && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {" "}
                      {item.last_note.length > 150
                        ? item.last_note.substring(0, 150) + "..."
                        : item.last_note}
                    </Tooltip>
                  }
                >
                  <IconContainer
                    size="30px"
                    style={{ marginRight: "8px" }}
                    background="#EDF1F7"
                  >
                    <FontAwesomeIcon
                      icon={faLightbulbOn}
                      style={{ fontSize: "var(--font-size-md)" }}
                      color="#334FFF"
                    />
                  </IconContainer>
                </OverlayTrigger>
              )}
              <NNameContrainerUnderline>
                {!user.anonymous && item.student_tracking.full_name}
                {user.anonymous && (
                  <>
                    {item.student_tracking.full_name.split(" ", 1)}{" "}
                    <AnonymousContainer isAnonymous={user?.anonymous}>
                      {item.student_tracking.full_name
                        .split(" ")
                        .splice(1)
                        .join(" ")}
                    </AnonymousContainer>
                  </>
                )}
              </NNameContrainerUnderline>
            </div>
            <div className="d-flex align-items-center">
              {item.counter > 1 && keyName !== "table_group" ? (
                <NBadge
                  onClick={() => groupTasks(item, position)}
                  // text={`+${item.counter - 1}`}
                  text={`+${item.counter}`}
                  kind="aqua"
                  customStyle={{ fontSize: 13, cursor: "pointer" }}
                  className={"ml-2"}
                  isBold
                />
              ) : null}
            </div>
          </div>
          <div style={{ fontSize: "var(--font-size-small)", color: "#787C83" }}>
            <AnonymousContainer isAnonymous={user?.anonymous}>{`${
              (item.student_tracking.document_type ?? "Other") === "Other"
                ? translation("document_type_dynamic", "", t("other"))
                : item.student_tracking.document_type ?? ""
            } ${item.student_tracking.document_number}`}</AnonymousContainer>
          </div>
          {getGlobalConfig("id_student_show", "yes") === "yes" && (
            <div
              style={{
                fontSize: "var(--font-size-small)",
                color: "#787C83",
                lineHeight: "120%",
                marginTop: "2px",
              }}
            >
              <AnonymousContainer isAnonymous={user?.anonymous}>
                {item.student_tracking.external_id}
              </AnonymousContainer>
            </div>
          )}
          {item.priority ? (
            <div
              style={{
                fontSize: "var(--font-size-small)",
                lineHeight: "120%",
                marginTop: "2px",
              }}
            >
              <span className="validation_color">{item.priority}</span>&nbsp;
              <FontAwesomeIcon className="icons_color mr-2" icon={faFlag} />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      columnName: "tracking_object",
      title: translation("tracking_object", "", "Tracking Object"),
      width: new_columns.length > 0 ? "135" : "175",
      key: "icon",
      render: (item, key) => {
        return (
          <div>
            <Institution.CourseColumnInfo
              enrollment={item.student_tracking}
              hasExtension={item.has_extension}
              hasPaymentProblem={item.payment_problem}
            />
            {item?.visual_tags && (
              <div style={{ marginTop: "5px" }}>
                <TagList tags={item?.visual_tags} />
              </div>
            )}
          </div>
        );
      },
    },
    {
      columnName: "campus",
      title: translation("campus", "", "Sede"),
      width: new_columns.length > 0 ? "100" : "110",
      key: "icon",
      render: (item, key) => (
        <AnonymousContainer isAnonymous={user?.anonymous}>
          <Institution.CampusColumnInfo enrollment={item.student_tracking} />
        </AnonymousContainer>
      ),
    },
    {
      title: translation("tracking_object", "", "Curso"),
      key: "new_carrera",
      center: true,
      permission: "carrera",
      width: "110",
      render: (item, key) => (
        <div>
          {item["student_tracking"]["carrera"] ?? t("no_information_line")}
        </div>
      ),
    },
    {
      title: t("tracking_object_mode", {
        tracking_object: translation("tracking_object", "", "Curso"),
      }),
      key: "new_career_mode",
      center: true,
      permission: "modalidad_carrera",
      width: "140",
      render: (item, key) => {
        return (
          <div>
            {item["student_tracking"]["modalidad_carrera"] ??
              t("no_information_line")}
          </div>
        );
      },
    },
    {
      columnName: "last_management",
      title: translation("last_management", "", "Última Gestión"),
      dataIndex: "last_contact",
      key: "icon",
      center: true,
      width: "110",
      render: (last_contact, item, key) => (
        <LastContact
          last_contact={last_contact}
          callback={toggleContactModal}
          item={item}
          user={user}
        />
      ),
    },
    {
      columnName: "expires_in",
      title: translation("expires_in", "", "Expira en"),
      key: "icon",
      width: "110",
      center: true,
      render: (item, key) => <ExpirationDate item={item} />,
    },
    {
      columnName: "risk",
      title: translation("risk", "", "Riesgo"),
      width: "100",
      key: "icon",
      center: true,
      render: (item, key) => (
        <div style={{ textAlign: "center" }}>
          {<RiskScoreTask item={item} />}
        </div>
      ),
    },

    ...getExtraColumns("tableTaskNew", false, translation),
    {
      columnName: "action",
      title: translation("action", "", "Acción"),
      key: "icon",
      center: true,
      fixed: true,
      style: { right: "0px" },
      width: "80",
      render: (item, key, postion) => {
        // if (positionOpenCollapse.includes(postion)) return <></>;
        if (user["config"]) {
          if (
            user["config"]["call_task_version"] &&
            user["config"]["call_task_version"] === "version2"
          ) {
            return (
              <div style={{ textAlign: "center" }}>
                <Actions
                  item={item}
                  processTaskRequest={processTaskRequest}
                  toggleTasks={toggleTasks}
                  groupTasks={groupTasks}
                ></Actions>
              </div>
            );
          }
        }

        return (
          <div style={{ textAlign: "center" }}>
            <ActionsToggle
              item={item}
              processTaskRequest={processTaskRequest}
              toggleTasks={toggleTasks}
            ></ActionsToggle>
          </div>
        );
      },
    },
  ];

  // const toggleTasks = async (action, item) => {
  //   if (action === typesTaskEvent.RELEASED) {
  //     await processTaskRequest(item, typesTaskEvent.RELEASED);
  //   }

  //   // lock/unlock others tasks
  //   dispatch(
  //     toggleActionRow({ taskId: item.id, lastTaskEvent: lastNotification })
  //   );
  // };

  return (
    <>
      <NTable
        keyName="table_task"
        tableName="tableTaskNew"
        widthSkeletons="100%"
        key={"table-task"}
        columns={columns}
        dataSource={dataSource}
        check={false}
        upperCaseHeader
        isPending={isPending}
        collapse
        toggleTasks={toggleTasks}
        textEmpty={`No hay tareas para mostrar ${
          tabTask === "moments" ? "en el plan de acción" : "en la campaña"
        }`}
        permissions={extras["tableTask"] ?? []}
        fixed={new_columns.length > 0 || table_task_extras.length > 0}
        showEmptyWidget={showEmptyWidget}
      />
      {/* Modal LastContact */}
      {showLastContact ? (
        <LastManagement
          student={studentInfo}
          isVisible={showLastContact}
          onClose={handleClose}
        />
      ) : null}

      {/* Student profile Modal */}
      {showProfile ? (
        <ModalProfile
          notifyClose={handleCloseProfile}
          isVisible={showProfile}
        />
      ) : null}
      <TaskGroupSide
        itemSelected={itemSelected}
        columns={columns}
        showEmptyWidget={showEmptyWidget}
        extras={extras}
        oClose={() => {
          setItemSelected(null);
        }}
      ></TaskGroupSide>
    </>
  );
};

export default TableTask;
