import React from "react";
import _ from "lodash";
import { CardNimbi } from "components/Nimbiv2/styled";
import { GradePage } from "../detail";
import { useTranslation } from "react-i18next";
import { fetchProgressGrades } from "store/actions/async/student-async.actions";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import SelectStudentTracking from "./SelectStudentTracking";

const PageGradesStudent = ({
  student_tracking_id,
  selectTracking,
  changeTrackingObject,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    periods,
    pending: { periods: isPendingPeriods },
  } = useSelector((state) => state.crm);
  const callProgressGrades = (period) => {
    if (!isPendingPeriods) {
      if (period)
        dispatch(fetchProgressGrades(student_tracking_id, { periods: period }));
      else dispatch(fetchProgressGrades(student_tracking_id));
    }
  };
  return (
    <CardNimbi height="100%" padding="16px 26px">
      <div className="d-flex justify-content-between">
        <div style={{ fontWeight: "600", fontSize: "20px" }}>
          {_.upperFirst(t("grades"))}
        </div>
        <div>
          <SelectStudentTracking
            selectTracking={selectTracking}
            changeTrackingObject={changeTrackingObject}
          />
        </div>
      </div>
      <div className="d-flex">
        {periods && periods.length > 0 && (
          <div className="d-flex justify-content-end">
            <ContainerSelect>
              <Form.Control
                as="select"
                onChange={(event) => {
                  callProgressGrades(event.target.value);
                }}
                style={{ width: "100%", padding: "6px 12px" }}
              >
                <option value={""}>{t("select_period")}</option>
                {periods.map((item, key) => {
                  return (
                    <option key={`${item.id}-${key}`} value={item.period}>
                      {item.period}
                    </option>
                  );
                })}
              </Form.Control>
            </ContainerSelect>
          </div>
        )}
      </div>
      <GradePage student_tracking_id={student_tracking_id} showSelect={false} />
    </CardNimbi>
  );
};
export default PageGradesStudent;
const ContainerSelect = styled.div`
  .form-control {
    background: transparent;
    padding: 14px 12px;
    height: auto;
    width: auto;
  }
`;
