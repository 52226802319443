/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Typeahead, AsyncTypeahead } from "react-bootstrap-typeahead";
import { getObjectAttribute, uuidv4 } from "utils";

import "react-bootstrap-typeahead/css/Typeahead.css";
import styles from "./NInputFilter.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
const NInputFilter = ({
  handleChange,
  data,
  renderMenuItem,
  placeholder = "",
  compositeLabel = [],
  selected = [],
  filterBy = [],
  pageSize = 10,
  delay = 200,
  asyncSearch = null,
  isMultiple = false,
  isDisabled = false,
  isAsync = false,
  canAutoFit = false,
  formatInput = null,
  showIcon = false,
  clean = 0,
  emptyLabel,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const typeahead = useRef(null);

  useEffect(() => {
    if (clean) {
      typeahead.current.clear();
      setOptions([]);
    }
  }, [clean]);

  const formatLabel = (option) => {
    if (compositeLabel.length > 0) {
      const newLabel = Array.from(compositeLabel).map((field) =>
        getObjectAttribute(option, field, "-")
      );
      return String(newLabel.join(", ")).trim();
    }

    // in case it's a simple option
    return option;
  };

  const handleSearch = async (query) => {
    setIsLoading(true);
    const results = await asyncSearch(formatInput ? formatInput(query) : query);
    setOptions(results);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isDisabled) {
      setOpen(false);
    }
  }, [isDisabled]);

  if (isAsync && asyncSearch) {
    return (
      <AsyncTypeahead
        delay={delay}
        ref={typeahead}
        className={canAutoFit ? styles.customStyle : ""}
        onChange={handleChange}
        filterBy={() => true}
        id="async-search"
        isLoading={isLoading}
        labelKey={(option) => formatLabel(option)}
        minLength={3}
        onSearch={handleSearch}
        selected={selected}
        options={options}
        placeholder={placeholder}
        emptyLabel={emptyLabel ? emptyLabel : "No hay resultados..."}
        renderMenuItemChildren={renderMenuItem}
        multiple={isMultiple}
        maxResults={pageSize}
        paginationText="Ver mas resultados..."
        disabled={isDisabled}
        clearButton
      />
    );
  }

  return (
    <TypeaheadContainer
      onClick={() => {
        if (!open) {
          setOpen(true);
        }
      }}
      className="form-control"
      tabindex="0"
      onBlur={() => {
        setOpen(false);
      }}
      disabled={isDisabled}
    >
      <Typeahead
        id={uuidv4(8)}
        ref={typeahead}
        onChange={(items) => {
          if (!isMultiple) {
            setOpen(false);
          }
          handleChange(items);
        }}
        options={data}
        labelKey={(option) => formatLabel(option)}
        renderMenuItemChildren={renderMenuItem}
        placeholder={placeholder}
        selected={selected}
        filterBy={filterBy}
        multiple={isMultiple}
        maxResults={pageSize}
        emptyLabel={emptyLabel ? emptyLabel : "No hay resultados..."}
        paginationText="Ver mas resultados..."
        disabled={isDisabled}
        clearButton
        open={open}
        onBlur={() => {
          setOpen(false);
        }}
      >
        {/* {({ onClear, selected }) =>
          !!selected.length && (
            <div className="rbt-aux">
              <ClearButton
                onClick={(e) => {
                  onClear();
                  setOpen(false);
                }}
                onFocus={(e) => {
                  // Prevent the main input from auto-focusing again.
                  e.stopPropagation();
                }}
                onMouseDown={(e) => {
                  // Prevent input from blurring when button is clicked.
                  e.preventDefault();
                }}
              />
            </div>
          )
        } */}
      </Typeahead>
      {showIcon && (
        <div className="dropdown-icon">
          <FontAwesomeIcon
            icon={open ? faAngleUp : faAngleDown}
            onClick={() => {
              setOpen(!open);
            }}
          ></FontAwesomeIcon>
        </div>
      )}
    </TypeaheadContainer>
  );
};

NInputFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
  formatInput: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  renderMenuItem: PropTypes.func.isRequired,
  compositeLabel: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  selected: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  filterBy: PropTypes.arrayOf(PropTypes.string),
  isMultiple: PropTypes.bool,
  pageSize: PropTypes.number,
  delay: PropTypes.number,
  asyncSearch: PropTypes.func,
  isAsync: PropTypes.bool,
  isDisabled: PropTypes.bool,
  canAutoFit: PropTypes.bool,
};

export default NInputFilter;

const TypeaheadContainer = styled.div`
  padding: 0px !important;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  .react-datepicker__input-container {
    width: 100%;
  }
  .form-control {
    width: 100%;
    border: 0px;
  }
  .rbt {
    flex: 1;
  }
  .dropdown-icon {
    padding-left: 10px;
    padding-right: 10px;
  }
  ${(props) =>
    props.disabled
      ? `background-color: #e9ecef !important; pointer-events:none;`
      : ""}
`;
