import React from "react";
import _ from "lodash";
import { CardNimbi } from "components/Nimbiv2/styled";
import { PageRisk } from "../detail";
import SelectStudentTracking from "./SelectStudentTracking";
import { useTranslationLocal } from "hooks";

const PageRiskStudent = ({ selectTracking, changeTrackingObject }) => {
  const { translation } = useTranslationLocal();
  return (
    <CardNimbi padding="16px 16px">
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: "24px", padding: "0px 10px" }}
      >
        <div style={{ fontWeight: "600", fontSize: "20px" }}>
          {_.upperFirst(translation("risk"))}
        </div>
        <SelectStudentTracking
          selectTracking={selectTracking}
          changeTrackingObject={changeTrackingObject}
        />
      </div>
      <PageRisk version={3} />
    </CardNimbi>
  );
};
export default PageRiskStudent;
