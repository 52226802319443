import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import { IconContainer } from "components/Nimbiv2/styled";
import { Image } from "components";
import styles from "../../Task.module.css";
import { useTranslation } from "react-i18next";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faMessageLines } from "@fortawesome/pro-regular-svg-icons";

const LastContact = ({ last_contact, item, callback, user }) => {
  const { t } = useTranslation();
  const iconsChannel = {
    Call: faPhone,
    Email: faEnvelope,
    WhatsApp: faWhatsapp,
    Blackboard: faMessageLines,
  };
  if (last_contact) {
    return (
      <div
        onClick={() => {
          callback(item);
        }}
        style={{ cursor: "pointer" }}
      >
        <div
          className={`d-flex align-items-center w-100 justify-content-center`}
        >
          {user &&
            (last_contact?.user_photo ?? null) !== null &&
            !user.anonymous && (
              <Image
                className={`${styles.contactThumb} mb-2`}
                src={last_contact.user_photo}
                alt="photo"
                aria-hidden="true"
                roundedCircle
              />
            )}
          {user &&
            ((last_contact?.user_photo ?? null) === null || user.anonymous) && (
              <IconContainer
                style={{ width: "30px", height: "30px" }}
                size="30"
              >
                {iconsChannel[last_contact.channel] && (
                  <FontAwesomeIcon
                    icon={iconsChannel[last_contact.channel]}
                  />
                )}
              </IconContainer>
            )}
        </div>
        <LastGestionDate
          className={`d-flex align-items-center w-100 justify-content-center`}
        >
          <u>{moment(last_contact.closed_at).format("DD/MM/YYYY")}</u>
        </LastGestionDate>
      </div>
    );
  }
  return (
    <span className="d-flex justify-content-center">
      {t("no_information_line")}
    </span>
  );
};
export default LastContact;
const LastGestionDate = styled.div`
  font-size: var(--font-size-md);
  line-height: 140%;
  text-decoration-line: underline;
  color: #334fff;
  margin-top: 4px;
`;
