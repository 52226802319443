import React from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import NBadge from "components/NBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
const BadgeDropdown = ({
  text,
  isDisabled,
  dataset = [],
  label = "",
  onSelect = () => {},
}) => {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <NBadge
        text={<div>{children}</div>}
        customStyle={{
          padding: "10px",
          borderRadius: "50px",
          fontSize: "14px",
          background: "#D9E1F2",
          color: "#2A2B2E",
          cursor: "pointer",
        }}
        colorIcon={"#9CABC2"}
        icon={<FontAwesomeIcon icon={faCaretDown} />}
        positionIcon={"right"}
        //disabled={disabled}
      />
    </div>
  ));
  return (
    <div>
      <DropdownTransparent disabled={isDisabled}>
        <Dropdown.Toggle
          as={CustomToggle}
          variant="secundary"
          id="dropdown-basic"
        >
          {text}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {dataset.map((item, key) => {
            return (
              <Dropdown.Item
                eventKey={item}
                key={`option-action-${key}`}
                value={item.id}
                onClick={() => {
                  onSelect(item);
                }}
              >
                {item[label]}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </DropdownTransparent>
    </div>
  );
};
export default BadgeDropdown;

const DropdownTransparent = styled(Dropdown)`
  margin-right: 10px;
  button {
    background-color: rgb(217, 225, 242) !important;
    color: #787c83;
    border-color: #cdd4de;
    height: 49px;
    text-align: inherit;
    border-radius: 50px;
    padding: 10px;
  }
  .btn:focus,
  .btn.focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dropdown-toggle::after {
    position: relative;
    top: auto;
    right: auto;
    margin-left: 10px;
  }
  ${(props) => (props.disabled ? "pointer-events: none;" : "")}
`;
