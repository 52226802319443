import React, { useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  faEnvelope,
  faPencil,
  faPhone,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactInfoItem from "components/Nimbiv2/ModalContactInfo/ContactInfoItem";
import {
  AnonymousContainer,
  DividerH,
  IconContainer,
} from "components/Nimbiv2/styled";
import { NButton } from "components/Nimbiv2";
import { ContactInfo } from "models";
import { uuidv4 } from "utils";

const CONTACT_TYPE = {
  EMAIL: "email",
  PHONE: "phone",
};
const ModalContactInfo = ({
  contacts,
  onUpdate,
  onHide,
  show,
  onUpdateStudent,
  isSaving = false,
  callSave = () => {},
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const {
    pending: { profile: isPending },
  } = useSelector((state) => state.crm);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    setData(contacts);
  }, [contacts]);

  const updateContact = (newInfo) => {
    const auxData = [...data];
    const pos = auxData.findIndex((row) => row.id === newInfo.id);

    if (pos >= 0) {
      auxData[pos] = newInfo;
      setData(auxData);
      updateContacts(auxData);
    }
  };

  const updateContacts = (newContactList) => {
    onUpdateStudent({ key: "contactinfo_set", value: newContactList });
  };

  const addContact = (contactType) => {
    const newContact = new ContactInfo({
      id: uuidv4(8),
      contact_type: contactType,
      is_allowed: "yes",
      is_new: true,
      error: true,
    });
    updateContacts([newContact, ...contacts]);
  };

  // const removeContact = (contact) => {
  //   const contactsSet = new Set(contacts);
  //   contactsSet.delete(contact);
  //   setData(Array.from(contactsSet));
  //   onUpdate(Array.from(contactsSet));
  // };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "24px" }}
          className="d-flex align-items-center"
        >
          Información de contacto
          <FontAwesomeIcon
            icon={faPencil}
            style={{ fontSize: "22px", marginLeft: "10px", color: "#334FFF" }}
          />
        </Modal.Title>
      </Modal.Header>
      <ModalBody>
        <div className="d-flex align-items-center">
          <IconContainer size={"42px"}>
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{ fontSize: "var(--font-size-md)" }}
            />
          </IconContainer>
          <div style={{ marginLeft: "14px", fontSize: "var(--font-size-lg)" }}>
            <strong>Correo</strong>
          </div>
          <div className="mr-auto"></div>
          <NButton outlined onClick={() => addContact(CONTACT_TYPE.EMAIL)}>
            <FontAwesomeIcon
              icon={faPlus}
              style={{ fontSize: "var(--font-size-md)", marginRight: "10px" }}
            />
            Agregar correo
          </NButton>
        </div>
        <div style={{ marginTop: "24px" }}>
          {contacts
            .filter((item) => {
              return item.contactType === "email";
            })
            .map((item, key) => {
              return (
                <div key={item.id}>
                  <AnonymousContainer isAnonymous={user?.anonymous}>
                    <ContactInfoItem contact={item} onUpdate={updateContact} />
                  </AnonymousContainer>
                </div>
              );
            })}
        </div>
        <DividerH
          style={{ marginTop: "24px", marginBottom: "24px" }}
        ></DividerH>
        <div className="d-flex align-items-center">
          <IconContainer size={"42px"}>
            <FontAwesomeIcon
              icon={faPhone}
              style={{ fontSize: "var(--font-size-md)" }}
            />
          </IconContainer>
          <div style={{ marginLeft: "14px", fontSize: "var(--font-size-lg)" }}>
            <strong>Teléfono</strong>
          </div>
          <div className="mr-auto"></div>
          <NButton outlined onClick={() => addContact(CONTACT_TYPE.PHONE)}>
            <FontAwesomeIcon
              icon={faPlus}
              style={{ fontSize: "var(--font-size-md)", marginRight: "10px" }}
            />
            Agregar teléfono
          </NButton>
        </div>
        <div style={{ marginTop: "24px" }}>
          {contacts
            .filter((item) => {
              return item.contactType === "phone";
            })
            .map((item, key) => {
              return (
                <div key={item.id}>
                  <AnonymousContainer isAnonymous={user?.anonymous}>
                    <ContactInfoItem contact={item} onUpdate={updateContact} />
                  </AnonymousContainer>
                </div>
              );
            })}
        </div>
      </ModalBody>
      <Modal.Footer>
        <NButton outlined onClick={onHide}>
          Cancelar
        </NButton>
        <NButton onClick={callSave} disabled={isSaving || isPending}>
          {_.upperFirst(t("save"))}
          {isSaving ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginLeft: "10px" }}
            />
          ) : null}
        </NButton>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalContactInfo;

const ModalBody = styled(Modal.Body)`
  padding-top: 18px;
  padding-left: 29px;
  padding-right: 29px;
  padding-bottom: 18px;
  background: #f8fbff;
`;
