import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Spinner, Form } from "react-bootstrap";
import { useFormik } from "formik";
import styles from "../User/User.module.css";
import { APP_MODULES } from "utils";
import { NSearchSelect } from "components";
import { RowItem, AnonymousContainer } from "components/Nimbiv2/styled";
import { NInput, TextError, NButton } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { useTranslationLocal } from "hooks";

const {
  STUDENT: { FILTER_NAME: FILTER_STUDENT },
} = APP_MODULES;

const AssociatedForm = ({
  formValues,
  onFormSubmit,
  isDisabled = false,
  isEditing = false,
  fetchUser = () => {},
  isEdit = false,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();

  const user = useSelector((state) => state.auth.user);
  const { listRoles, listTrackingObject } = useSelector(
    (state) => state.associated
  );

  const selectItemTrackingObjects = (x) => {
    formik.setFieldValue("tracking_objects", x);
  };

  const selectRoles = (role) => {
    if (typeof role === "object") {
      if (_.isEmpty(role)) {
        role = null;
      }
    }
    formik.setFieldValue("role", role);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = t("name_is_required");
    } else if (!values.email) {
      errors.email = t("email_required");
    } else if (!values.role) {
      errors.role = t("required_field");
    } else if (
      values.tracking_objects &&
      values.tracking_objects.length === 0
    ) {
      errors.tracking_objects = t("required_field");
    } else if (values.roles && values.roles.length === 0) {
      errors.roles = t("required_field");
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit: onFormSubmit,
    validate,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <RowItem>
        <Col lg="10" md="9">
          <RowItem style={{ paddingTop: "6px" }}>
            <Col sm="4" md="6" lg="4">
              <Form.Group controlId="formName">
                <NInput
                  minWidth="auto"
                  defaultValue={formik.values?.name}
                  label={_.upperFirst(t("name"))}
                  name="name"
                  // placeholder="Ingrese el nombre"
                  onChangeEvent={formik.handleChange}
                  errorText={formik.errors["name"]}
                />
                {/* {showError("first_name", formik.errors)} */}
              </Form.Group>
            </Col>
            <Col sm="4" md="6" lg="4">
              <AnonymousContainer isAnonymous={user.anonymous && isEdit}>
                <Form.Group controlId="formPhone">
                  <NInput
                    minWidth="auto"
                    defaultValue={formik.values?.phone}
                    label={_.upperFirst(t("phone"))}
                    name="phone"
                    // placeholder="Ingrese el apellido paterno"
                    onChangeEvent={formik.handleChange}
                    disabled={formik.isSubmitting || isDisabled}
                    errorText={formik.errors["phone"]}
                  />
                </Form.Group>
              </AnonymousContainer>
            </Col>
          </RowItem>

          <AnonymousContainer isAnonymous={user.anonymous && isEdit}>
            <RowItem className="mt-3">
              <Col sm="8">
                <Form.Group controlId="formEmail">
                  <NInput
                    minWidth="auto"
                    defaultValue={formik.values?.email}
                    label={_.upperFirst(t("email"))}
                    name="email"
                    // placeholder="Ingrese el correo"
                    onChangeEvent={formik.handleChange}
                    disabled={formik.isSubmitting || isDisabled || isEditing}
                    errorText={formik.errors["email"]}
                  />
                </Form.Group>
              </Col>
            </RowItem>
          </AnonymousContainer>
        </Col>
      </RowItem>
      <RowItem className="mt-3">
        <Col sm="12">
          <Form.Group controlId="formEmail">
            <Form.Label
              className="font-weight-bold"
              style={{ fontSize: "var(--font-size-lg)" }}
            >
              {t("select_role")}
            </Form.Label>
            <Col xs="12">
              <AnonymousContainer isAnonymous={user?.anonymous}>
                <NSearchSelect
                  id="role"
                  name="role"
                  label={null}
                  dataset={listRoles && listRoles.length > 0 ? listRoles : []}
                  onChange={selectRoles}
                  filterType={FILTER_STUDENT}
                  isMultiple={false}
                  // itemsSelected={itemsSelected}
                  showIcon={true}
                  itemObject={false}
                />
              </AnonymousContainer>
              <TextError
                text={formik.errors["role"]}
                marginTop="5px"
                icon={faExclamationCircle}
              />
            </Col>
          </Form.Group>
        </Col>
      </RowItem>

      <RowItem className="mt-3">
        <Col sm="12">
          <Form.Group controlId="formTrackingObject">
            <Form.Label
              className="font-weight-bold"
              style={{ fontSize: "var(--font-size-lg)" }}
            >
              {t("label_select_careers", {
                tracking_objects: translation("tracking_objects", "", "Cursos"),
              })}
            </Form.Label>
            <Col xs="12">
              <AnonymousContainer isAnonymous={user?.anonymous}>
                <NSearchSelect
                  id="tracking_objects"
                  name="tracking_objects"
                  label={null}
                  dataset={
                    listTrackingObject?.length > 0 ? listTrackingObject : []
                  }
                  onChange={(x) => selectItemTrackingObjects(x)}
                  filterType={FILTER_STUDENT}
                  isMultiple={true}
                  // itemsSelected={itemsSelectedTracking}
                  showIcon={true}
                  // disabled={itemsSelected.length === 0}
                  // clear={clearTracking}
                  itemObject={false}
                />
              </AnonymousContainer>
              <TextError
                text={formik.errors["tracking_objects"]}
                marginTop="5px"
                icon={faExclamationCircle}
              />
            </Col>
          </Form.Group>
        </Col>
      </RowItem>

      <RowItem className={`pt-4 ${styles.footerUserForm}`}>
        <Col>
          <NButton
            type="submit"
            className={`float-right ${styles.btnPrimary}`}
            disabled={formik.isSubmitting || isDisabled}
            style={{ height: "100%" }}
          >
            {formik.isSubmitting ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              _.upperFirst(t("save"))
            )}
          </NButton>
          <span
            className="btn float-right mr-4"
            onClick={() => history.push("/administracion#officials")}
            aria-hidden="true"
          >
            {_.upperFirst(t("cancel"))}
          </span>
        </Col>
      </RowItem>
    </form>
  );
};

export default AssociatedForm;
