import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faUser,
  faBars,
  faRandom,
  faLanguage,
  faBug,
} from "@fortawesome/pro-regular-svg-icons";

import styles from "./NavbarDashboard.module.css";
import { clearSession } from "store/actions/auth.actions";
import { cleanAllFilter } from "store/actions/site.actions";
import {
  cleanThemeColor,
  getNewClientArea,
  getClientAreas,
  clearCookies,
} from "utils";
import {
  FirstCircle,
  SecondCircle,
  ContainerNav,
  DropdownToggle,
  ContainerDesign,
  ContainerBackground,
} from "./Navbar.styled";
import ModalUser from "../ModalUser";
import { ModalReport } from "components";
import { ModalLogout } from "components/Nimbiv2";
import { toggleSidebar } from "store/actions/site.actions";
import ModalClientArea from "../ModalClientArea";
import { BackgroundDetail } from "assets/images";
import CircleButton from "./CircleButton";
import ModalLanguage from "../ModalLanguage";
import { AnonymousContainer } from "../styled";
import NBreadcrumb from "../NBreadcrumb";
import { useTranslationLocal } from "hooks";

const NNavbarDashboard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, token } = useSelector((state) => state.auth);
  const { activeModule } = useSelector((state) => state.site);
  const location = useLocation();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();

  const [show, setShow] = useState(false);
  const [showModalReport, setShowModalReport] = useState(false);
  const [showModalClientArea, setShowClientArea] = useState(false);
  const [showModalLanguage, setShowModalLanguage] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShowClientArea = () => {
    setShowClientArea(true);
  };
  const handleShowLanguage = () => setShowModalLanguage(true);
  const onCloseModalClientArea = () => setShowClientArea(false);
  const onCloseModalLanguage = () => setShowModalLanguage(false);
  const openModalReport = () => setShowModalReport(true);
  const onCloseModalReport = () => setShowModalReport((sw) => !sw);

  const isOpened = useSelector((state) => state.site.isSidebarOpen);

  useEffect(() => {
    if (!token) {
      history.replace({ pathname: "/" });
    }
  }, [token, history]);
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <DropdownToggle
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div style={{ marginRight: "8px" }}>
        <div
          style={{
            fontSize: "var(--font-size-md)",
            fontWeight: "bold",
            textAlign: "right",
          }}
          className="fontNova"
        >
          {`${user.first_name} ${user.last_name}`}
        </div>
        <div style={{ color: "#787c83", textAlign: "right" }}>
          <AnonymousContainer isAnonymous={user?.anonymous}>
            {getNewClientArea()}
          </AnonymousContainer>
        </div>
      </div>
      <FirstCircle>{getUserThumb(user?.profile_picture)}</FirstCircle>
    </DropdownToggle>
  ));

  const getUserThumb = (photo, isBig = false) => {
    if (photo) {
      return <SecondCircle src={user.profile_picture}></SecondCircle>;
    }

    return <FontAwesomeIcon icon={faUser} size={isBig ? "5x" : "2x"} />;
  };

  const openConfirmLogout = () => {
    setShowModalConfirm(true);
  };

  const logout = () => {
    dispatch(clearSession());
    dispatch(cleanAllFilter());
    cleanThemeColor();
    clearCookies();
    window.location.reload();
  };

  const getTitle = () => {
    if (props.children) {
      return props.children;
    }
    if (activeModule.breadcrumbles) {
      return (
        <NBreadcrumb
          items={activeModule.breadcrumbles}
          anonymous={user.anonymous ?? false}
        />
      );
    }
    if (user) {
      if (location.pathname === "/dashboard") {
        return `${_.capitalize(t("hello"))}, ${user.first_name}`;
      }
      return activeModule.title;
    }
    return "";
  };

  const getSubtitle = () => {
    if (props.breadcrumbles) {
      return (
        <strong style={{ zIndex: 1 }}>
          {activeModule.title ? activeModule.title : ""}
        </strong>
      );
    }
    if (location.pathname === "/dashboard") {
      return t("lest_see_to_support_student", {
        students: translation("students", "", t("students")),
      });
    }
    if (activeModule.subtitle) {
      return activeModule.subtitle ?? "";
    }
    return "";
  };

  return (
    <ContainerDesign
      fluid
      style={{ paddingTop: "16px" }}
      textwhite={props.textwhite ?? 0}
    >
      {props.activeCircle && <CircleButton />}
      <ContainerNav>
        <div
          className="mr-3 d-block d-md-none"
          style={{ cursor: "pointer", zIndex: 1 }}
        >
          <FontAwesomeIcon
            icon={faBars}
            className="d-block d-lg-none"
            onClick={() => {
              dispatch(toggleSidebar(!isOpened));
            }}
          />
        </div>
        <div
          className="mr-auto fontNova"
          style={{
            fontSize: "30px",
            fontWeight: "bold",
            zIndex: 1,
          }}
        >
          {getTitle()}
        </div>
        {user ? (
          <Dropdown style={{ zIndex: 1 }}>
            <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>

            <Dropdown.Menu alignRight>
              <Dropdown.Item onClick={handleShow}>
                <FontAwesomeIcon
                  className={`mr-2 ${styles.menuIcon}`}
                  icon={faUser}
                />
                <span className={styles.menuIcon}>
                  {_.capitalize(t("my_data"))}
                </span>
              </Dropdown.Item>
              {getClientAreas().length > 1 && (
                <Dropdown.Item onClick={handleShowClientArea}>
                  <FontAwesomeIcon
                    className={`mr-2 ${styles.menuIcon}`}
                    icon={faRandom}
                  />
                  <span className={styles.menuIcon}>{t("change_area")}</span>
                </Dropdown.Item>
              )}
              {user &&
                user.client_area_languages &&
                user.client_area_languages.length > 1 && (
                  <Dropdown.Item onClick={handleShowLanguage}>
                    <FontAwesomeIcon
                      className={`mr-2 ${styles.menuIcon}`}
                      icon={faLanguage}
                    />
                    <span className={styles.menuIcon}>
                      {t("change_language")}
                    </span>
                  </Dropdown.Item>
                )}
              <Dropdown.Item onClick={openModalReport}>
                <FontAwesomeIcon
                  className={`mr-2 ${styles.menuIcon}`}
                  icon={faBug}
                />
                <span className={styles.menuIcon}>{t("report_bug")}</span>
              </Dropdown.Item>
              {/* <Dropdown.Divider /> */}
              <Dropdown.Item onClick={openConfirmLogout}>
                <FontAwesomeIcon
                  className={`mr-2 ${styles.menuIcon}`}
                  icon={faSignOutAlt}
                />
                <span className={styles.menuIcon}>{_.capitalize(t("logout"))}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : null}

        <ModalUser user={user} handleClose={handleClose} show={show} />

        <ModalReport isVisible={showModalReport} onClose={onCloseModalReport} />

        <ModalClientArea
          show={showModalClientArea}
          handleClose={onCloseModalClientArea}
        />
        <ModalLanguage
          show={showModalLanguage}
          handleClose={onCloseModalLanguage}
        />
        <ModalLogout
          title={"¿Quieres cerrar sesión?"}
          description={"¿Ya te vas? ¡Esperamos volver a ayudarte muy pronto!"}
          onAccept={logout}
          onCancel={() => {
            setShowModalConfirm(false);
          }}
          size="lg"
          isVisible={showModalConfirm}
        />
        {props.textwhite === 1 && (
          <ContainerBackground image={BackgroundDetail} />
        )}
      </ContainerNav>
      <div
        style={
          props.breadcrumbles
            ? {
                fontSize: "24px",
                marginTop: "-15px",
                zIndex: 1,
                position: "relative",
              }
            : {
                fontSize: "var(--font-size-lg)",
                zIndex: 1,
                position: "relative",
              }
        }
      >
        <AnonymousContainer
          isAnonymous={user?.anonymous && activeModule.breadcrumbles}
          radius="5px"
        >
          {getSubtitle()}
        </AnonymousContainer>
      </div>
      {props.widgetSubtitle && (
        <div style={{ zIndex: 1, position: "relative" }}>
          {props.widgetSubtitle()}
        </div>
      )}
    </ContainerDesign>
  );
};
export default NNavbarDashboard;
