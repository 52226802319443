import React, { useEffect, useState } from "react";
import _ from "lodash";
import styles from "containers/Report/Report.module.css";
import { Tab, Col, Container } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

import { useDefault, useTranslationLocal } from "hooks";
import { ReportComponents } from "containers";
import { fetchReports } from "store/actions/async/reports-async.actions";
import { setActiveModule } from "store/actions/site.actions";
import { NNavbarDashboard, ScrollToTopOnMount } from "components/Nimbiv2";
import { NTabs, RowItem } from "components/Nimbiv2/styled";
import CardReportSkeletons from "components/Skeletons/CardReport";

const Reports = () => {
  const { id: reportId } = useParams();
  const dispatch = useDispatch();
  const {
    list: reports,
    pending: { list: isPending },
  } = useSelector((state) => state.report);
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const selectedReport = reports.find((e) => `${e.id}` === reportId);
  const reportModuleTitle = useDefault(
    "reportModuleTitle",
    _.upperFirst(t("reports"))
  );
  const [reportList, setReportList] = useState([]);
  const [groupedReports, setGroupedReports] = useState({});
  const [selectTab, setSelectTab] = useState("all");

  useEffect(() => {
    dispatch(fetchReports());
  }, [dispatch]);

  useEffect(() => {
    if (selectedReport) {
      dispatch(
        setActiveModule({
          title: selectedReport.name,
          subtitle: null,
        })
      );
    } else {
      if (reportModuleTitle) {
        dispatch(
          setActiveModule({
            title: reportModuleTitle,
            subtitle: t("page_report_subtitle", {
              students: translation("students", "", t("students")),
            }),
          })
        );
      }
    }
  }, [dispatch, reportModuleTitle, selectedReport, t, translation]);

  const sortCategories = (reports) => {
    return reports.sort((first, second) => {
      return first.category["weight"] - second.category["weight"];
    });
  };

  const sortReports = (reports) => {
    if (reports) {
      return reports.sort((first, second) => {
        return first.weight - second.weight;
      });
    }
    return [];
  };

  const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      // eslint-disable-next-line no-param-reassign
      (rv[x.category[key]] = rv[x.category[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    setReportList(reports);
    let reportGroup = [...reports];
    setGroupedReports(groupBy(sortCategories(reportGroup), "name"));
  }, [reports]);

  const filterGroup = (item) => {
    setSelectTab(item);
    if (item === "all") {
      setReportList(sortReports(reports));
    } else {
      setReportList(sortReports(groupedReports[item]));
    }
  };

  return (
    <>
      <ScrollToTopOnMount />
      <NNavbarDashboard breadcrumbles={selectedReport ? true : false}>
        {selectedReport ? (
          <ReportBreadcrumb filterGroup={filterGroup} report={selectedReport} />
        ) : null}
      </NNavbarDashboard>
      {selectedReport ? (
        <ReportComponents.ReportView report={selectedReport} />
      ) : (
        <Container fluid style={{ marginTop: "48px" }}>
          <RowItem>
            <Col lg="12" style={{ padding: "0px" }}>
              <NTabs
                activeKey={selectTab}
                id="tabs-reports"
                className="mb-3"
                onSelect={filterGroup}
              >
                <Tab eventKey="all" title={_.upperFirst(t("all"))}></Tab>
                {Object.keys(groupedReports).map((group) => (
                  <Tab key={group} eventKey={group} title={group}></Tab>
                ))}
              </NTabs>
            </Col>

            {isPending && (
              <div style={{ width: "100%" }}>
                <RowItem>
                  {[1, 2, 3, 4].map((item, key) => {
                    return (
                      <Col
                        lg="6"
                        xs="12"
                        className="mb-3"
                        key={`skeletons-card-${key}`}
                      >
                        <CardReportSkeletons />
                      </Col>
                    );
                  })}
                </RowItem>
              </div>
            )}
            {!isPending && <ReportComponents.ReportList reports={reportList} />}
          </RowItem>
        </Container>
      )}
    </>
  );
};

export default Reports;

const ReportBreadcrumb = ({ report, filterGroup }) => {
  const { t } = useTranslation();
  return (
    <div
      className={styles.breadcrumb}
      style={{ fontSize: "var(--font-size-md)" }}
    >
      <Link
        className={styles.reportRoot}
        to="/reportes"
        onClick={() => {
          filterGroup("all");
        }}
      >
        {_.upperFirst(t("reports"))}
      </Link>
      <FontAwesomeIcon
        style={{ marginLeft: "14px", marginRight: "14px", fontSize: "10px" }}
        icon={faChevronRight}
      />
      <Link
        to="/reportes"
        className={styles.reportRoot}
        onClick={() => {
          filterGroup(report.category.name);
        }}
      >
        {`${report.category.name}`}
      </Link>
      <span className={styles.reportTitle}>
        <FontAwesomeIcon
          style={{ marginLeft: "14px", marginRight: "14px", fontSize: "10px" }}
          icon={faChevronRight}
        />
        {`${report.name}`}
      </span>
    </div>
  );
};
