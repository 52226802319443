import React, { useEffect } from "react";
import _ from "lodash";

import { NTable } from "components/Nimbiv2";
import { CardNimbi } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchProgressAttendance } from "store/actions/async/student-async.actions";
import { fetchPeriods } from "store/actions/async/crm-async.action";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import { getExtraColumns } from "utils";

const AttendancePage = ({
  student_tracking_id,
  callFetch,
  showSelect = true,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    attendance,
    pending: { attendance: isPending },
  } = useSelector((state) => state.student);
  const {
    periods,
    pending: { periods: isPendingPeriods },
  } = useSelector((state) => state.crm);

  useEffect(() => {
    // call endpoint
    dispatch(fetchProgressAttendance(student_tracking_id));
    dispatch(
      fetchPeriods({
        student_tracking_id: student_tracking_id,
        model: "attendances",
      })
    );
  }, [dispatch, student_tracking_id]);

  const columns = [
    {
      title: _.upperFirst(t("modules")),
      key: "course_name",
      dataIndex: "course_name",
      width: "200",
      fixed: true,
      style: { left: 0 },
      render: (item, key) => <div>{item}</div>,
    },
    {
      title: _.upperFirst(t("average_attendance")),
      key: "note",
      dataIndex: "average_attendance",
      width: "200",
      fixed: true,
      style: { left: 0 },
      render: (item, key) => (
        <div>{item && item !== " " && item !== "" ? `${item}%` : "-"}</div>
      ),
    },
    ...getExtraColumns("tableAttendance", false),
  ];

  const callProgressAttendance = (period) => {
    if (!isPendingPeriods) {
      if (period)
        dispatch(
          fetchProgressAttendance(student_tracking_id, { periods: period })
        );
      else dispatch(fetchProgressAttendance(student_tracking_id));
    }
  };

  return (
    <div>
      {showSelect && periods && periods.length > 0 && (
        <div>
          <ContainerSelect style={{ marginBottom: "16px" }}>
            <Form.Control
              as="select"
              onChange={(event) => {
                callProgressAttendance(event.target.value);
              }}
            >
              <option value={""}>{t("select_period")}</option>
              {periods.map((item, key) => {
                return (
                  <option key={`${item.id}-${key}`} value={item.period}>
                    {item.period}
                  </option>
                );
              })}
            </Form.Control>
          </ContainerSelect>
        </div>
      )}
      <CardNimbi
        radius="8px"
        padding="23px 26px"
        style={{ height: "fit-content" }}
      >
        <NTable
          columns={columns}
          textEmpty="No hay asistencias para mostrar"
          dataSource={attendance?.progress ?? []}
          fixed={true}
          isPending={isPending}
        ></NTable>
      </CardNimbi>
    </div>
  );
};

export default AttendancePage;

const ContainerSelect = styled.div`
  .form-control {
    background: transparent;
    padding: 14px 12px;
    height: auto;
    width: auto;
  }
`;
