import React from "react";
import _ from "lodash";
import { CardNimbi } from "components/Nimbiv2/styled";
import { AcademicAdvancement } from "containers/Task/detail";
import { useTranslation } from "react-i18next";
import SelectStudentTracking from "./SelectStudentTracking";

const PageAcedemicAdvancement = ({
  student_tracking_id,
  selectTracking,
  changeTrackingObject = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <CardNimbi padding="16px 26px">
      <div className="d-flex justify-content-between">
        <div style={{ fontWeight: "600", fontSize: "20px" }}>
          {_.upperFirst(t("Avance académico"))}
        </div>
        <div>
          <SelectStudentTracking
            selectTracking={selectTracking}
            changeTrackingObject={changeTrackingObject}
          />
        </div>
      </div>
      <AcademicAdvancement
        student_tracking_id={student_tracking_id}
        callFetch={true}
      />
    </CardNimbi>
  );
};
export default PageAcedemicAdvancement;
