import React from "react";
import styled from "styled-components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { faFlag, faProjectDiagram } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CardEmptyDashboard, NTable, NButton } from "components/Nimbiv2";
import { AnonymousContainer, CardNimbi } from "components/Nimbiv2/styled";
import {
  applyInitiativeFilter,
  applyMomentFilter,
  filterMomentActive,
} from "utils/moments";
import { getDataFromLocalStorage } from "utils";
import { useTranslationLocal } from "hooks";
import { permissionChecker } from "routes/helpers";
import { setTabSelectTask } from "store/actions/crm.actions";
import { ButtonSize, colorProgress } from "utils/constants";
import { NBadge } from "components";
import EmptyActionPlanBoard from "assets/images/EmptyActionPlanBoard";
import EmptyInitiativeBoard from "assets/images/EmptyInitiativeBoard";

const ListContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const {
    pending: { stats: isPending },
    stats: { moments, initiatives, count_initiatives },
  } = useSelector((state) => state.dashboard);
  const { user } = useSelector((state) => state.auth);
  const momentsActive = filterMomentActive(moments ?? []);

  const columns = [
    {
      title: translation("name"),
      key: "name",
      style: { minWidth: "160px" },
      render: (data) => {
        return (
          <strong className="font-size-caption">
            <AnonymousContainer isAnonymous={user?.anonymous}>
              {data.name}
            </AnonymousContainer>
          </strong>
        );
      },
    },
    {
      title: translation("students"),
      dataIndex: "all_students",
      key: "all_students",
      center: true,
      render: (all_students) => (
        <div className="text-center">
          <div className="font-size-caption">{all_students}</div>
          {1 === 0 && (
            <div style={{ color: "red", fontSize: "10px" }}>
              Pronto a expirar
            </div>
          )}
        </div>
      ),
    },
    {
      title: translation("pending"),
      dataIndex: "all_students",
      key: "all_students",
      center: true,
      render: (all_students, item) => (
        <div className="text-center">
          <NBadge
            text={String(item["all_tasks"] - item["completed_tasks"])}
            customStyle={{ borderRadius: "64px", padding: "4px 10px" }}
          >
            <div className="font-size-caption"></div>
          </NBadge>
        </div>
      ),
    },
    {
      title: translation("progress"),
      dataIndex: "task",
      key: "task",
      width: "20%",
      render: (text, item) => (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip-top">
              <strong>{item["completed_tasks"]} </strong>/ {item["all_tasks"]}{" "}
            </Tooltip>
          }
        >
          <div>
            <div className="font-size-caption">
              <strong>
                {item["all_tasks"] <= 0 && item["completed_tasks"] <= 0
                  ? "100"
                  : item["percentage"]}
                %
              </strong>
            </div>

            <div style={{ marginTop: "5px" }}>
              {item["percentage"] <= 100 && item["percentage"] >= 70 && (
                <ProgressBarN
                  now={item["percentage"]}
                  color={colorProgress.success}
                />
              )}
              {item["percentage"] < 70 && item["percentage"] >= 40 && (
                <ProgressBarN
                  now={item["percentage"]}
                  color={colorProgress.warning}
                />
              )}
              {item["percentage"] < 40 && item["percentage"] >= 0 && (
                <ProgressBarN
                  now={item["percentage"]}
                  color={colorProgress.danger}
                />
              )}
            </div>
          </div>
        </OverlayTrigger>
      ),
    },
    {
      title: "",
      dataIndex: "accions",
      key: "accions",
      width: "15%",
      fixed: true,
      style: { right: "0px" },
      render: (text, item) => (
        <>
          {item?.count_workflow_call > 0 && (
            <NButton
              sizeStyle={ButtonSize.sm}
              outlined
              color="second"
              onClick={() => {
                localStorage.removeItem("filters");
                localStorage.removeItem("clientFilters");
                applyMomentFilter(dispatch, item["moment_id"], 10, false, true);
                dispatch(setTabSelectTask("moments"));
                history.push("/tareas");
              }}
              style={{ width: "105px" }}
            >
              {t("see_tasks")}
            </NButton>
          )}
          {item?.count_workflow_call === 0 && <div>---</div>}
        </>
      ),
    },
  ];

  const columnsInitiativa = [
    {
      title: translation("name"),
      key: "name",
      style: { minWidth: "160px" },
      render: (data) => {
        return (
          <strong className="font-size-caption">
            <AnonymousContainer isAnonymous={user?.anonymous}>
              {data.name}
            </AnonymousContainer>
          </strong>
        );
      },
    },
    {
      title: translation("duration"),
      dataIndex: "all_students",
      key: "all_students",
      width: "19%",
      render: (all_students, item) => (
        <div>
          <div style={{ fontSize: "var(--font-size-small)" }}>
            {moment(item["start_date"]).format("DD/MM")}
            {item["end_date"] ? " - " : ""}
            {item["end_date"] ? moment(item["end_date"]).format("DD/MM") : ""}
          </div>
        </div>
      ),
    },
    {
      title: translation("pending"),
      dataIndex: "task",
      key: "task",
      center: true,
      render: (text, item) => (
        <div className="text-center">
          <NBadge
            text={String(item["all_tasks"] - item["completed_tasks"])}
            customStyle={{ borderRadius: "64px", padding: "4px 10px" }}
          >
            {item["all_tasks"] - item["completed_tasks"]}
          </NBadge>
        </div>
      ),
    },
    {
      title: translation("progress"),
      dataIndex: "task",
      key: "task",
      width: "20%",
      render: (text, item) => (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip-top">
              <strong>{item["completed_tasks"]} </strong>/ {item["all_tasks"]}{" "}
            </Tooltip>
          }
        >
          <div>
            <div className="font-size-caption">
              <strong>{item["percentage"]}%</strong>
            </div>
            <div style={{ marginTop: "5px" }}>
              {item["percentage"] <= 100 && item["percentage"] >= 70 && (
                <ProgressBarN
                  now={item["percentage"]}
                  color={colorProgress.success}
                />
              )}
              {item["percentage"] < 70 && item["percentage"] >= 40 && (
                <ProgressBarN
                  now={item["percentage"]}
                  color={colorProgress.warning}
                />
              )}
              {item["percentage"] < 40 && item["percentage"] >= 0 && (
                <ProgressBarN
                  now={item["percentage"]}
                  color={colorProgress.danger}
                />
              )}
            </div>
          </div>
        </OverlayTrigger>
      ),
    },
    {
      title: "",
      dataIndex: "accions",
      key: "accions",
      width: "auto",
      fixed: true,
      style: { right: "0px" },
      render: (text, item) => (
        <>
          {item["type_initiative"] === "Call" && (
            <NButton
              sizeStyle={ButtonSize.sm}
              outlined
              color="second"
              onClick={() => {
                localStorage.removeItem("filters");
                localStorage.removeItem("clientFilters");
                applyInitiativeFilter(
                  dispatch,
                  item["initiative_id"],
                  10,
                  false,
                  false,
                  item["source_type"]
                );
                dispatch(setTabSelectTask("initiatives"));
                history.push("/tareas");
              }}
              style={{ minWidth: "105px" }}
            >
              {t("see_tasks")}
            </NButton>
          )}
          {item["type_initiative"] === "Email" && <div></div>}
        </>
      ),
    },
  ];

  const getEmptyInitiative = () => {
    return (
      <CardEmptyDashboard
        title={t("initiatives")}
        subtitle={t("you_have_no_active_initiatives")}
        description={t("do_you_want_to_create_initiative", {
          students: translation("students", "", t("students")),
        })}
        textButton={t("create_initiative")}
        imageBoard={<EmptyInitiativeBoard />}
        onClick={() => {
          history.push("/campañas/add");
        }}
      />
    );
  };

  const getEmptyMoment = () => {
    return (
      <CardEmptyDashboard
        title={t("action_plans")}
        subtitle={t("you_have_no_active_actions_plans")}
        description={t("the_next_action_plan_2")}
        textButton={t("see_action_plans")}
        imageBoard={<EmptyActionPlanBoard />}
        onClick={() => {
          history.push("/planes-de-accion");
        }}
      />
    );
  };
  const { groups = [] } = getDataFromLocalStorage("user", {});
  const access = permissionChecker({
    allowedCode: "initiatives_tasks",
    userRoles: groups,
  });
  return (
    <Container style={{ marginTop: "40px" }} fluid>
      <Row className={`pl-1 pr-1`}>
        <Col lg="6">
          <TitleCard>
            <FontAwesomeIcon
              icon={faProjectDiagram}
              style={{ fontSize: "14px" }}
              className="mr-2 ml-3"
            />
            <strong style={{ marginRight: "5px", fontSize: "20px" }}>
              {t("action_plans_active")}{" "}
            </strong>{" "}
            ({momentsActive.length ?? 0})
          </TitleCard>
          <CardNimbi
            padding="0px 12px 12px 12px"
            style={{ marginTop: "20px", height: "auto" }}
          >
            <NTable
              columns={columns}
              dataSource={momentsActive}
              check={false}
              upperCaseHeader
              paddingHeader="12px"
              widgetEmpty={getEmptyMoment}
              isPending={isPending}
              widthSkeletons={500}
              heightSkeletons={250}
            />
            {momentsActive && momentsActive.length > 5 && (
              <ButtonShowAll
                onClick={() => {
                  history.push("/planes-de-accion");
                }}
              >
                {t("see_all")}
              </ButtonShowAll>
            )}
          </CardNimbi>
        </Col>
        {access && (
          <Col lg="6">
            <TitleCard className="mt-md-0 mt-3">
              <FontAwesomeIcon icon={faFlag} className="mr-2 ml-3" />
              <strong style={{ marginRight: "5px", fontSize: "20px" }}>
                {t("active_initiatives")}
              </strong>
              ({count_initiatives ?? 0})
            </TitleCard>
            <CardNimbi
              padding="0px 12px 12px 12px;"
              style={{ marginTop: "20px", height: "auto" }}
            >
              <NTable
                columns={columnsInitiativa}
                dataSource={initiatives}
                check={false}
                upperCaseHeader
                widgetEmpty={getEmptyInitiative}
                isPending={isPending}
                widthSkeletons={500}
                heightSkeletons={250}
              />
              {!isPending && count_initiatives > 5 && (
                <ButtonShowAll
                  onClick={() => {
                    history.push("/campañas");
                  }}
                >
                  {t("see_all")}
                </ButtonShowAll>
              )}
            </CardNimbi>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default ListContainer;

const ProgressBarN = styled(ProgressBar)`
  background-color: #dfe4ed;
  height: 6px !important;
  .progress-bar {
    background-color: ${(props) => props.color ?? "#0fcdb3"};
  }
`;
const TitleCard = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--font-size-lg);
`;

const ButtonShowAll = styled.div`
  border-top: 1px solid #dde3ec;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  font-size: var(--font-size-md);
  color: #334fff;
  font-weight: 400;
  cursor: pointer;
`;
