/* eslint-disable no-param-reassign */
import {
  GET_STUDENTS,
  SET_STUDENTS,
  UPDATE_STUDENT_INFO,
  SET_STUDENT_TAGS,
  TOGGLE_STAG_STATUS,
  TOGGLE_CHECKED,
  TOGGLE_CHECKED_PAGE,
  TOGGLE_MARK_ITEMS,
  TOGGLE_LIST_CHECKABLE,
  REGISTER_ERROR,
  SET_NOTES,
  TOGGLE_CHECKED_ARRAY,
  SET_CUSTOM_PERSON,
  SET_STUDENT_INSIGHTS,
  SET_STUDENT_RISK,
  GET_CUSTOM_PERSON,
  SET_COURSE_SELECTED,
  CLEAN_CUSTOM_PERSON,
  GET_STUDENT_INSIGHTS,
  GET_ACADEMIC_PROGRESS,
  SET_ACADEMIC_PROGRESS,
  SET_PROGRESS_ATTENDANCE,
  SET_PROGRESS_GRADES,
  GET_PROGRESS_GRADES,
  GET_PROGRESS_ATTENDANCE,
  GET_ENTERABLE_ACTION_PLANS,
  SET_ENTERABLE_ACTION_PLANS,
  GET_CHANGE_LOGS,
  SET_CHANGE_LOGS,
  GET_GENERATION_EVENTS,
  SET_GENERATION_EVENTS,
  GET_RISK_HISTORY,
  SET_RISK_HISTORY,
  GET_SURVEY_ANSWERS,
  SET_SURVEY_ANSWERS,
  GET_MILESTONE_STUDENT,
  SET_MILESTONE_STUDENT,
  SET_COURSE_SELECTED_CLEAN,
} from "store/actions/student.actions";
import { Student } from "models";

const paginationDefault = {
  count: 0,
  next: null,
  previous: null,
  page: 1,
  pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
};

const initialState = {
  list: [],
  customInfoPerson: [],
  insights: [],
  risk: {},
  historyRisk: [],
  academicProgress: null,
  grades: null,
  attendance: null,
  enterable_action_plans: null,
  pagination: paginationDefault,
  generationEvents: [],
  pending: {
    list: false,
    customInfoPerson: false,
    insightsPending: false,
    academicProgress: false,
    grades: false,
    attendance: false,
    enterable_action_plans: false,
    changeLogs: false,
    generationEvents: false,
    historyRisk: false,
  },
  studentTags: [],
  wholePageMarked: false,
  markAllItems: false,
  listCheckable: false,
  hasSelect: false,
  courseSelected: null,
  academicStudentId: null,
  changeLogs: [],
  paginationChangeLogs: paginationDefault,
  paginationRisk: {
    count: 0,
    next: null,
    previous: null,
    page: 1,
    pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  },
};

const studentReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENTS: {
      return {
        ...state,
        pending: { ...state.pending, list: true },
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          pageSize: action.payload.page_size,
        },
      };
    }

    case SET_STUDENTS: {
      const { count, next, previous, results } = action.payload;
      const students = new Student([...results]).getList();
      let auxPagination = { ...state.pagination };
      auxPagination = { ...auxPagination, count, next, previous };

      students.forEach((student) => {
        student.isOpened = false;
        student.isDisabled = false;
        student.isChecked = false;
      });

      return {
        ...state,
        list: students,
        pending: { ...state.pending, list: false },
        pagination: auxPagination,
      };
    }

    case UPDATE_STUDENT_INFO: {
      const studenId = action.payload.id;
      const auxList = [...state.list];
      const pos = auxList.findIndex((student) => student.id === studenId);
      auxList[pos] = new Student(action.payload);

      return { ...state, list: auxList };
    }

    case SET_STUDENT_TAGS: {
      const finalTags = [...action.payload.results].map((t) => {
        return { ...t, value: t.tag, selected: false };
      });

      return { ...state, studentTags: finalTags };
    }

    case TOGGLE_STAG_STATUS: {
      const { tagName, sw } = action.payload;
      const auxTags = [...state.studentTags];

      if (sw) {
        auxTags.map((tag) => {
          // eslint-disable-next-line no-param-reassign
          tag.selected = tag.tag === tagName ? (tag.selected = sw) : !sw;
          return tag;
        });
      } else {
        const wantedTag = auxTags.find((tag) => tag.tag === tagName);
        wantedTag.selected = false;
      }

      return { ...state, studentTags: auxTags };
    }

    case TOGGLE_CHECKED: {
      const { idList, sw } = action.payload;
      const auxStudents = [...state.list];

      Array.from(idList).forEach((id) => {
        auxStudents.find((t) => t.id === id).isChecked = sw;
      });

      return { ...state, list: auxStudents };
    }

    case TOGGLE_CHECKED_ARRAY: {
      const { listID } = action.payload;
      const auxStudents = [...state.list];
      let markAllItemsAux = false;
      Array.from(auxStudents).forEach((student) => {
        if (listID.length > 0) {
          if (listID[0] === 0) {
            markAllItemsAux = true;
            student.isChecked = true;
          }
        }
        if (!markAllItemsAux) {
          if (listID.includes(student.id)) {
            student.isChecked = true;
          } else {
            student.isChecked = false;
          }
        }
      });

      return {
        ...state,
        list: auxStudents,
        markAllItems: markAllItemsAux,
        hasSelect: true,
      };
    }

    case TOGGLE_CHECKED_PAGE: {
      const auxStudents = [...state.list];

      Array.from(auxStudents).forEach((student) => {
        student.isChecked = action.payload;
      });

      return { ...state, list: auxStudents };
    }

    case TOGGLE_MARK_ITEMS: {
      const { key, value } = action.payload;
      let hasSelect = true;
      if ("markAllItems" === key && value === false) {
        hasSelect = false;
      }
      return { ...state, [key]: value, hasSelect: hasSelect };
    }

    case TOGGLE_LIST_CHECKABLE: {
      return { ...state, listCheckable: action.payload };
    }

    case REGISTER_ERROR: {
      const auxPending = { ...state.pending };
      if (action.payload.key) auxPending[action.payload.key] = false;

      return { ...state, pending: auxPending };
    }

    case SET_NOTES: {
      const { studentID, notes } = action.payload;
      let auxList = [...state.list];
      const pos = auxList.findIndex((row) => row.id === studentID);

      if (pos >= 0) {
        auxList[pos].notes = notes;
      } else {
        auxList = [{ id: studentID, notes }];
      }

      return { ...state, list: auxList };
    }

    case GET_CUSTOM_PERSON: {
      return {
        ...state,
        customInfoPerson: [],
        pending: { ...state.pending, customInfoPerson: true },
      };
    }

    case SET_CUSTOM_PERSON: {
      const results = action.payload;

      return {
        ...state,
        customInfoPerson: results,
        pending: { ...state.pending, customInfoPerson: false },
      };
    }
    case GET_RISK_HISTORY: {
      return {
        ...state,
        historyRisk: [],
        pending: { ...state.pending, historyRisk: true },
        paginationRisk: {
          ...state.paginationRisk,
          page: action.payload.page,
          pageSize: action.payload.page_size,
        },
      };
    }
    case SET_RISK_HISTORY: {
      const { count, next, previous, results } = action.payload;
      let auxPagination = { ...state.paginationRisk };
      auxPagination = { ...auxPagination, count, next, previous };

      return {
        ...state,
        historyRisk: results,
        pending: { ...state.pending, historyRisk: false },
        paginationRisk: auxPagination,
      };
    }

    case GET_STUDENT_INSIGHTS: {
      return {
        ...state,
        insights: [],
        pending: { ...state.pending, insightsPending: true },
      };
    }

    case SET_STUDENT_INSIGHTS: {
      const { insights } = action.payload;

      return {
        ...state,
        insights: insights,
        pending: { ...state.pending, insightsPending: false },
      };
    }

    case SET_STUDENT_RISK: {
      const { risk } = action.payload;

      return { ...state, risk: risk };
    }

    case SET_COURSE_SELECTED: {
      const { courseSelected } = action.payload;

      return { ...state, courseSelected: courseSelected };
    }

    case SET_COURSE_SELECTED_CLEAN: {
      return { ...state, courseSelected: null };
    }

    case CLEAN_CUSTOM_PERSON: {
      return {
        ...state,
        customInfoPerson: [],
      };
    }

    case GET_ACADEMIC_PROGRESS: {
      const academic_id = action.payload;
      return {
        ...state,
        academicProgress: [],
        pending: { ...state.pending, academicProgress: true },
        academicStudentId: academic_id,
      };
    }

    case SET_ACADEMIC_PROGRESS: {
      const results = action.payload;
      return {
        ...state,
        academicProgress: results,
        pending: { ...state.pending, academicProgress: false },
      };
    }
    case GET_PROGRESS_GRADES: {
      return {
        ...state,
        grades: [],
        pending: { ...state.pending, grades: true },
      };
    }

    case SET_PROGRESS_GRADES: {
      const results = action.payload;
      return {
        ...state,
        grades: results,
        pending: { ...state.pending, grades: false },
      };
    }
    case GET_PROGRESS_ATTENDANCE: {
      return {
        ...state,
        grades: [],
        pending: { ...state.pending, attendance: true },
      };
    }

    case SET_PROGRESS_ATTENDANCE: {
      const results = action.payload;
      return {
        ...state,
        attendance: results,
        pending: { ...state.pending, attendance: false },
      };
    }

    case GET_ENTERABLE_ACTION_PLANS: {
      return {
        ...state,
        enterable_action_plans: [],
        pending: { ...state.pending, enterable_action_plans: true },
      };
    }
    case SET_ENTERABLE_ACTION_PLANS: {
      const results = action.payload;
      return {
        ...state,
        enterable_action_plans: results,
        pending: { ...state.pending, enterable_action_plans: false },
      };
    }
    case GET_CHANGE_LOGS: {
      return {
        ...state,
        pending: { ...state.pending, changeLogs: true },
        paginationChangeLogs: {
          ...state.paginationChangeLogs,
          page: action.payload.page,
          pageSize: action.payload.page_size,
        },
      };
    }

    case SET_CHANGE_LOGS: {
      const { count, next, previous, results } = action.payload;
      let auxPagination = { ...state.paginationChangeLogs };
      auxPagination = { ...auxPagination, count, next, previous };

      return {
        ...state,
        changeLogs: results,
        pending: { ...state.pending, changeLogs: false },
        paginationChangeLogs: auxPagination,
      };
    }

    case GET_GENERATION_EVENTS: {
      return {
        ...state,
        generationEvents: [],
        pending: { ...state.pending, generationEvents: true },
      };
    }

    case SET_GENERATION_EVENTS: {
      const results = action.payload;

      return {
        ...state,
        generationEvents: results,
        pending: { ...state.pending, generationEvents: false },
      };
    }

    case GET_SURVEY_ANSWERS: {
      return {
        ...state,
        surveyAnwers: [],
        pending: { ...state.pending, surveyAnwers: true },
      };
    }

    case SET_SURVEY_ANSWERS: {
      const results = action.payload;
      return {
        ...state,
        surveyAnwers: results,
        pending: { ...state.pending, surveyAnwers: false },
      };
    }

    case GET_MILESTONE_STUDENT: {
      return {
        ...state,
        milestoneStudent: [],
        pending: { ...state.pending, milestoneStudent: true },
      };
    }

    case SET_MILESTONE_STUDENT: {
      const results = action.payload;
      return {
        ...state,
        milestoneStudent: results,
        pending: { ...state.pending, milestoneStudent: false },
      };
    }

    default:
      return { ...state };
  }
};

export default studentReducers;
