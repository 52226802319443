import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import {
  faLightbulbOn,
  faStickyNote,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardNimbi,
  CardNimbiFixed,
  IconContainer,
} from "components/Nimbiv2/styled";
import { useSelector } from "react-redux";
import styles from "components/NProfileTabs/NProfileTabs.module.css";
import { useTranslation } from "react-i18next";
import { ContainerEmpty, NButton } from "components/Nimbiv2";
import EmptyListSVG from "assets/images/EmptyListSVG";
import FormSlideObservation from "./FormSlideObservation";
import { getGlobalConfig } from "utils";

const ObservationPage = ({ studentID, isModal = false }) => {
  const { t } = useTranslation();
  const { list } = useSelector((state) => state.student);

  const [notes, setAllNotes] = useState([]);
  const [showSlide, setShowSlide] = useState(false);

  const version3 = getGlobalConfig("enable_student_version_3", false);
  useEffect(() => {
    if (!studentID || list.length === 0) {
      setAllNotes(null);
      return null;
    }
    const student = list.find((row) => row.id === studentID);
    if (!student) {
      setAllNotes(null);
      return null;
    }
    const { notes: allNotes = [] } = student;
    setAllNotes(allNotes);
  }, [list, studentID]);

  return (
    <CardNimbi radius="8px" padding="0px" style={{ height: "100%" }}>
      <CardNimbi padding="16px 26px">
        <div className="d-flex align-items-center">
          {!version3 && (
            <IconContainer style={{ marginRight: "13px" }}>
              <FontAwesomeIcon
                icon={faStickyNote}
                style={{ fontSize: "18px" }}
              />
            </IconContainer>
          )}
          <strong style={{ fontSize: "var(--font-size-lg)" }}>
            {_.upperFirst(t("observations"))}
          </strong>
        </div>
        {notes && notes.length === 0 && (
          <ContainerEmpty
            version="2"
            image={<EmptyListSVG />}
            text={t("no_observations")}
          />
        )}
        <div style={isModal ? { maxHeight: "200px", overflow: "scroll" } : {}}>
          {notes &&
            notes.length > 0 &&
            notes.map((item) => {
              const fullName = `${item.user.first_name} ${item.user.last_name}`;
              const createdAt = moment(item.created_at).format(
                "DD-MM-YYYY HH:mm:SS"
              );
              return (
                <div
                  key={item.id}
                  className={`d-flex ${styles.noteBlock}`}
                  style={{
                    paddingTop: "10px",
                    marginTop: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <div className={styles.noteEntry} style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item.is_starring && (
                        <IconContainer
                          style={{ marginRight: "13px" }}
                          title={t("starred_note")}
                        >
                          <FontAwesomeIcon
                            icon={faLightbulbOn}
                            style={{ fontSize: "18px" }}
                          />
                        </IconContainer>
                      )}
                      {item.note}
                    </div>
                    <div
                      className={`d-flex justify-content-end ${styles.noteInfo}`}
                    >
                      {`${fullName}.  `}
                      <span>{createdAt}</span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </CardNimbi>
      <CardNimbiFixed
        style={{
          height: "auto",
          boxShadow: "0px -10px 30px 0px #7F7F7F29",
          position: "sticky",
          top: 0,
        }}
      >
        <NButton
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => {
            setShowSlide(!showSlide);
          }}
        >
          {t("new_observation")}
        </NButton>
      </CardNimbiFixed>
      <FormSlideObservation
        open={showSlide}
        studentID={studentID}
        onClose={() => {
          setShowSlide(false);
        }}
      />
    </CardNimbi>
  );
};
export default ObservationPage;
