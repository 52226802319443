import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const MenuItemStudent = ({ title, active, onClick = () => {} }) => {
  return (
    <MenuItemStudentDesign active={active} onClick={onClick}>
      <div className="mr-auto">{title}</div>
      {active && <FontAwesomeIcon icon={faChevronRight} color="#334FFF" />}
    </MenuItemStudentDesign>
  );
};
export default MenuItemStudent;

const MenuItemStudentDesign = styled.div`
  padding: 10.5px 16px 10.5px 8px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.active ? "border-radius: 8px; background-color: #ECF2FF;" : ""}
`;
