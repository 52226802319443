import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { StepTitle, TextTitle } from "components/Nimbiv2/InitiativesComponent";
import { Col, Row } from "react-bootstrap";
import ListOptionClip from "components/Nimbiv2/ListOptionClip";
import { NSpinnerPoints, NButton, NIcon } from "components/Nimbiv2";
import { CardNimbi } from "components/Nimbiv2/styled";
import IniciativeModal from "components/Nimbiv2/IniciativeModal";
import moment from "moment";
import { toast } from "react-toastify";
import { createInitiative, updateInitiative } from "services/initiativeService";
import { useDispatch, useSelector } from "react-redux";
import { fetchListInitiatives } from "store/actions/async/initiatives-async.actions";
import { APP_MODULES, getGlobalConfig } from "utils";
import { useTranslation } from "react-i18next";
import {
  CardInfoInitiativeResume,
  CardTemplateInitiativeResume,
  StudentsInitiativeResume,
  CardFileInitiativeResume,
} from "./CardsResume";
import { ContainerTitle, TitleCard } from "./CardsResume/styles";
import { ButtonSize } from "utils/constants";
import CardQuestionResume from "./CardsResume/CardQuestionResume";
import { isEmptyArray } from "formik";

const positionsForm = {
  normal: [
    "selectInitiative",
    "selectType",
    "addInfo",
    "selectTemplate",
    "selectStudent",
    "resume",
  ],
  external: [
    "selectInitiative",
    "selectType",
    "addFile",
    "addInfo",
    "selectTemplate",
    "resume",
  ],
  clone: [
    "selectInitiative",
    "selectType",
    "selectClone",
    "addInfo",
    "selectStudent",
    "resume",
  ],
  edit: ["addInfo", "selectTemplate", "selectStudent", "resume"],
};
const panel = APP_MODULES.INITIATIVES.ID;
const PageFifth = ({
  onChange,
  setPosition,
  formValues,
  pageNumber,
  edit = false,
  id = null,
  typeFormList = [],
  validateAll = () => {},
  sucesssSave = () => {},
}) => {
  const { t } = useTranslation();
  const { clientFilters } = useSelector((state) => state.site);
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [selectedOptions, setSelectedOption] = useState([]);
  const [selectedCreate, setSelectedCreate] = useState([]);
  const [loadingActive, setLoadingActive] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [objectCreate, setObjectCreate] = useState({});
  const [textModal, setTextModal] = useState({
    title: t("campaign_created_successfully"),
    description: t("check_compaign_monitor_progress"),
  });
  useEffect(() => {
    validateAll();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { listTypeInitiatives, paginationTracking } = useSelector(
    (state) => state.initiative
  );
  const { user } = useSelector((state) => state.auth);

  const options = {
    new: {
      icon: "sparkles",
      title: t("new_initiative"),
      value: "new",
    },
    copy: {
      icon: "copy",
      title: t("campaign_designed"),
      value: "copy",
    },
    internal: {
      icon: "house",
      title: t("nimbi_intern"),
      value: "internal",
    },
    external: {
      icon: "excel",
      title: "Externa (carga archivo)",
      value: "external",
    },
  };

  const client_area_max_internal_management = useSelector(
    (state) => state.auth.user.client_area_max_internal_management
  );

  const getItemTypeInitiative = (value) => {
    for (let i = 0; i < listTypeInitiatives.length; i++) {
      if (listTypeInitiatives[i].name === value) {
        return {
          icon: listTypeInitiatives[i].icon,
          title: listTypeInitiatives[i].front_name,
          value: listTypeInitiatives[i].name,
        };
      }
    }
    return {};
  };
  const isExternal = useMemo(() => {
    return formValues.source_type === "external";
  }, [formValues.source_type]);

  const saveInitiative = async (status = "programmed") => {
    if (!validateAll()) {
      let payload = { ...formValues };
      if (!payload["tracking_objects"]) {
        payload["tracking_objects"] = [];
      }
      if (payload.moments_selected) {
        payload["moments"] = payload.moments_selected;
        delete payload["moments_selected"];
      }
      if (payload.optionEmailPriority) {
        payload["email_with_higher_priority"] =
          payload.optionEmailPriority === "institutional_email";
        delete payload["optionEmailPriority"];
      }

      if (!edit && id === null) {
        payload["client_area"] = user.client_area_id;
        payload["status"] = status;
        if (status !== "programmed") {
          setLoadingDraft(true);
        } else {
          setLoadingActive(true);
        }

        if (payload.source_type === "external") {
          delete payload["tracking_objects"];
          let form_data = new FormData();
          for (let key in payload) {
            form_data.append(key, payload[key]);
            if (key === "start_date") {
              form_data.set(
                key,
                moment(payload.start_date).format("YYYY-MM-DD") + " 12:00:00"
              );
            }
            if (key === "end_date" && payload.end_date) {
              form_data.set(
                key,
                moment(payload.end_date).format("YYYY-MM-DD") + " 12:00:00"
              );
            }
            if (
              key === "student_responses" &&
              getGlobalConfig("enabled_select_student_responses", false)
            ) {
              if (
                payload?.student_responses &&
                payload?.student_responses?.length > 0
              ) {
                // let x = payload?.student_responses?.map((item) => item?.id);
                // form_data.set(key, x);
                for (let i = 0; i < payload?.student_responses?.length; i++) {
                  if (i === 0) {
                    form_data.set(
                      key,
                      parseInt(payload?.student_responses[i].id)
                    );
                  } else {
                    form_data.append(
                      key,
                      parseInt(payload?.student_responses[i].id)
                    );
                  }
                }
              }
            }
          }
          if (
            payload?.question_selected &&
            payload?.question_selected?.id !== -1
          ) {
            form_data.append("call_form", payload?.question_selected?.id);
          }
          payload = form_data;
        } else {
          // payload = { ...formValues };
          payload["start_date"] =
            moment(payload.start_date).format("YYYY-MM-DD") + " 12:00:00";
          if (payload.end_date) {
            payload["end_date"] =
              moment(payload.end_date).format("YYYY-MM-DD") + " 12:00:00";
          }
          if (
            payload?.question_selected &&
            payload?.question_selected?.id !== -1
          ) {
            payload["call_form"] = payload?.question_selected?.id;
          }
          if (
            payload?.student_responses &&
            payload?.student_responses?.length > 0 &&
            getGlobalConfig("enabled_select_student_responses", false)
          ) {
            let x = payload?.student_responses?.map((item) => item?.id);
            payload["student_responses"] = x;
          }
        }

        const data = await createInitiative(payload);
        if (data.status === 201) {
          setLoadingActive(false);
          dispatch(fetchListInitiatives({ status: status }));
          if (status === "draft") {
            setLoadingDraft(false);
            setTextModal({
              title: t("initiative_saved_draft"),
              description: t("review_draft_initiative"),
            });
          }
          //setStatus(status);
          sucesssSave(true);
          setShow(true);
          setObjectCreate(data.data);
        } else {
          if (data?.message === "Error in len student") {
            toast.error(
              t("error_len_student", {
                max_students: client_area_max_internal_management,
              })
            );
          } else {
            if (payload.source_type === "external") {
              toast.error(t("error_create_initiative"));
            } else {
              if (data?.message === "Error in mail column, invalid format") {
                toast.error(t("error_email_column_external"));
              } else {
                if (
                  data?.message === "Error in course column, invalid format"
                ) {
                  toast.error(t("error_course_column_external"));
                } else {
                  toast.error(t("error_create_initiative_simple"));
                }
              }
            }
          }
          if (status !== "programmed") {
            setLoadingDraft(false);
          } else {
            setLoadingActive(false);
          }
        }
      } else {
        setLoadingActive(true);

        if (!payload["tracking_objects"]) {
          payload["tracking_objects"] = [];
        }
        payload = { ...formValues };
        payload["start_date"] =
          moment(payload.start_date).format("YYYY-MM-DD") + " 12:00:00";
        if (payload.end_date) {
          payload["end_date"] =
            moment(payload.end_date).format("YYYY-MM-DD") + " 12:00:00";
        }
        if (
          payload?.question_selected &&
          payload?.question_selected?.id !== -1
        ) {
          payload["call_form"] = payload?.question_selected?.id;
        }
        if (
          payload?.question_selected &&
          payload?.question_selected?.id === -1
        ) {
          payload["call_form"] = null;
        }
        if (
          payload?.student_responses &&
          payload?.student_responses?.length > 0 &&
          getGlobalConfig("enabled_select_student_responses", false)
        ) {
          let x = payload?.student_responses?.map((item) => item?.id);
          payload["student_responses"] = x;
        }
        const data = await updateInitiative(id, payload, true);
        if (data.status === 200) {
          setLoadingActive(false);
          dispatch(fetchListInitiatives({ status: status }));
          setTextModal({
            title: t("initiative_saved_sucessfully"),
            description: t("check_detail_initiativa"),
          });
          setShow(true);
          sucesssSave(true);
          setObjectCreate(data.data);
        } else {
          toast.error(t("error_update_initiative"));
          setLoadingActive(false);
        }
      }
    } else {
      setLoadingActive(false);
      setLoadingDraft(false);
      toast.error(t("required_fields_missing"));
    }
  };

  useEffect(() => {
    const aux = [];
    aux.push(getItemTypeInitiative(formValues.optionInitiative));
    //aux.push(options[formValues.optionCreate]);
    aux.push(options[formValues.source_type]);
    const auxCreated = [];
    auxCreated.push(options[formValues.optionCreate]);
    setSelectedOption(aux);
    setSelectedCreate(auxCreated);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filtersData = useMemo(() => clientFilters.data[panel] || [], [
    clientFilters.data,
  ]);
  const filtersApplied = useMemo(() => {
    const currentFilters = Object.keys({ ...clientFilters.filters[panel] });

    const list_filter = [];
    const allItems = {
      1: "SI",
      0: "NO",
      True: "SI",
      False: "NO",
      undefined: "NO",
    };
    currentFilters.forEach((filter) => {
      const item = filtersData.find(
        (element) => element.filter_field === filter
      );

      // eslint-disable-next-line prefer-destructuring
      let value = _.get(
        allItems,
        clientFilters.filters[panel][filter],
        clientFilters.filters[panel][filter]
      );
      if (item) {
        let res = { key: item["label"], value: value, filter: filter };
        list_filter.push(res);
      }
    });

    return list_filter;
  }, [clientFilters.filters, filtersData]);

  const getPosition = (text) => {
    let arrayForm = [];
    if (edit) {
      arrayForm = positionsForm["edit"];
    } else {
      if (formValues.optionCreate && formValues.optionCreate === "copy") {
        arrayForm = positionsForm["clone"];
      } else {
        arrayForm =
          positionsForm[
            formValues.source_type === "external" ? "external" : "normal"
          ];
      }
    }
    return arrayForm.indexOf(text);
  };

  return (
    <div style={{ maxWidth: "922px", margin: "auto", paddingBottom: "30px" }}>
      <CardNimbi padding={"32px 35px 32px 35px"}>
        <Row>
          <Col xs="7">
            <StepTitle style={{ marginBottom: "16px" }}>
              {pageNumber ? pageNumber : t("step_number", { number: "6" })}
            </StepTitle>
            <TextTitle>
              {edit
                ? t("title_preview_update_initiative")
                : t("title_preview_save_initiative")}
            </TextTitle>
          </Col>
        </Row>
      </CardNimbi>

      {!edit && (
        <div style={{ marginTop: "24px" }}>
          <CardNimbi padding={"17px 35px 17px 18px"} bordercolor="#3AC8AC">
            <ContainerTitle>
              <TitleCard> {t("type_initiative")}</TitleCard>
              <div style={{ display: "flex" }}>
                <StepTitle>{t("step_number", { number: "1" })}</StepTitle>
                <div style={{ marginLeft: "18px", width: "16px" }} />
              </div>
            </ContainerTitle>
            <div style={{ display: "flex", marginTop: "16px" }}>
              <ListOptionClip
                listOptions={selectedCreate}
                selectedAll={true}
                planeText={true}
              />
            </div>
          </CardNimbi>
        </div>
      )}

      {!edit && (
        <div style={{ marginTop: "24px" }}>
          <CardNimbi padding={"17px 35px 17px 18px"} bordercolor="#3AC8AC">
            <ContainerTitle>
              <TitleCard>DETALLE CAMPAÑA</TitleCard>
              <div style={{ display: "flex" }}>
                <StepTitle>{t("step_number", { number: "2" })}</StepTitle>
                <div style={{ marginLeft: "18px", width: "16px" }} />
              </div>
            </ContainerTitle>
            <div style={{ display: "flex", marginTop: "16px" }}>
              <ListOptionClip
                listOptions={selectedOptions}
                selectedAll={true}
                planeText={true}
              />
            </div>
          </CardNimbi>
        </div>
      )}
      {formValues.source_type === "external" && (
        <div style={{ marginTop: "24px" }}>
          <CardFileInitiativeResume
            formValues={formValues}
            edit={edit}
            isExternal={isExternal}
            onChangePosition={() => {
              setPosition(getPosition("addFile"));
            }}
          />
        </div>
      )}
      <div style={{ marginTop: "24px" }}>
        <CardInfoInitiativeResume
          formValues={formValues}
          edit={edit}
          isExternal={isExternal}
          onChangePosition={() => {
            setPosition(getPosition("addInfo"));
          }}
        />
      </div>
      <div style={{ marginTop: "24px" }}>
        <CardTemplateInitiativeResume
          formValues={formValues}
          edit={edit}
          isExternal={isExternal}
          onChangePosition={() => {
            setPosition(getPosition("selectTemplate"));
          }}
          typeFormList={typeFormList}
        />
      </div>

      {formValues.optionInitiative === "Call" &&
        getGlobalConfig("enabled_select_student_responses", false) && (
          <div style={{ marginTop: "24px" }}>
            <CardNimbi
              padding={"17px 35px 17px 18px"}
              bordercolor={
                isEmptyArray(formValues?.student_responses)
                  ? "#E23232"
                  : "#3AC8AC"
              }
            >
              <ContainerTitle>
                <TitleCard>Respuesta de estudiante</TitleCard>
                <div style={{ display: "flex" }}>
                  <StepTitle>
                    {t("step_number", {
                      number:
                        typeFormList?.indexOf("selectStudentResponse") + 1,
                    })}
                  </StepTitle>
                  {!formValues.clone && (
                    <NIcon
                      iconname="edit"
                      style={{
                        marginLeft: "18px",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setPosition(
                          typeFormList?.indexOf("selectStudentResponse")
                        );
                      }}
                    />
                  )}
                  {formValues.clone && (
                    <div style={{ marginLeft: "18px", width: "16px" }} />
                  )}
                </div>
              </ContainerTitle>
              <div style={{ marginTop: "10px" }}>
                {formValues?.student_responses &&
                  formValues?.student_responses.map((item) => (
                    <div key={item?.id}>{item?.response_text}</div>
                  ))}
              </div>
            </CardNimbi>
          </div>
        )}
      {formValues.source_type === "internal" &&
        formValues.optionInitiative === "Call" &&
        getGlobalConfig("enable_question_initative", false) && (
          <div style={{ marginTop: "24px" }}>
            <CardQuestionResume
              formValues={formValues}
              edit={edit}
              isExternal={isExternal}
              textNumber={typeFormList?.indexOf("selectQuestion") + 1}
              onChangePosition={() => {
                setPosition(typeFormList?.indexOf("selectQuestion"));
              }}
            />
          </div>
        )}
      {formValues.source_type === "internal" && (
        <div style={{ marginTop: "24px" }}>
          <StudentsInitiativeResume
            formValues={formValues}
            edit={edit}
            isExternal={isExternal}
            onChangePosition={() => {
              setPosition(typeFormList?.indexOf("selectStudent"));
            }}
            filtersApplied={filtersApplied}
            paginationTracking={paginationTracking}
            textNumber={typeFormList?.indexOf("selectStudent") + 1}
          />
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          {!edit && (
            <NButton
              disabled={loadingDraft}
              sizeStyle={ButtonSize.lg}
              style={{
                marginTop: "24px",
                borderRadius: "24px",
                marginRight: "16px",
              }}
              onClick={() => {
                saveInitiative("draft");
              }}
              outlined
              color="second"
            >
              {t("save_draft")} {loadingDraft && <NSpinnerPoints size="20px" />}
            </NButton>
          )}
          <NButton
            disabled={loadingActive}
            sizeStyle={ButtonSize.lg}
            style={{
              marginTop: "24px",
              borderRadius: "24px",
            }}
            onClick={() => {
              saveInitiative("programmed");
            }}
            color="second"
          >
            {edit ? t("save_initiative") : t("create_initiative")}
            {loadingActive && <NSpinnerPoints size="20px" />}
          </NButton>
        </div>
      </div>
      <IniciativeModal
        show={show}
        handleClose={() => {
          setShow(false);
        }}
        buttonAction={() => {
          history.push(`/campañas/${objectCreate.id}`);
        }}
        textTitle={textModal.title}
        description={textModal.description}
      />
    </div>
  );
};

export default PageFifth;
