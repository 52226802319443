import React, { useState, useEffect, useCallback, useMemo } from "react";
import _ from "lodash";
import styled from "styled-components";
import PropTypes from "prop-types";
import FormFilter from "../../NFormFilter";
import NFilterBadge from "../NDynamicFilters/NFilterBadge";
import { useTranslation } from "react-i18next";
import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { APP_MODULES } from "utils";
import { SEARCH } from "../NDynamicFilters/constants";
import { RowItem } from "../styled";
import { getValueFilter, setValueFilter } from "utils/filters";
import NSearch from "../NSearch";
import { NButton } from "components/Nimbiv2";
import ContainerFilters from "../ContainerFilters";

const {
  TASK: { FILTER_NAME: FILTER_TASK },
} = APP_MODULES;

const NContainerFilters = ({
  isPendingList = false,
  filterName = FILTER_TASK,
  // Property Buttons
  textButtonCreate,
  renderSectionCreate,
  renderCheckFilters,
  buttonCreateAction = () => {},
  showButtonCreate = true,
  // Property count rows
  showCountResult,
  countResult = 0,
  textCountResult,
  //Property Sections
  renderSectionRight,
  //new attributes
  dataFilter = [],
  fetchFilters,
  onChange,
  searchKey,
  compositeLabel,
  customMenuItem,
  urlSearch,
  placeholderSearch,
  attribSearch,
  showFilters = true,
  persistentSearch = true,
}) => {
  const { t } = useTranslation();

  //----------------------------------------------
  const [filters, setFilters] = useState(null);
  // const [loadFilters, setLoadFilters] = useState(false);
  const [filterSave, setFilterSave] = useState({});
  const [search, setSearch] = useState(null);

  const getFetchFilters = async () => {
    if (fetchFilters) {
      // setLoadFilters(true);
      const { data } = await fetchFilters();
      setFilters(data);
      // setLoadFilters(false);
    }
  };

  useEffect(() => {
    getFetchFilters();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const data = getValueFilter(filterName);
    setFilterSave(data);
    if (data.hasOwnProperty(searchKey)) {
      setSearch(data[searchKey]);
    }
  }, [filterName, searchKey]);

  // useMemo
  const hasFilters = useMemo(() => {
    return filters || dataFilter.length > 0;
  }, [filters, dataFilter]);

  const filtersDynamic = useMemo(() => {
    if (filters) {
      return filters;
    }
    return dataFilter;
  }, [filters, dataFilter]);

  const filtersApplied = useMemo(() => {
    const currentFilters = Object.keys(filterSave);

    const list_filter = [];
    const allItems = {
      1: "SI",
      0: "NO",
      True: "SI",
      False: "NO",
      undefined: "NO",
      "1##": "SI",
      "0##": "NO",
    };
    if (filtersDynamic) {
      currentFilters.forEach((filter) => {
        const item = filtersDynamic.find(
          (element) => element.filter_field === filter
        );

        // eslint-disable-next-line prefer-destructuring
        let value = _.get(allItems, filterSave[filter], filterSave[filter]);
        if (item) {
          let res = { key: item["label"], value: value, filter: filter };
          list_filter.push(res);
        }
      });
    }

    return list_filter;
  }, [filterSave, filtersDynamic]);

  // Callbacks

  const changeValueFilters = useCallback(
    (new_values) => {
      let val = {};
      if (new_values.hasOwnProperty(searchKey)) {
        val = { ...filterSave, ...new_values };
      } else {
        if (search) {
          val = { ...new_values, ...{ [searchKey]: search } };
        } else {
          val = new_values;
        }
      }
      setFilterSave(val);
      onChange(val);
      if (persistentSearch) {
        setValueFilter(filterName, val);
      } else {
        delete val[searchKey];
        setValueFilter(filterName, val);
      }
    },
    [filterSave, filterName, onChange, search, searchKey, persistentSearch]
  );

  const deleteFilter = useCallback(
    (filter) => {
      let filters = { ...filterSave };
      delete filters[filter["filter"]];
      changeValueFilters(filters);
    },
    [filterSave, changeValueFilters]
  );

  const renderFilterBadges = useCallback(() => {
    if (Object.keys(filterSave).length === 0) return <></>;
    return filtersApplied.map((element) => {
      //const [key, value] = element;
      return (
        <NFilterBadge
          key={element["key"]}
          filterInfo={element}
          onClickDelete={deleteFilter}
        />
      );
    });
  }, [filterSave, filtersApplied, deleteFilter]);

  const renderFilterGroup = useCallback(() => {
    if (!hasFilters) return null;
    return (
      <>
        <Row>
          <Col className="p-0">
            <Container style={{ marginTop: "10px", padding: "0px" }} fluid>
              <FormFilter
                data={filtersDynamic}
                onChange={changeValueFilters}
                filterset={filterSave}
                isDisabled={isPendingList}
                externalUpdate={true}
              />
            </Container>
          </Col>
        </Row>
      </>
    );
  }, [
    isPendingList,
    hasFilters,
    filterSave,
    changeValueFilters,
    filtersDynamic,
  ]);
  //-----------------------------

  return (
    <div>
      <Row style={{ padding: "0 15px" }}>
        <Col xs="12" className="p-0">
          <RowItem>
            <Col xs="7" className="p-0">
              <InputDesign style={{ padding: "7px 12px" }}>
                <NSearch
                  filterKey={SEARCH.TASK.FILTER_KEY}
                  fieldName={SEARCH.TASK.FIELD_NAME}
                  onChange={(value) => {
                    if (value && Object.keys(value).length > 0) {
                      setSearch(value);
                      changeValueFilters({ [searchKey]: value });
                    } else {
                      deleteFilter({ filter: searchKey });
                      setSearch(null);
                    }
                  }}
                  isDisabled={isPendingList}
                  defaultSearch={search}
                  applyFiltersStudent={false}
                  compositeLabel={compositeLabel}
                  customMenuItem={customMenuItem}
                  urlSearch={urlSearch}
                  placeholder={placeholderSearch}
                  attribSearch={attribSearch}
                  filterName={filterName}
                  persistentSearch={persistentSearch}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{
                    height: "27px",
                    marginLeft: "10px",
                    color: "#334FFF",
                  }}
                />
              </InputDesign>
            </Col>
            <Col xs="5" className="p-0">
              {renderSectionCreate}
              {!renderSectionCreate && showButtonCreate && (
                <div className="d-flex justify-content-end align-items-center h-100">
                  <NButton
                    padding="9px 15px"
                    boxshadow={1}
                    border="24px"
                    size="14px"
                    onClick={buttonCreateAction}
                    style={{ minWidth: "180px" }}
                    colorbutton="var(--call-in-action-second)"
                    hoverbuttom="var(--color-btn-second)"
                    icon={<FontAwesomeIcon icon={faPlus} />}
                  >
                    {textButtonCreate ?? "Agregar Registro"}
                  </NButton>
                </div>
              )}
            </Col>
          </RowItem>
        </Col>
        <Col xs="7" className="p-0">
          <div className="d-flex" style={{ marginTop: "16px" }}>
            <div className="mr-auto" style={{ zIndex: "1" }}>
              {renderCheckFilters}
            </div>
          </div>
        </Col>
        <Col xs="5" className="p-0">
          {renderSectionRight}
        </Col>
        {showFilters && (
          <ContainerFilters
            isPendingCount={isPendingList}
            countItems={countResult}
            textCount={textCountResult ?? t("users_found")}
            hasFilters={true}
            renderFilterBadges={renderFilterBadges}
            renderFilterGroup={renderFilterGroup}
          />
        )}
      </Row>
    </div>
  );
};

export default NContainerFilters;
NContainerFilters.propTypes = {
  isPendingList: PropTypes.bool,
  showPriorityFilter: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

const InputDesign = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 10px 12px;
  .form-control {
    border: 0px solid #ced4da !important;
    padding: 0px;
  }
  .rbt {
    width: 100%;
  }
`;
