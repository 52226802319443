import React, { useMemo } from "react";
import _ from "lodash";
import { InfoStudent } from "../detail";
import { CardNimbi, RowItem } from "components/Nimbiv2/styled";
import { CardIndicatorStudent } from "components/Nimbiv2/PageStudent";
import { useSelector } from "react-redux";
import { getGlobalConfig } from "utils";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CardIndicator from "components/Skeletons/CardIndicator";
import { IconRisk } from "assets/images";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/pro-regular-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import SelectStudentTracking from "./SelectStudentTracking";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import EditContactModal from "./EditContactModal";

const PageInfoStudent = ({ selectTracking, changeTrackingObject }) => {
  const { t } = useTranslation();
  const {
    insights,
    courseSelected,
    pending: { insightsPending: isPending },
  } = useSelector((state) => state.student);
  const { personsProfile } = useSelector((state) => state.crm);

  let cardsTemplate = [
    {
      icon: "flag",
      title: _.upperFirst(t("risk")),
      data: insights?.risk?.data,
      days_difference: "-",
      empty_image: IconRisk,
    },
  ];
  if (getGlobalConfig("hide_nimbi_risk", false)) {
    cardsTemplate = [
      {
        icon: "flag",
        title: _.upperFirst(t("no_information")),
        data: [],
        days_difference: "-",
        empty_image: IconRisk,
      },
    ];
  }

  const emailPersonProfile = useMemo(() => {
    if (
      personsProfile &&
      personsProfile?.contactinfo_set &&
      personsProfile?.contactinfo_set?.filter((item) => {
        return item.contact_type === "email";
      }).length > 0
    ) {
      const emails = personsProfile?.contactinfo_set?.filter((item) => {
        return item.contact_type === "email";
      });
      return emails[0]?.value;
    }
    return "-";
  }, [personsProfile]);
  const emailSecondPersonProfile = useMemo(() => {
    if (
      personsProfile &&
      personsProfile?.contactinfo_set &&
      personsProfile?.contactinfo_set?.filter((item) => {
        return item.contact_type === "email" && item.is_allowed === "yes";
      }).length > 1
    ) {
      const emails = personsProfile?.contactinfo_set?.filter((item) => {
        return item.contact_type === "email" && item.is_allowed === "yes";
      });
      return emails[1]?.value;
    }
    return null;
  }, [personsProfile]);

  const phonePersonProfile = useMemo(() => {
    if (
      personsProfile &&
      personsProfile?.contactinfo_set &&
      personsProfile?.contactinfo_set?.filter((item) => {
        return item.contact_type === "phone" && item.is_allowed === "yes";
      }).length > 0
    ) {
      const phones = personsProfile?.contactinfo_set?.filter((item) => {
        return item.contact_type === "phone" && item.is_allowed === "yes";
      });
      return phones[0]?.value;
    }
    return "-";
  }, [personsProfile]);

  const phoneSecondPersonProfile = useMemo(() => {
    if (
      personsProfile &&
      personsProfile?.contactinfo_set &&
      personsProfile?.contactinfo_set?.filter((item) => {
        return item.contact_type === "phone" && item.is_allowed === "yes";
      }).length > 1
    ) {
      const phones = personsProfile?.contactinfo_set?.filter((item) => {
        return item.contact_type === "phone" && item.is_allowed === "yes";
      });
      return phones[1]?.value;
    }
    return null;
  }, [personsProfile]);

  return (
    <CardNimbi padding="16px 16px">
      <div
        className="d-flex justify-content-between "
        style={{ marginBottom: "24px", paddingLeft: "10px" }}
      >
        <div style={{ fontWeight: "600", fontSize: "20px" }}>
          Información general
        </div>
        <SelectStudentTracking
          selectTracking={selectTracking}
          changeTrackingObject={changeTrackingObject}
        />
      </div>
      <div>
        {!isPending && insights.length > 0 && (
          <RowItem>
            {insights
              .filter((item) => {
                if (
                  insights.length > 1 &&
                  getGlobalConfig("hide_nimbi_risk", false) &&
                  item?.title === "Riesgo"
                ) {
                  return false;
                }
                return true;
              })
              .map((item, key) => {
                return (
                  <Col lg="4" md="6" key={`${key}-card-indicator`}>
                    <CardIndicatorStudent
                      item={item}
                      labelModal={
                        item?.title === "Riesgo"
                          ? _.upperFirst(t("risk"))
                          : null
                      }
                      border
                    />
                  </Col>
                );
              })}
          </RowItem>
        )}
        {!isPending && courseSelected && insights.length === 0 && (
          <RowItem>
            {cardsTemplate.map((item, key) => {
              return (
                <Col lg="4" md="6" key={`${key}-card-indicator`}>
                  <CardIndicatorStudent item={item} border />
                </Col>
              );
            })}
          </RowItem>
        )}
        {(isPending || !courseSelected) && (
          <RowItem>
            {["1", "2", "3"].map((item, key) => {
              return (
                <Col lg="4" md="6" key={`${key}-card-indicator-skeletons`}>
                  <CardIndicator />
                </Col>
              );
            })}
          </RowItem>
        )}
      </div>
      <ContainerContact>
        <div className="mr-auto" style={{ paddingRight: "20px" }}>
          <div
            className="d-flex"
            style={{ flexWrap: "wrap", columnGap: "20px", rowGap: "10px" }}
          >
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faPhone}
                className="mr-2"
                color="#334FFF"
                style={{ fontSize: "18px" }}
              />
              <span style={{ fontSize: "16px", fontWeight: "500" }}>
                {phonePersonProfile ?? _.upperFirst(t("no_information"))}
              </span>
              <FontAwesomeIcon
                icon={faClone}
                className="ml-2"
                style={{ fontSize: "18px", cursor: "pointer" }}
                onClick={() => {
                  copy(phonePersonProfile);
                  toast.info(`Copiado: ${phonePersonProfile}`);
                }}
              />
            </div>
            {phoneSecondPersonProfile && (
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="mr-2"
                  color="#334FFF"
                  style={{ fontSize: "18px" }}
                />
                <span style={{ fontSize: "16px", fontWeight: "500" }}>
                  {phoneSecondPersonProfile ??
                    _.upperFirst(t("no_information"))}
                </span>
                <FontAwesomeIcon
                  icon={faClone}
                  className="ml-2"
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  onClick={() => {
                    copy(phoneSecondPersonProfile);
                    toast.info(`Copiado: ${phoneSecondPersonProfile}`);
                  }}
                />
              </div>
            )}
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="mr-2"
                color="#334FFF"
                style={{ fontSize: "18px" }}
              />
              <span style={{ fontSize: "16px", fontWeight: "500" }}>
                {emailPersonProfile ?? _.upperFirst(t("no_information"))}
              </span>
              <FontAwesomeIcon
                icon={faClone}
                className="ml-2"
                onClick={() => {
                  copy(emailPersonProfile);
                  toast.info(`Copiado: +${emailPersonProfile}`);
                }}
                style={{ fontSize: "18px", cursor: "pointer" }}
              />
            </div>
            {emailSecondPersonProfile && (
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="mr-2"
                  color="#334FFF"
                  style={{ fontSize: "18px" }}
                />
                <span style={{ fontSize: "16px", fontWeight: "500" }}>
                  {emailSecondPersonProfile ??
                    _.upperFirst(t("no_information"))}
                </span>
                <FontAwesomeIcon
                  icon={faClone}
                  className="ml-2"
                  onClick={() => {
                    copy(emailSecondPersonProfile);
                    toast.info(`Copiado: +${emailSecondPersonProfile}`);
                  }}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                />
              </div>
            )}
          </div>
        </div>
        {!getGlobalConfig("disable_update_contact", false) && (
          <EditContactModal />
        )}
      </ContainerContact>
      <InfoStudent version={3} />
    </CardNimbi>
  );
};
export default PageInfoStudent;

const ContainerContact = styled.div`
  display: flex;
  padding: 17.5px 24px;
  background-color: #f6fafe;
  margin-top: 24px;
  border-radius: 8px;
  align-items: center;
`;
