import React, { useEffect, useCallback, useState } from "react";
import _ from "lodash";
import { NTable } from "components/Nimbiv2";
import { CardNimbi } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMilestoneStudent,
  fetchMilestoneStudentCreate,
} from "store/actions/async/student-async.actions";
import { toast } from "react-toastify";
import { getGlobalConfig, getText } from "utils";
import SelectStudentTracking from "../v3/SelectStudentTracking";
const MilestonePage = ({
  student_tracking_id,
  changeTrackingObject,
  selectTracking,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDisabled, setDisabled] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const version3 = getGlobalConfig("enable_student_version_3", false);
  let defaultMilestone = [
    {
      id: 1,
      type_name: "Hito 1",
      type_description: "Matrícula",
      type_value: "boolean",
      type_id: null,
      value: "-",
      created_by: "",
      created_at: "",
      modified_at: "",
    },
    {
      id: 2,
      type_name: "Hito 2",
      type_description: "Fidelización DC",
      type_value: "boolean",
      type_id: null,
      value: "-",
      created_by: "",
      created_at: "",
      modified_at: "",
    },
    {
      id: 3,
      type_name: "Hito 3",
      type_description: "Welcome call",
      type_value: "boolean",
      type_id: null,
      value: "-",
      created_by: "",
      created_at: "",
      modified_at: "",
    },
    {
      id: 4,
      type_name: "Hito 4",
      type_description: "Tour presencial",
      type_value: "boolean",
      type_id: null,
      value: "-",
      created_by: "",
      created_at: "",
      modified_at: "",
    },
    {
      id: 5,
      type_name: "Hito 5",
      type_description: "Jornadas de inducción",
      type_value: "boolean",
      type_id: null,
      value: "-",
      created_by: "",
      created_at: "",
      modified_at: "",
    },
    {
      id: 6,
      type_name: "Hito 6",
      type_description: "Inicio año académico",
      type_value: "boolean",
      type_id: null,
      value: "-",
      created_by: "",
      created_at: "",
      modified_at: "",
    },
  ];
  const validatePermissions = () => {
    let permissions = ["Administrator", "Supervisor"];
    if (user && user.groups !== undefined) {
      if (user.groups.includes(permissions[0])) {
        setDisabled(false);
      } else {
        if (user.groups.includes(permissions[1])) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }
    } else {
      setDisabled(true);
    }
  };
  const {
    milestoneStudent,
    pending: { milestoneStudent: isPendingMilestone },
  } = useSelector((state) => state.student);

  useEffect(() => {
    validatePermissions();
    // call endpoint
    dispatch(fetchMilestoneStudent(student_tracking_id));
  }, [dispatch, student_tracking_id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (milestoneStudent) {
    for (let i = 0; i < milestoneStudent.length; i++) {
      for (let j = 0; j < defaultMilestone.length; j++) {
        if (milestoneStudent[i]?.type_name === defaultMilestone[j]?.type_name) {
          defaultMilestone[j] = milestoneStudent[i];
        }
      }
    }
  }

  const changeMilestone = useCallback(
    async (item, value) => {
      const d = {
        type_value_id: item?.type_id,
        type_name: item?.type_name,
        type_value: item?.type_value,
        value: value ? "1" : "0",
      };
      try {
        const { status } = await fetchMilestoneStudentCreate(
          student_tracking_id,
          d
        );
        if (status === 201) {
          dispatch(fetchMilestoneStudent(student_tracking_id));
          toast.success(t("success_save_milestone"));
        } else {
          toast.error(t("error_save_milestone"));
        }
      } catch (err) {
        toast.error(t("error_save_milestone"));
      }
    },
    [dispatch, student_tracking_id, t]
  );
  const columns = [
    {
      title: _.upperFirst(t("milestone")),
      key: "type_name",
      fixed: true,
      render: (item, key) => {
        return (
          <div>
            {item?.type_description && (
              <div>{`${item?.type_name} - ${item?.type_description}`}</div>
            )}
            {!item?.type_description && <div>{`${item?.type_name}`}</div>}
          </div>
        );
      },
    },
    {
      title: _.upperFirst(t("milestone_completed")),
      key: "value",
      fixed: true,
      render: (item, key) => {
        return (
          <div className="d-flex justify-content-start align-items-center pb-2">
            {!isDisabled &&
              (item?.value === "1" ||
                item?.value === "0" ||
                item?.value === "-") && (
                <Form.Check
                  className="font-weight-bold"
                  type="checkbox"
                  id={`value-${item?.id}`}
                  onChange={(event) => {
                    changeMilestone(item, event.target.checked);
                  }}
                  checked={item?.value === "1" ? true : false}
                  disabled={isDisabled}
                  custom
                />
              )}
            {isDisabled && (item?.value === "1" || item?.value === "0") && (
              <div>{item?.value === "1" ? "Sí" : "No"}</div>
            )}
            {isDisabled && item?.value !== "1" && item?.value !== "0" && (
              <div>{"-"}</div>
            )}
          </div>
        );
      },
    },
    {
      title: _.upperFirst(t("updated_date")),
      key: "modified_at",
      dataIndex: "modified_at",
      fixed: true,
      render: (item, key) => <div>{item}</div>,
    },
    {
      title: _.upperFirst(t("created_by_user")),
      key: "created_by",
      dataIndex: "created_by",
      fixed: true,
      render: (item, key) => <div>{item}</div>,
    },
  ];
  return (
    <div>
      <CardNimbi
        radius="8px"
        padding={version3 ? "16px 26px" : "23px 26px"}
        style={{ height: "fit-content" }}
      >
        {version3 && (
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ marginBottom: "24px" }}
          >
            <div style={{ fontWeight: "600", fontSize: "20px" }}>
              {getText("milestone", "startCase", t("milestone"))}
            </div>
            <div>
              <SelectStudentTracking
                selectTracking={selectTracking}
                changeTrackingObject={changeTrackingObject}
              />
            </div>
          </div>
        )}
        <NTable
          columns={columns}
          dataSource={defaultMilestone}
          fixed={true}
          isPending={isPendingMilestone}
          collapse={false}
          textEmpty={t("no_data_milestone")}
        ></NTable>
      </CardNimbi>
    </div>
  );
};

export default MilestonePage;
